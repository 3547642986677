import { PDFDocument, degrees, rgb, StandardFonts } from 'pdf-lib';
const API_URL = process.env.REACT_APP_API_URL;
const API_TOKEN = 'Yh7l5CUTaZ1nIgAueWglafvm616hchHFFZxRjKjPHNBjB19b2jTDgGoCSpeq';

/**
 * Downloads raw bytes for a given URL.
 */
async function fetchBytes(url) {
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error(`Failed to fetch resource from ${url}`);
  }
  return await response.arrayBuffer();
}

/**
 * Downloads raw bytes for a given PDF URL.
 */
async function fetchPDFBytes(url) {
  return fetchBytes(url);
}

/**
 * Fetches the UPS labels PDF for a shipment from the API.
 */
async function fetchShipmentPdf(planId, shipmentId) {
  const url = `https://app.yyzprep.ca/api/fba-transport/v2024/plans/${planId}/labels?shipment_id=${shipmentId}&api_token=${API_TOKEN}`;
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error(`Failed to fetch labels PDF for shipment: ${shipmentId}`);
  }
  const data = await response.json();
  let label = data.data?.find(
    (lbl) => lbl.name.includes('8.5') && lbl.type === 'package'
  );
  if (!label?.url) {
    throw new Error(`No suitable label found for shipment: ${shipmentId}`);
  }
  const proxyUrl = `${API_URL}/api/fetch-pdf?url=${encodeURIComponent(label.url)}`;
  return fetchPDFBytes(proxyUrl);
}

/**
 * Fetches the plan data (which includes box_groups) for a given planId.
 */
async function fetchPlanData(planId) {
  const url = `https://app.yyzprep.ca/api/fba-transport/v2024/plans/${planId}?api_token=${API_TOKEN}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: { 'Accept': 'application/json' }
  });
  if (!response.ok) {
    throw new Error(`Failed to fetch plan data for plan: ${planId}`);
  }
  return response.json().then(data => data.data);
}

/**
 * Logs out the contents of each internal box group.
 */
function logInternalBoxContents(planData) {
  if (!planData.box_groups || planData.box_groups.length === 0) {
    console.log("No box groups available in the plan data.");
    return;
  }
  console.log("Listing internal box contents:");
  planData.box_groups.forEach(group => {
    console.log(`\nBox Group ID: ${group.id}`);
    console.log(`Box Numbers: ${group.box_numbers.join(", ")}`);
    if (group.items && group.items.length > 0) {
      console.log("Items:");
      group.items.forEach(item => {
        console.log(
          ` • Plan Item ID: ${item.plan_item_id}, SKU: ${item.msku || "N/A"}, Quantity: ${item.quantity}`
        );
      });
    } else {
      console.log("No items in this box group.");
    }
  });
}

/**
 * Crops and rotates a label from a specific page of sourceDoc into targetDoc.
 */
async function cropAndRotateLabel(sourceDoc, pageIndex, cropBox, targetDoc) {
  const { x, y, width, height } = cropBox;
  const [copiedPage] = await targetDoc.copyPages(sourceDoc, [pageIndex]);
  copiedPage.setCropBox(x, y, width, height);
  copiedPage.setMediaBox(x, y, width, height);
  const scaleFactor = 0.84;
  copiedPage.scale(scaleFactor, scaleFactor);
  copiedPage.setRotation(degrees(90));
  // Swap width and height to preserve portrait orientation.
  copiedPage.setSize(height, width);
  targetDoc.addPage(copiedPage);
}

/**
 * Converts an 8.5x11 PDF (assumed to have 2 labels per physical page)
 * to a new PDF with 4x6 pages.
 */
async function process8x11Page(pdfDoc) {
  const newPdfDoc = await PDFDocument.create();
  const pages = pdfDoc.getPages();
  const label1CropBox = { x: 10, y: 34, width: 288, height: 432 };
  const label2CropBox = { x: 15, y: 342, width: 288, height: 432 };

  for (let i = 0; i < pages.length; i++) {
    try {
      await cropAndRotateLabel(pdfDoc, i, label1CropBox, newPdfDoc);
    } catch (error) {
      console.error(`Error processing Label 1 on Page ${i + 1}: ${error.message}`);
    }
    try {
      await cropAndRotateLabel(pdfDoc, i, label2CropBox, newPdfDoc);
    } catch (error) {
      console.error(`Error processing Label 2 on Page ${i + 1}: ${error.message}`);
    }
  }
  return newPdfDoc;
}

/**
 * Given the plan data, builds the full ordered list of internal box numbers.
 */
function buildOrderedBoxNumbers(planData) {
  const ordered = [];
  planData.box_groups.forEach(group => {
    group.box_numbers.forEach(boxNum => ordered.push(boxNum));
  });
  return ordered;
}

/**
 * Processes one shipment using the internal plan ordering.
 *
 * Updated to map internal box numbers to UPS box numbers based on amazon_box_id.
 */
async function processShipmentUsingPlanMapping(shipment, planData, onProgress) {
  // Extract and map internal box numbers to UPS box numbers
  const boxMappings = shipment.boxes.map(box => {
    const internalBoxNumber = box.box_number;
    const amazonBoxId = box.amazon_box_id;
    const upsBoxNumberStr = amazonBoxId.slice(-6);
    const upsBoxNumber = parseInt(upsBoxNumberStr, 10);
    if (isNaN(upsBoxNumber)) {
      throw new Error(`Invalid amazon_box_id for box_number ${internalBoxNumber}: ${amazonBoxId}`);
    }
    return {
      internalBoxNumber,
      upsBoxNumber
    };
  });

  // Sort boxes by internalBoxNumber ascending
  boxMappings.sort((a, b) => a.internalBoxNumber - b.internalBoxNumber);
  console.log("Box mappings (sorted by internal box number):", boxMappings);

  // Fetch and load the shipment UPS PDF.
  const pdfBytes = await fetchShipmentPdf(planData.id, shipment.shipment_id);
  let pdfDoc = await PDFDocument.load(pdfBytes);
  const shipmentCount = boxMappings.length;
  const expectedPagesByShipment = shipmentCount * 2;
  const pageCount = pdfDoc.getPageCount();
  let wasConverted = false;
  if (pageCount === expectedPagesByShipment) {
    console.log(`Shipment ${shipment.shipment_id} has ${pageCount} pages as expected.`);
  } else if (pageCount === shipmentCount) {
    console.log(`Shipment ${shipment.shipment_id} has one page per box. Converting using 2-label crop.`);
    pdfDoc = await process8x11Page(pdfDoc);
    wasConverted = true;
    console.log("Converted PDF page count:", pdfDoc.getPageCount());
  } else {
    console.warn(`Unexpected PDF layout for shipment ${shipment.shipment_id}. Page count: ${pageCount}.`);
  }

  const finalPageCount = pdfDoc.getPageCount();

  // Build miniEntries based on UPS box numbers
  const miniEntries = [];
  for (const mapping of boxMappings) {
    const { internalBoxNumber, upsBoxNumber } = mapping;
    const upsPageIndex = (upsBoxNumber - 1) * 2;
    const fbaPageIndex = (upsBoxNumber - 1) * 2 + 1;

    if (upsPageIndex >= finalPageCount || fbaPageIndex >= finalPageCount) {
      console.error(`For shipment box ${internalBoxNumber} (UPS Box ${upsBoxNumber}), page indices [${upsPageIndex}, ${fbaPageIndex}] exceed total ${finalPageCount}.`);
      continue;
    }

    console.log(`Extracting for shipment box ${internalBoxNumber} (UPS Box ${upsBoxNumber}): pages [${upsPageIndex}, ${fbaPageIndex}].`);

    const miniPdfCreate = async () => {
      const miniPdf = await PDFDocument.create();
      const [copiedUpsPage, copiedFbaPage] = await miniPdf.copyPages(pdfDoc, [upsPageIndex, fbaPageIndex]);
      miniPdf.addPage(copiedUpsPage);
      miniPdf.addPage(copiedFbaPage);
      if (wasConverted) {
        const helveticaFont = await miniPdf.embedFont(StandardFonts.Helvetica);
        const stampText = `YYZ Box ${internalBoxNumber}`;
        miniPdf.getPages().forEach((page, index) => {
          const isEvenPage = index % 2 === 0;
          const textOptions = {
            x: isEvenPage ? 373 : 250,
            y: isEvenPage ? 150 : 450,
            size: 12,
            font: helveticaFont,
            color: rgb(0, 0, 0),
            rotate: degrees(90)
          };
          page.drawText(stampText, textOptions);
        });
      }
      return miniPdf.save();
    };

    miniEntries.push({ 
      box_number: internalBoxNumber, 
      correctedBoxNumber: internalBoxNumber, 
      shipmentIndex: internalBoxNumber, // Using internal box number for ordering
      labelPdfBytes: miniPdfCreate() 
    });
  }

  // Resolve all mini PDF promises
  const resolvedEntries = await Promise.all(miniEntries.map(async entry => ({
    ...entry,
    labelPdfBytes: await entry.labelPdfBytes
  })));

  return resolvedEntries;
}

/**
 * Processes all shipments using the internal plan mapping.
 */
async function processAllShipmentsUsingPlanMapping(placementOption, planData, onProgress) {
  let allEntries = [];
  for (let i = 0; i < placementOption.shipments.length; i++) {
    const shipment = placementOption.shipments[i];
    onProgress?.(`Mapping shipment ${i + 1} of ${placementOption.shipments.length}...`);
    try {
      const entries = await processShipmentUsingPlanMapping(shipment, planData, onProgress);
      allEntries = allEntries.concat(entries);
    } catch (err) {
      console.error(`Error processing shipment ${shipment.shipment_id}: ${err.message}`);
    }
  }
  return allEntries;
}

/**
 * Merges all mini 2-page PDFs into one final PDF.
 * Before merging, it sorts them by the correctedBoxNumber in ascending (A–Z) order.
 */
async function mergeAllBoxes(allBoxEntries, onProgress) {
  onProgress?.("Merging PDFs...");
  // Sort by correctedBoxNumber as string with numeric comparison.
  allBoxEntries.sort((a, b) => {
    const aStr = a.correctedBoxNumber.toString();
    const bStr = b.correctedBoxNumber.toString();
    return aStr.localeCompare(bStr, undefined, { numeric: true });
  });
  console.log("Final sorted mini-PDF entries:", allBoxEntries);

  const finalPdf = await PDFDocument.create();
  for (const entry of allBoxEntries) {
    console.log(`Merging box number: ${entry.correctedBoxNumber}`);
    const miniDoc = await PDFDocument.load(entry.labelPdfBytes);
    const miniPages = miniDoc.getPages();
    const pagesToCopy = miniPages.map((_, idx) => idx);
    const copiedPages = await finalPdf.copyPages(miniDoc, pagesToCopy);
    copiedPages.forEach(page => finalPdf.addPage(page));
  }
  onProgress?.("Finalizing merged PDF...");
  return await finalPdf.save();
}

/**
 * Triggers a browser download for the provided PDF bytes.
 */
function downloadPdf(bytes, fileName = 'Merged UPS Labels.pdf') {
  const blob = new Blob([bytes], { type: 'application/pdf' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  a.click();
  URL.revokeObjectURL(url);
}

/**
 * Main function that:
 * 1) Fetches the plan data internally.
 * 2) Logs the internal box contents.
 * 3) Processes all shipments using the plan mapping (computing the intersection).
 * 4) Merges and triggers download of the final merged PDF.
 */
export async function fetchUpsMergedLabels(placementOption, planId, onProgress) {
  try {
    onProgress?.("Fetching plan data...");
    const planData = await fetchPlanData(planId);
    console.log("Received plan data:", planData);
    logInternalBoxContents(planData);
    onProgress?.("Processing shipments using plan mapping...");
    const allBoxEntries = await processAllShipmentsUsingPlanMapping(placementOption, planData, onProgress);
    const mergedBytes = await mergeAllBoxes(allBoxEntries, onProgress);
    onProgress?.("Download ready...");
    downloadPdf(mergedBytes);
    onProgress?.("Download complete.");
  } catch (error) {
    console.error("Error while processing UPS labels:", error);
    onProgress?.(`Error: ${error.message}`);
    if (error.message.includes("No suitable label found for shipment")) {
      onProgress?.("Notification: UPS labels haven't been generated yet or shipments haven't been created yet.");
      alert("UPS labels haven't been generated yet or shipments haven't been created yet.");
    }
  }
}
