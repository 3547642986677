import React, { useState, useRef, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Select, { components } from 'react-select'; // CHANGED
import OutboundItem from './OutboundItem';
import FiltersDropdown from './FiltersDropdown';
import Box from './Box';
import { Modal } from '@mui/material';
import dayjs from 'dayjs';
import { fetchUpsMergedLabels } from './USAHelper/MergeUPSLabels';
import ImportModal from './ImportModal';
import { BoxContext } from './BoxContext';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import SplitShipmentModal from './SplitShipmentModal';
import { BoxProvider } from './BoxContext';
import SendEmail from './SendEmail';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import NicknameModal from './NicknameModal';
import PickingListModal from './PickingListModal';
import ReactTooltip from 'react-tooltip'; // Import react-tooltip
import { Button, Typography, IconButton } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { LinearProgress, CircularProgress } from '@mui/material';
import { update } from 'lodash';
import ReassignPlanModal from './ReassignPlanModal';
const moment = require('moment');
const API_URL = process.env.REACT_APP_API_URL;

/* 
  // NEW: This is our custom option component for React-Select 
  // It includes the "Cancel Plan" button if plan isActive === true
*/
const CustomOption = (props) => {
  const { data, innerProps, innerRef } = props; 
  // data structure example: { value, label, isActive: boolean }

  return (
    <div
      ref={innerRef}
      {...innerProps}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '6px 8px',
        cursor: 'pointer',
        backgroundColor: props.isFocused ? '#e2e2e2' : 'white',
        width: '100%', // CHANGED: you can adjust the width of each option here
      }}
    >
      <div style={{ marginRight: '8px' }}>{data.label}</div>

      {data.isActive && (
        <button
          style={{
            marginLeft: 'auto',
            backgroundColor: 'red',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
            padding: '4px 8px',
            cursor: 'pointer',
            fontSize: '0.75rem',
          }}
          onClick={(e) => {
            e.stopPropagation(); // Prevent closing the dropdown
            props.selectProps.handleCancelPlan(data.value); 
          }}
        >
          Cancel Plan
        </button>
      )}
    </div>
  );
};

const Shipment = () => {
  const { shipmentId } = useParams();

  return (
    <BoxProvider shipmentId={shipmentId}>
      <ShipmentContent />
    </BoxProvider>
  );
};

const ShipmentContent = () => {
  const fileInputRef = useRef(null); // Create a ref for the file input
  const [dataLoaded, setDataLoaded] = useState(false);
  const { shipmentId } = useParams();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info'); // 'info', 'success', 'error', etc.
  const [filterByPackingGroup, setFilterByPackingGroup] = useState(false);
  const [filterOptions, setFilterOptions] = useState([]);
  const [showSplitShipmentModal, setShowSplitShipmentModal] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [newOutboundId, setNewOutboundId] = useState(null);

  const showSnackbar = (message, severity = 'info') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  // All from BoxContext
  const {
    shipmentInfo,
    shipmentItems,
    shelvingData,
    reloadShipmentData,
    servicesData,
    clientsData,
    clientInfo,
    fetchAsinData,
    loading,
    removeShelf,
    updateShelvingQty,
    removeShelving,
    fetchAllData,
    boxes,
    setSelectedNickname,
    selectedNickname,
    setBoxes,
    fetchStandardBoxes,
    refreshBoxes,
    plans,
    selectedPlan,
    setSelectedPlan,
    setSelectedPackingGroup,
    selectedPackingGroup,
    packingGroups
  } = useContext(BoxContext);

  // Add this line in ShipmentContent component
  const [openBoxNumber, setOpenBoxNumber] = useState(null);
  const [oversizedItemsWithoutFees, setOversizedItemsWithoutFees] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showShelfReminder, setShowShelfReminder] = useState(false);
  const [tooltipContent, setTooltipContent] = useState('');

  const [asinData, setAsinData] = useState([]);
  const [selectedBox, setSelectedBox] = useState(null);
  const [itemSearchTerm, setItemSearchTerm] = useState('');
  const [boxSearchTerm, setBoxSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('All');
  const [showSendEmailModal, setShowSendEmailModal] = useState(false);
  const [standardBoxes, setStandardBoxes] = useState([]);
  const [progress, setProgress] = useState(0);
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [internalNotes, setInternalNotes] = useState('');
  const [importedData, setImportedData] = useState([]);
  const [showImportModal, setShowImportModal] = useState(false);
  const [showPickingListModal, setShowPickingListModal] = useState(false);
  const [pickingListData, setPickingListData] = useState([]);
  const [showItemsForAllPlacements, setShowItemsForAllPlacements] = useState(false);
  const [showReassignPlanModal, setShowReassignPlanModal] = useState(false);
  const [mainOutboundId, setMainOutboundId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [showResetDialog, setShowResetDialog] = useState(false);
  const [isResetting, setIsResetting] = useState(false);

  // State for progress
  const [unitProgress, setUnitProgress] = useState(0);
  const [skuProgress, setSkuProgress] = useState(0);
  const [unitsPacked, setUnitsPacked] = useState(0);
  const [skusCompleted, setSkusCompleted] = useState(0);
  const [totalUnits, setTotalUnits] = useState(0);

  // Check for missing Oversized fees
  const checkOversizedItemsWithoutFees = () => {
    console.log("Checking for oversized items without fees...");

    const itemsWithoutFees = shipmentItems.filter(item => {
      const lengthInches = item.item.length_mm / 25.4;
      const widthInches = item.item.width_mm / 25.4;
      const heightInches = item.item.height_mm / 25.4;
      const weightLbs = item.item.weight_gm / 453.592;

      const isOversized = lengthInches > 18 || widthInches > 14 || heightInches > 8 || weightLbs > 20;
      const isHugeOversized = isOversized && (lengthInches >= 25 || widthInches >= 25 || heightInches >= 25);

      let expectedFee = '';
      if (isHugeOversized) expectedFee = 'Huge Oversized';
      else if (isOversized) expectedFee = 'Oversized';

      if (expectedFee) {
        console.log(`Item: ${item.item.title} (SKU: ${item.item.merchant_sku}) is expected to have fee: ${expectedFee}`);

        const hasFee = item.company_services.some(service => service.name === expectedFee && service.charge);
        if (!hasFee) {
          console.log(`  - Missing fee for ${expectedFee}`);
          return true; 
        }
      }
      return false; 
    });

    if (itemsWithoutFees.length > 0) {
      console.log("Oversized items without fees found:", itemsWithoutFees);
      setOversizedItemsWithoutFees(itemsWithoutFees);
      setIsModalOpen(true);
      return false; 
    }

    console.log("All oversized items have the correct fees applied.");
    return true; 
  };

  // NEW: Check for multiple ACTIVE plans
  useEffect(() => {
    if (!plans || plans.length === 0) return;

    const activePlans = plans.filter(plan => plan.status === 'ACTIVE');
    if (activePlans.length > 1) {
      alert(
        'Warning: There are multiple ACTIVE plans. Only one plan should be active at a time. ' +
        'Please cancel extra active plan(s) before proceeding.'
      );
      // Or use showSnackbar(...) if you prefer
    }
  }, [plans]);

  useEffect(() => {
    if (shipmentInfo) {
      setInternalNotes(shipmentInfo.internal_notes);
    }
  }, [shipmentInfo]);

  const handleResetBoxContents = async () => {
    try {
      setIsResetting(true);
      const response = await fetch(`${API_URL}/api/reset_box_contents`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          FBA_ID: selectedPlan.id, 
          selectedNickname,
        }),
      });

      if (response.ok) {
        console.log('Box contents reset successfully');
        refreshBoxes();
      } else {
        console.error('Failed to reset box contents');
      }
    } catch (error) {
      console.error('Error resetting box contents:', error);
    } finally {
      setIsResetting(false);
      setShowResetDialog(false);
    }
  };

  // CHANGED: handle Plan selection
  const handlePlanSelect = (selectedOption) => {
    if (plans.length === 1) {
      const plan = plans[0];
      setSelectedPlan(plan);
      console.log('Selected plan:', plan);
    } else {
      const plan = plans.find(p => p.id === selectedOption.value);
      setSelectedPlan(plan ? plan : null);
      console.log('Selected plan:', plan);
    }
    refreshBoxes();
  };

  // NEW: handleCancelPlan method
  const handleCancelPlan = async (planId) => {
    const confirmCancel = window.confirm(`Are you sure you want to cancel Plan: ${planId}?`);
    if (!confirmCancel) return;

    try {
      // Post to your cancel endpoint
      const response = await fetch(
        `https://app.yyzprep.ca/api/fba-transport/v2024/plans/${planId}/cancel-plan?api_token=Yh7l5CUTaZ1nIgAueWglafvm616hchHFFZxRjKjPHNBjB19b2jTDgGoCSpeq`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({plan_id : planId}),
        }
      );

      if (!response.ok) {
        throw new Error(`Failed to cancel plan with ID ${planId}`);
      }

      // Refresh to see updated plan statuses
      await reloadShipmentData();
      showSnackbar(`Plan ${planId} cancelled successfully!`, 'success');
    } catch (error) {
      console.error('Error cancelling plan:', error);
      showSnackbar(`Error cancelling plan: ${error.message}`, 'error');
    }
  };

  const handlePackingGroupSelect = (selectedOption) => {
    setSelectedPackingGroup(selectedOption.value);
    console.log(`Currently Selected Packing Group is:`, selectedOption.value);
  };

  const validateBoxDimensions = (boxes) => {
    const missingDimensions = [];
    boxes.forEach(box => {
      if (!box.height || !box.width || !box.length || !box.weight) {
        missingDimensions.push(
          `Box ${box.boxNumber} - missing ${
            !box.length ? 'Length' : ''
          }${!box.width ? 'Width' : ''}${!box.height ? 'Height' : ''}${
            !box.weight ? 'Weight' : ''
          }`
        );
      }
    });
    return missingDimensions;
  };

  const handleSplitShipment = async () => {
    if (selectedItems.length === 0) {
      alert("Please select at least one item to split.");
      return;
    }

    try {
      // Step 1: Create a new outbound
      const newOutboundResponse = await fetch(`${API_URL}/api/create_new_outbound`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          name: `${shipmentInfo.name} | Split`,
          warehouse_id: shipmentInfo.warehouse_id,
          notes: `This outbound was split from ${shipmentInfo.id} on ${new Date().toLocaleString('en-US', {
            timeZone: 'America/New_York'
          })}`,
          team_id: shipmentInfo.team_id
        }),
      });

      if (!newOutboundResponse.ok) {
        throw new Error('Failed to create new outbound');
      }

      const newOutboundData = await newOutboundResponse.json();
      const newOutboundId = newOutboundData.shipment_id;
      setNewOutboundId(newOutboundId);

      // Step 2: Add selected items to the new outbound
      for (const item of selectedItems) {
        const addItemResponse = await fetch(`${API_URL}/api/add_item_to_outbound`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            shipment_id: newOutboundId,
            item_id: item.item_id,
            quantity: item.quantity,
            expiry_date: item.expiry_date,
          }),
        });
        if (!addItemResponse.ok) {
          throw new Error(`Failed to add item ${item.item_id} to new outbound`);
        }
      }

      // Step 3: Confirm items are added
      const confirmResponse = await fetch(`${API_URL}/api/confirm_items_added`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ shipment_id: newOutboundId }),
      });
      if (!confirmResponse.ok) {
        throw new Error('Failed to confirm items added to new outbound');
      }

      // Step 4: Remove items from old outbound
      for (const item of selectedItems) {
        const deleteItemResponse = await fetch(`${API_URL}/api/delete_item_from_outbound`, {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            shipment_id: shipmentId,
            item_id: item.id,
          }),
        });
        if (!deleteItemResponse.ok) {
          throw new Error(`Failed to remove item ${item.id} from old outbound`);
        }
      }

      reloadShipmentData();
      setShowSuccessModal(true);
    } catch (error) {
      console.error('Error splitting shipment:', error);
      alert('Failed to split shipment. Please try again.');
    }
  };

  const handleFilterChange = (e) => {
    const selectedOptions = Array.from(e.target.selectedOptions, (option) => option.value);
    setFilterOptions(selectedOptions);

    // Update individual filter states
    setPrepFilter(selectedOptions.includes('prep'));
    setShelvedFilter(selectedOptions.includes('shelved'));
    setShowItemsForAllPlacements(selectedOptions.includes('allPlacements'));
  };

  const handleReassignPlan = async (outboundId, newPlanId) => {
    try {
      const newOutboundId = `${newPlanId}`;
      const response = await fetch(`${API_URL}/api/reassign_plan`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ shipmentId, outboundId, newPlanId })
      });
      if (!response.ok) {
        throw new Error('Failed to reassign plan');
      }
      const result = await response.json();
      console.log('Plan reassigned successfully:', result);
      refreshBoxes();
    } catch (error) {
      console.error('Error reassigning plan:', error);
    }
  };

  const groupAndSortPlans = (plans) => {
    const grouped = plans.reduce((acc, plan) => {
      const date = new Date(plan.created_at).toLocaleDateString();
      if (!acc[date]) acc[date] = [];
      acc[date].push(plan);
      return acc;
    }, {});

    const sorted = Object.keys(grouped).sort((a, b) => new Date(a) - new Date(b));
    return sorted.map(date => ({
      date,
      plans: grouped[date].sort((a, b) => a.name.localeCompare(b.name))
    }));
  };

  const handleConfirmImport = async () => {
    try {
      const replaceBoxContentsBody = { shipmentId, selectedPlan, boxes: importedData, selectedNickname };
      await fetch(`${API_URL}/api/replace_box_contents`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(replaceBoxContentsBody)
      });
      refreshBoxes();

      // Update expiry dates + services
      for (const item of importedData) {
        const { SKU, EXPIRY_DATE, SERVICES_ADDED } = item;
        const matchedItem = shipmentItems.find(shipmentItem => shipmentItem.item.merchant_sku === SKU);
        if (matchedItem && EXPIRY_DATE) {
          let formattedExpiryDate;
          if (!isNaN(EXPIRY_DATE) && typeof EXPIRY_DATE === 'number') {
            formattedExpiryDate = convertExcelDateToJSDate(EXPIRY_DATE);
          } else if (!isNaN(Date.parse(EXPIRY_DATE))) {
            formattedExpiryDate = EXPIRY_DATE;
          } else {
            console.error('Invalid date format:', EXPIRY_DATE);
            continue;
          }

          console.log('Formatted Expiry Date:', formattedExpiryDate);
          const updateItemPayload = {
            itemId: matchedItem.id,
            shipmentId: shipmentId,
            item: {
              ...matchedItem,
              expiry_date: formattedExpiryDate,
            }
          };

          const expiryResponse = await fetch(`${API_URL}/api/update-expiry`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(updateItemPayload),
          });

          if (!expiryResponse.ok) {
            console.error('Failed to update expiry date');
          }
        }

        // Handle services
        if (SERVICES_ADDED) {
          const services = SERVICES_ADDED.split(',').map(s => s.trim());
          const matchedServices = servicesData.services.filter(systemService =>
            services.includes(systemService.name)
          );

          if (matchedServices.length > 0) {
            const servicesPayload = matchedServices.reduce((acc, service, index) => {
              acc[index] = {
                id: service.id,
                name: service.name,
                charge: service.charge,
                advanced_options: service.advanced_options,
                quantity: 1,
              };
              return acc;
            }, {});

            const servicesResponse = await fetch(
              `https://app.yyzprep.ca/api/shipments/outbound/${shipmentId}/outbound-shipment-item/${matchedItem.id}/services?api_token=Yh7l5CUTaZ1nIgAueWglafvm616hchHFFZxRjKjPHNBjB19b2jTDgGoCSpeq`,
              {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ services: servicesPayload }),
              }
            );

            if (!servicesResponse.ok) {
              console.error(`Failed to update services for item ${matchedItem.id}`);
            }
          } else {
            console.warn(`No matching services found for item ${SKU}`);
          }
        }
      }
      reloadShipmentData();
      setShowImportModal(false);
    } catch (error) {
      console.error('Error confirming import:', error);
      setShowImportModal(false);
    }
  };

  const checkPlacementOptions = (plan) => {
    if (!plan || !plan.placement_options || plan.placement_options.length === 0) {
      console.log('No placement options available.');
      return;
    }

    const confirmedOptions = plan.placement_options.filter(option => option.confirmed_at);
    if (confirmedOptions.length === 0) {
      console.log('No placement option has been confirmed.');
      return;
    }

    const latestConfirmedOption = confirmedOptions.reduce((latest, current) => {
      return new Date(current.confirmed_at) > new Date(latest.confirmed_at) ? current : latest;
    });
    return latestConfirmedOption;
  };

  const convertExcelDateToJSDate = (serial) => {
    const excelEpoch = new Date(1899, 11, 30);
    const jsDate = new Date(excelEpoch.getTime() + (serial * 86400 * 1000));
    return jsDate.toISOString().split('T')[0];
  };

  const handleImportExcel = async (event) => {
    console.log("Uploaded file...");
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = async (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });

      const boxContentsSheet = workbook.Sheets['Box Contents'];
      const shipmentItemsSheet = workbook.Sheets['Shipment Items'];

      const boxContents = XLSX.utils.sheet_to_json(boxContentsSheet);
      const shipmentItems = XLSX.utils.sheet_to_json(shipmentItemsSheet);

      const combinedData = boxContents.map(boxItem => {
        const shipItem = shipmentItems.find(item => item.SKU === boxItem.SKU) || {};
        return {
          ...boxItem,
          EXPIRY_DATE: shipItem.EXPIRY_DATE || '',
          SERVICES_ADDED: shipItem.SERVICES_ADDED || '',
          FNSKU: shipItem.FNSKU || '',
          ASIN: shipItem.ASIN || '',
          LENGTH: boxItem.LENGTH || shipItem.LENGTH || '',
          WIDTH: boxItem.WIDTH || shipItem.WIDTH || '',
          HEIGHT: boxItem.HEIGHT || shipItem.HEIGHT || '',
          WEIGHT: boxItem.WEIGHT || shipItem.WEIGHT || '',
        };
      });

      const boxNumbers = [...new Set(combinedData.map(item => parseInt(item.BOX_NUMBER)))];
      boxNumbers.sort((a, b) => a - b);

      for (let i = 0; i < boxNumbers.length; i++) {
        if (boxNumbers[i] !== i + 1) {
          alert(`Box numbers must be consecutive starting from 1. Missing box number: ${i + 1}`);
          return;
        }
      }

      setImportedData(combinedData);
      setShowImportModal(true);
      fileInputRef.current.value = null;
    };
    reader.readAsArrayBuffer(file);
  };

  const updateItemServices = async (item, services) => {
    try {
      const response = await fetch(
        `https://app.yyzprep.ca/api/services?type[]=outbound_shipment_item&api_token=Yh7l5CUTaZ1nIgAueWglafvm616hchHFFZxRjKjPHNBjB19b2jTDgGoCSpeq`
      );
      const servicesData = await response.json();
      const matchedServices = servicesData.services.filter(sysService => services.includes(sysService.name));

      if (matchedServices.length > 0) {
        const servicesPayload = matchedServices.reduce((acc, service, index) => {
          acc[index] = {
            id: service.id,
            name: service.name,
            charge: service.charge,
            advanced_options: service.advanced_options,
            quantity: 1,
          };
          return acc;
        }, {});

        await fetch(
          `https://app.yyzprep.ca/api/shipments/outbound/${item.SHIPMENT_ID}/outbound-shipment-item/${item.ITEM_ID}/services?api_token=Yh7l5CUTaZ1nIgAueWglafvm616hchHFFZxRjKjPHNBjB19b2jTDgGoCSpeq`,
          {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ services: servicesPayload }),
          }
        );
        console.log(`Services updated for item ${item.SKU}`);
      } else {
        console.warn(`No matching services found for item ${item.SKU}`);
      }
    } catch (error) {
      console.error('Error updating services:', error);
    }
  };

  useEffect(() => {
    refreshBoxes();
  }, [refreshBoxes]);

  let skuList = [];
  const [nicknames, setNicknames] = useState([]);
  const [prepFilter, setPrepFilter] = useState(false);
  const [shelvedFilter, setShelvedFilter] = useState(false);
  const [boxSortOrder, setBoxSortOrder] = useState('asc');

  const handleInternalNotesChange = (e) => {
    setInternalNotes(e.target.value);
  };

  async function toggleLock(shipmentId) {
    try {
      const response = await fetch(
        `https://app.yyzprep.ca/api/shipments/outbound/${shipmentId}/toggleLock?api_token=Yh7l5CUTaZ1nIgAueWglafvm616hchHFFZxRjKjPHNBjB19b2jTDgGoCSpeq`,
        { method: 'GET' }
      );
      const data = await response.json();
      console.log("Toggle Lock Response Data: ", data);
      return data;
    } catch (error) {
      console.error('Error toggling lock:', error);
      throw error;
    }
  }

  const handleInternalNotesBlur = async () => {
    const updatedShipmentInfo = {
      notes: shipmentInfo.notes,
      internal_notes: internalNotes
    };
    let retry = false;

    try {
      const response = await fetch(
        `https://app.yyzprep.ca/api/shipments/outbound/${shipmentId}?api_token=Yh7l5CUTaZ1nIgAueWglafvm616hchHFFZxRjKjPHNBjB19b2jTDgGoCSpeq`,
        {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(updatedShipmentInfo),
        }
      );
      if (!response.ok) {
        throw new Error('Failed to update shipment info');
      }

      const data = await response.json();
      showSnackbar('Shipment info updated successfully', 'success');
      console.log("Shipment info updated successfully:", data);
    } catch (error) {
      console.error('Error updating shipment info, attempting to toggle lock:', error);

      try {
        await toggleLock(shipmentId);
        retry = true;
      } catch (lockError) {
        console.error('Error toggling lock:', lockError);
      }

      if (retry) {
        try {
          const response = await fetch(
            `https://app.yyzprep.ca/api/shipments/outbound/${shipmentId}?api_token=Yh7l5CUTaZ1nIgAueWglafvm616hchHFFZxRjKjPHNBjB19b2jTDgGoCSpeq`,
            {
              method: 'PUT',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(updatedShipmentInfo),
            }
          );
          if (!response.ok) {
            throw new Error('Failed to update shipment info');
          }
          const data = await response.json();
          showSnackbar('Shipment info updated successfully', 'success');
          console.log("Shipment info updated successfully after toggle lock:", data);
        } catch (retryError) {
          console.error('Error updating shipment info after toggle lock:', retryError);
        } finally {
          try {
            await toggleLock(shipmentId);
          } catch (finalLockError) {
            console.error('Error toggling lock back:', finalLockError);
          }
        }
      }
    }
  };

  const checkProgress = async () => {
    try {
      const response = await fetch(`${API_URL}/api/submit_box_progress`);
      const result = await response.json();
      setProgress(result.progress);
      if (result.progress >= 0 && result.progress < 100) {
        setTimeout(checkProgress, 1000);
      } else {
        setProgress(0);
        setShowProgressBar(false);
        setIsSubmitting(false);
      }
    } catch (error) {
      setProgress(0);
      setShowProgressBar(false);
      setIsSubmitting(false);
      console.error('Error checking progress:', error);
    }
  };

  const fetchNicknames = async () => {
    try {
      const response = await fetch(`${API_URL}/api/nicknames`);
      const data = await response.json();
      setNicknames(data);
    } catch (error) {
      console.error('Error fetching nicknames:', error);
    }
  };

  useEffect(() => {
    fetchNicknames();
  }, []);

  const handleNicknameSelect = (nickname) => {
    setSelectedNickname(nickname);
    window.selectedNickname = nickname;
  };

  useEffect(() => {
    if (selectedNickname) {
      console.log('Fetching all data...');
    }
  }, [selectedNickname, shipmentId]);

  useEffect(() => {
  }, [boxes]);

  const findClient = (team_id, clients) => {
    const client = clients.find(client => client.id === team_id);
    if (!client) return { name: 'Unknown', country: 'Unknown', email: '' };

    const name = client.name;
    let country = 'Unknown';
    if (name.includes('(US)')) {
      country = 'USA';
    } else if (name.includes('(CA)')) {
      country = 'Canada';
    }
    return { name, country, email: client.email };
  };

  useEffect(() => {
    if (shipmentInfo && selectedPlan) {
      validateShipFromAddress(selectedPlan, clientInfo.country);
    }
  }, [shipmentInfo, selectedPlan]);

  const validateShipFromAddress = (selectedPlan, clientCountry) => {
    if (!selectedPlan || !selectedPlan.ship_from_address || !clientCountry) return;
    const shipFromCountryCode = selectedPlan.ship_from_address.country_code;

    if (
      (shipFromCountryCode === 'CA' && clientCountry === 'USA') ||
      (shipFromCountryCode === 'US' && clientCountry === 'Canada')
    ) {
      alert(
        `The ship-from location for this shipment is ${shipFromCountryCode}, but it looks like it's a ${clientCountry} shipment. Please double-check before continuing.`
      );
    } else {
      console.log(`ship from country code is ${shipFromCountryCode} and client country is ${clientCountry}`);
    }
  };

  const generatePickingList = () => {
    const shelvedItems = shipmentItems.filter(item =>
      shelvingData.some(data => data.SKU === item.item.merchant_sku)
    );

    const sortedShelvedItems = shelvedItems.sort((a, b) => {
      const getShelvingCode = (sku) => shelvingData.find(data => data.SKU === sku).FULL_SHELF_CODE;
      const [aAisle, aRow, aShelf, aBin] = getShelvingCode(a.item.merchant_sku).split('-');
      const [bAisle, bRow, bShelf, bBin] = getShelvingCode(b.item.merchant_sku).split('-');

      if (aAisle !== bAisle) return aAisle.localeCompare(bAisle);
      if (aRow !== bRow) return parseInt(aRow) - parseInt(bRow);
      if (aShelf !== bShelf) return parseInt(aShelf) - parseInt(bShelf);
      if (aBin && bBin) return aBin.localeCompare(bBin);
      return 0;
    });

    const listData = sortedShelvedItems.map(item => {
      const shelvingCode = shelvingData.find(data => data.SKU === item.item.merchant_sku).FULL_SHELF_CODE;
      return {
        title: item.item.title,
        quantity: item.quantity,
        shelvingCode
      };
    });

    setPickingListData(listData);
    setShowPickingListModal(true);
  };

  const handleUpdateBoxDetails = async (updatedBox) => {
    if (updatedBox.items.length > 0) {
      try {
        const length = updatedBox.items[0].LENGTH;
        const width = updatedBox.items[0].WIDTH;
        const height = updatedBox.items[0].HEIGHT;
        const weight = updatedBox.items[0].WEIGHT;

        const response = await fetch(`${API_URL}/api/update_box_details`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ shipmentId, selectedPlan, updatedBox, length, width, height, weight, selectedNickname }),
        });

        if (!response.ok) {
          throw new Error('Failed to update box details');
        }
        await refreshBoxes();
      } catch (error) {
        console.error('Error updating box details:', error);
      }
    }

    setBoxes(prevBoxes =>
      prevBoxes.map(box => (box.boxNumber === updatedBox.boxNumber ? updatedBox : box))
    );
    if (selectedBox && selectedBox.boxNumber === updatedBox.boxNumber) {
      setSelectedBox(updatedBox);
    }
  };

  const handleBoxClick = (box) => {
    setOpenBoxNumber(prevBoxNumber => (prevBoxNumber === box.boxNumber ? null : box.boxNumber));
    setSelectedBox(prevBox => (prevBox === box ? null : box));
  };

  const handleAddBox = async () => {
    const newBoxNumber = boxes.length + 1;
    const newBox = {
      boxNumber: newBoxNumber,
      items: [],
      LENGTH: null,
      WIDTH: null,
      HEIGHT: null,
      WEIGHT: null,
      OUTBOUND_ID: shipmentId,
      FBA_ID: selectedPlan.id,
      employee: selectedNickname
    };
    try {
      const response = await fetch(`${API_URL}/api/add_box`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(newBox)
      });
      const result = await response.json();
      if (result.success) {
        refreshBoxes();
      } else {
        console.error('Error adding box:', result.error);
      }
    } catch (error) {
      console.error('Error adding box:', error);
    }
  };

  const filteredDbData = boxes
    .filter(box =>
      (box.items.length === 0 ||
        box.items.some(item =>
          item.SKU.toLowerCase().includes(boxSearchTerm.toLowerCase()) ||
          item.ASIN.toLowerCase().includes(boxSearchTerm.toLowerCase()) ||
          item.FNSKU.toLowerCase().includes(boxSearchTerm.toLowerCase())
        )) &&
      (!filterByPackingGroup ||
        box.items.length === 0 ||
        (selectedPackingGroup?.items &&
          box.items.some(boxItem =>
            selectedPackingGroup.items.some(packingItem => packingItem.msku === boxItem.SKU)
          )))
    )
    .sort((a, b) =>
      boxSortOrder === 'asc'
        ? a.boxNumber.localeCompare(b.boxNumber)
        : b.boxNumber.localeCompare(a.boxNumber)
    );

  const sortedDbData = [...filteredDbData].sort((a, b) => {
    if (boxSortOrder === 'asc') {
      return a.boxNumber - b.boxNumber;
    } else {
      return b.boxNumber - a.boxNumber;
    }
  });

  const handleDuplicateBox = async (box, times = 1) => {
    try {
      const response = await fetch(`${API_URL}/api/duplicate_box`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          shipmentId,
          selectedPlan,
          box,
          maxBoxLength: boxes.length,
          times,
          selectedNickname
        })
      });
      console.log("Sending dupe info: ", { shipmentId, selectedPlan, box, maxBoxLength: boxes.length, times, selectedNickname });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to duplicate box: ${errorText}`);
      }
      await refreshBoxes();
    } catch (error) {
      console.error('Error duplicating box:', error);
    }
  };

  const calculateTotalQuantityInBoxes = (sku) => {
    if (!Array.isArray(boxes)) {
      return 0;
    }
    return boxes.reduce((acc, box) => {
      return (
        acc +
        box.items.reduce((itemAcc, item) => {
          return itemAcc + (item.SKU === sku ? Number(item.QTY) : 0);
        }, 0)
      );
    }, 0);
  };

  const getItemStatus = (item) => {
    const totalQuantity = Number(item.quantity);
    const quantityInBoxes = calculateTotalQuantityInBoxes(item.item.merchant_sku);
    if (quantityInBoxes === 0) {
      return 'Not Started';
    } else if (quantityInBoxes < totalQuantity) {
      return 'In Progress';
    } else if (quantityInBoxes === totalQuantity) {
      return 'Completed';
    } else if (quantityInBoxes > totalQuantity) {
      return 'Over Limit';
    }
  };

  const filterItemsByStatus = (items, status) => {
    if (status === 'All') return items;
    return items.filter(item => getItemStatus(item) === status);
  };

  const filterItemsByPrep = (items, prepFilter) => {
    if (!prepFilter) return items;
    return items.filter(item => item.item.prep_instructions.length > 1);
  };

  const filterItemsByShelved = (items, shelvedFilter) => {
    if (!shelvedFilter) return items;
    return items.filter(item => shelvingData.some(data => data.SKU === item.item.merchant_sku));
  };

  const filterItemsByPackingGroup = (items, selectedPackingGroup, showItemsForAllPlacements) => {
    if (showItemsForAllPlacements || !selectedPackingGroup) return items;
    return items.filter(item =>
      selectedPackingGroup?.items.some(planItem => planItem.msku === item.item.merchant_sku)
    );
  };

  useEffect(() => {
    console.log('Modal state changed:', showSendEmailModal);
  }, [showSendEmailModal]);

  useEffect(() => {
    const x = filterItemsByPackingGroup(shipmentItems, selectedPackingGroup, showItemsForAllPlacements);
    if (x && boxes) {
      const totalUnitsValue = x.reduce((acc, item) => acc + item.quantity, 0);
      const unitsPackedValue = x.reduce(
        (acc, item) => acc + calculateTotalQuantityInBoxes(item.item.merchant_sku),
        0
      );
      const totalSKUs = x.length;
      const skusCompletedValue = x.filter(
        item => calculateTotalQuantityInBoxes(item.item.merchant_sku) >= item.quantity
      ).length;

      setTotalUnits(totalUnitsValue);
      setUnitsPacked(unitsPackedValue);
      setSkusCompleted(skusCompletedValue);

      const unitProgressValue =
        totalUnitsValue > 0 ? (unitsPackedValue / totalUnitsValue) * 100 : 0;
      setUnitProgress(unitProgressValue);

      const skuProgressValue = totalSKUs > 0 ? (skusCompletedValue / totalSKUs) * 100 : 0;
      setSkuProgress(skuProgressValue);
    }
  }, [shipmentItems, boxes, selectedPlan]);

  const downloadUPSLabels = () => {
    if (!selectedPlan) {
      alert("Please select a plan before downloading UPS labels.");
      return;
    }
    fetchUpsMergedLabels(checkPlacementOptions(selectedPlan), selectedPlan.id);
  };

  const exportToExcel = () => {
    const wb = XLSX.utils.book_new();
    const flattenedData = [];

    boxes.forEach(box => {
      box.items.forEach(item => {
        flattenedData.push({
          BOX_NUMBER: box.boxNumber,
          TITLE: item.TITLE,
          SKU: item.SKU,
          QTY: item.QTY,
          LENGTH: item.LENGTH,
          WIDTH: item.WIDTH,
          HEIGHT: item.HEIGHT,
          WEIGHT: item.WEIGHT
        });
      });
    });

    const wsBoxes = XLSX.utils.json_to_sheet(flattenedData);
    XLSX.utils.book_append_sheet(wb, wsBoxes, 'Box Contents');

    const shipmentItemsData = shipmentItems.map(item => ({
      TITLE: item.item.title,
      SKU: item.item.merchant_sku,
      ASIN: item.item.asin,
      FNSKU: item.item.fnsku,
      EXPIRY_DATE: item.expiry_date || '',
      SERVICES_ADDED: item.company_services.map(service => service.name).join(', ')
    }));
    const wsShipmentItems = XLSX.utils.json_to_sheet(shipmentItemsData);
    XLSX.utils.book_append_sheet(wb, wsShipmentItems, 'Shipment Items');

    XLSX.writeFile(wb, `Export ${selectedPlan.id}.xlsx`);
  };

  const handleSubmitBoxContents = async (confirmed = false, ignoreOversizedCheck = false) => {
    const missingDimensions = validateBoxDimensions(boxes);
    if (missingDimensions.length > 0) {
      alert(`Please fix the following missing dimensions:\n${missingDimensions.join('\n')}`);
      return;
    }
    if (!ignoreOversizedCheck && !checkOversizedItemsWithoutFees()) {
      return;
    }
    if (!confirmed) {
      setShowShelfReminder(true);
      return;
    }

    const transformedBoxes = [];
    let currentBox = null;
    let repeatCount = 1;

    const latestPackingOption = selectedPlan.packing_options.reduce((latest, current) => {
      return !latest || new Date(current.confirmed_at) > new Date(latest.confirmed_at)
        ? current
        : latest;
    }, null);

    const packingGroups = latestPackingOption?.packing_groups || [];

    const findPackingGroupId = (boxItems, packingGroups) => {
      for (const packingGroup of packingGroups) {
        const packingGroupMsus = new Set(packingGroup.items.map(packingItem => packingItem.msku));
        const allItemsMatch = boxItems.every(boxItem => packingGroupMsus.has(boxItem.SKU));
        if (allItemsMatch) {
          return packingGroup.id;
        }
      }
      return null;
    };

    for (let i = 0; i < boxes.length; i++) {
      const box = boxes[i];
      let packing_group_id = null;

      if (latestPackingOption && latestPackingOption.packing_groups) {
        for (const packingGroup of latestPackingOption.packing_groups) {
          const allItemsMatch = box.items.every(boxItem =>
            packingGroup.items.some(packingItem => packingItem.msku === boxItem.SKU)
          );
          if (allItemsMatch) {
            packing_group_id = packingGroup.id;
            break;
          }
        }
      }

      const transformedBox = {
        length_mm: parseInt(box.items[0].LENGTH, 10) * 10,
        width_mm: parseInt(box.items[0].WIDTH, 10) * 10,
        height_mm: parseInt(box.items[0].HEIGHT, 10) * 10,
        weight_gm: parseInt(box.items[0].WEIGHT, 10) * 1000,
        repeat: 1,
        packing_group_id: packing_group_id,
        items: box.items.map(item => {
          const matchingItem = shipmentItems.find(
            shipmentItem => shipmentItem.item.merchant_sku === item.SKU
          );
          const planItem = selectedPlan.items.find(planItem => planItem.msku === item.SKU);
          return {
            item_id: matchingItem ? matchingItem.item.id : null,
            quantity: item.QTY,
            plan_item_id: planItem ? planItem.id : null,
            expiration: matchingItem ? matchingItem.expiry_date : null
          };
        })
      };

      if (currentBox && JSON.stringify(currentBox) === JSON.stringify(transformedBox)) {
        repeatCount++;
      } else {
        if (currentBox) {
          currentBox.repeat = repeatCount;
          transformedBoxes.push(currentBox);
        }
        currentBox = transformedBox;
        repeatCount = 1;
      }
    }

    if (currentBox) {
      currentBox.repeat = repeatCount;
      transformedBoxes.push(currentBox);
    }

    console.log("Transformed boxes we're submitting:");
    console.log(transformedBoxes);

    setShowProgressBar(true);
    setIsSubmitting(true);

    await fetch(`${API_URL}/api/begin_box_progress`);
    checkProgress();

    try {
      const response = await fetch(`${API_URL}/api/submit_box_contents`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ shipmentId, selectedPlan, boxes: transformedBoxes })
      });

      const result = await response.json();
      if (!response.ok) {
        throw new Error(result.message);
      }

      window.open(`https://app.yyzprep.ca/staff/fba-transport/v2024/plans/${selectedPlan.id}#box_contents`,'_blank');
    } catch (error) {
      console.error('Error submitting box contents:', error);
      setIsSubmitting(false);
      alert(`Error submitting box contents: ${error.message}`);
    }
  };

  if (!selectedNickname) {
    return <NicknameModal nicknames={nicknames} onSelect={handleNicknameSelect} />;
  }

  if (loading) {
    return <div className="p-4">Loading...</div>;
  }

  if (!shipmentInfo || !shipmentItems) {
    return <div className="p-4">No data available</div>;
  }

  const statusCounts = {
    NotStarted: 0,
    InProgress: 0,
    Completed: 0,
    OverLimit: 0
  };

  const skuCount = shipmentItems.length;
  const itemCount = shipmentItems.reduce((total, item) => total + item.quantity, 0);

  let filteredShipmentItems = shipmentItems.filter(item =>
    item.item.merchant_sku.toLowerCase().includes(itemSearchTerm.toLowerCase()) ||
    item.item.title.toLowerCase().includes(itemSearchTerm.toLowerCase()) ||
    item.item.asin.toLowerCase().includes(itemSearchTerm.toLowerCase()) ||
    item.item.fnsku.toLowerCase().includes(itemSearchTerm.toLowerCase())
  );

  filteredShipmentItems = filterItemsByStatus(filteredShipmentItems, statusFilter);
  filteredShipmentItems = filterItemsByPrep(filteredShipmentItems, prepFilter);
  filteredShipmentItems = filterItemsByShelved(filteredShipmentItems, shelvedFilter);
  filteredShipmentItems = filterItemsByPackingGroup(filteredShipmentItems, selectedPackingGroup, showItemsForAllPlacements);

  filterItemsByPackingGroup(filteredShipmentItems, selectedPlan, showItemsForAllPlacements).forEach(item => {
    const status = getItemStatus(item);
    if (status === 'Not Started') statusCounts.NotStarted++;
    if (status === 'In Progress') statusCounts.InProgress++;
    if (status === 'Completed') statusCounts.Completed++;
    if (status === 'Over Limit') statusCounts.OverLimit++;
  });

  if (selectedPlan) {
    filteredShipmentItems = filteredShipmentItems.map(item => {
      const planItem = selectedPlan.items.find(planItem => planItem.msku === item.item.merchant_sku);
      if (planItem) {
        return { ...item, quantity: planItem.quantity };
      }
      return item;
    });
  }

  const totalSKUs = shipmentItems.length;

  // CHANGED: Build planOptions with isActive
  const groupedPlans = plans.reduce((acc, plan) => {
    const date = moment(plan.created_at).format('MMM D, h:mm:ss A');
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(plan);
    return acc;
  }, {});

  const planOptions = Object.keys(groupedPlans)
    .sort((a, b) => new Date(b) - new Date(a))
    .map(date => ({
      label: date,
      options: groupedPlans[date]
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(plan => ({
          value: plan.id,
          label: `${plan.inbound_plan_id} | ${plan.items.length} SKUs - ${plan.items.reduce((sum, item) => sum + item.quantity, 0)} Units${
            plan.is_canceled ? ' (Cancelled)' : plan.is_accepted ? ' (Accepted)' : ''
          }`,
          isActive: plan.status === 'ACTIVE', // NEW
        }))
    }));

  const packingGroupOptions = packingGroups?.map(group => {
    const skuSet = new Set();
    let totalUnits = 0;
    group.items.forEach(item => {
      skuSet.add(item.msku);
      totalUnits += item.quantity;
    });
    return {
      value: group,
      label: `(...${group.id.slice(-3)}) ${skuSet.size} SKUs - ${totalUnits} Units`
    };
  }) || [];

  const itemsPerPage = 15;
  const totalPages = Math.ceil(filteredShipmentItems.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredShipmentItems.slice(indexOfFirstItem, indexOfLastItem);

  const handleShowReassignModal = (mainOutboundId) => {
    setMainOutboundId(mainOutboundId);
    setShowReassignPlanModal(true);
  };

  const handleTitleMouseEnter = () => {
    setTooltipContent(shipmentInfo.name);
  };

  return (
    <div className="p-4">
      <>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={1250}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </>
      <header className="bg-blue-500 text-white p-6 rounded-t-lg shadow-md">
        <div className="flex flex-col md:flex-row justify-between items-start">
          <div className="flex-2 pr-4 w-full md:w-auto">
            <h1
              className="text-2xl font-bold mb-4 truncate w-96"
              data-tip={shipmentInfo.name}
              onMouseEnter={handleTitleMouseEnter}
            >
              {shipmentInfo.name}
            </h1>
            <ReactTooltip />

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 text-sm">
              <div className="text-white">
                <p>
                  <span className="font-bold">Created:</span>{' '}
                  {new Date(shipmentInfo.created_at).toLocaleString()}
                </p>
                <p>
                  <span className="font-bold">Shipment ID:</span>{' '}
                  <a
                    href={`https://app.yyzprep.ca/staff/shipments/outbound/${shipmentInfo.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="underline text-white"
                  >
                    {shipmentInfo.id}
                  </a>
                </p>
                <p>
                  <span className="font-bold">SKU Count:</span> {skuCount}
                </p>
                <p>
                  <span className="font-bold">Item Count:</span> {itemCount}
                </p>
              </div>
              <div>
                {clientInfo && (
                  <>
                    <p>
                      <span className="font-bold">Client:</span> {clientInfo.name}
                    </p>
                    <p>
                      <span className="font-bold">Country:</span> {clientInfo.country}
                    </p>
                  </>
                )}
                <p>
                  <span className="font-bold">Employee:</span> {selectedNickname}
                </p>
                <div className="mt-2">
                  <span
                    className={`px-2 py-1 rounded-full text-xs font-bold ${
                      shipmentInfo.status.toLowerCase() === 'shipped'
                        ? 'bg-green-500'
                        : shipmentInfo.status.toLowerCase() === 'open'
                        ? 'bg-red-500'
                        : shipmentInfo.status.toLowerCase() === 'planning' ||
                          shipmentInfo.status.toLowerCase() === 'planned'
                        ? 'bg-orange-500'
                        : shipmentInfo.status.toLowerCase() === 'created'
                        ? 'bg-yellow-500'
                        : ''
                    }`}
                  >
                    Status: {shipmentInfo.status.toUpperCase()}
                  </span>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-4">
              <div>
                <span className="text-sm">
                  Units Completed: {Math.round(unitProgress)}% ({unitsPacked}/{totalUnits})
                </span>
              </div>
              <div>
                <span className="text-sm">SKUs Completed: {Math.round(skuProgress)}%</span>
              </div>
            </div>
          </div>

          <div className="flex-1 pl-4 w-full md:w-auto mt-6 md:mt-0">
            <div className="flex flex-col">
              <div className="grid grid-cols-2 gap-4">
                <textarea
                  className="w-full h-24 p-2 border border-gray-300 rounded text-black"
                  placeholder="Client Notes"
                  value={shipmentInfo.notes || ''}
                  readOnly
                />
                <textarea
                  className="w-full h-24 p-2 border border-gray-300 rounded text-black"
                  placeholder="Warehouse Notes"
                  value={internalNotes || ''}
                  onChange={handleInternalNotesChange}
                  onBlur={handleInternalNotesBlur}
                />
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
  <div>
    <label className="block text-sm font-medium text-black">Select Plan:</label>
    <Select
      value={planOptions.find(option => option.value === selectedPlan)}
      onChange={handlePlanSelect}
      options={planOptions}
      components={{ Option: CustomOption }}
      handleCancelPlan={handleCancelPlan}
      styles={{
        control: (provided) => ({
          ...provided,
          borderColor: 'gray',
          boxShadow: 'none',
          backgroundColor: 'white',
          width: '100%',
        }),
        singleValue: (provided) => ({
          ...provided,
          color: 'black',
        }),
        placeholder: (provided) => ({
          ...provided,
          color: 'black',
        }),
        input: (provided) => ({
          ...provided,
          color: 'black',
        }),
        menu: (provided) => ({
          ...provided,
          backgroundColor: 'white',
          color: 'black',
        }),
        option: (provided, state) => ({
          ...provided,
          color: 'black',
          backgroundColor: state.isFocused ? '#e2e2e2' : 'white',
          cursor: 'pointer',
        }),
        dropdownIndicator: (provided) => ({
          ...provided,
          color: 'black',
          '&:hover': {
            color: 'black',
          },
        }),
        indicatorSeparator: (provided) => ({
          ...provided,
          backgroundColor: 'black',
        }),
        clearIndicator: (provided) => ({
          ...provided,
          color: 'black',
          '&:hover': {
            color: 'black',
          },
        }),
      }}
      className="mt-1 block w-full text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
    />
  </div>

  <div>
    <label className="block text-sm font-medium text-black">
      Select Packing Group:
    </label>
    <Select
      value={packingGroupOptions.find(
        option => option.value === selectedPackingGroup?.value
      )}
      onChange={handlePackingGroupSelect}
      options={packingGroupOptions}
      styles={{
        control: (provided) => ({
          ...provided,
          borderColor: 'gray',
          boxShadow: 'none',
          backgroundColor: 'white',
          width: '100%',
        }),
        singleValue: (provided) => ({
          ...provided,
          color: 'black',
        }),
        placeholder: (provided) => ({
          ...provided,
          color: 'black',
        }),
        input: (provided) => ({
          ...provided,
          color: 'black',
        }),
        menu: (provided) => ({
          ...provided,
          backgroundColor: 'white',
          color: 'black',
        }),
        option: (provided, state) => ({
          ...provided,
          color: 'black',
          backgroundColor: state.isFocused ? '#e2e2e2' : 'white',
          cursor: 'pointer',
        }),
        dropdownIndicator: (provided) => ({
          ...provided,
          color: 'black',
          '&:hover': {
            color: 'black',
          },
        }),
        indicatorSeparator: (provided) => ({
          ...provided,
          backgroundColor: 'black',
        }),
        clearIndicator: (provided) => ({
          ...provided,
          color: 'black',
          '&:hover': {
            color: 'black',
          },
        }),
      }}
      className="mt-1 block w-full text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
      placeholder="Select a packing group..."
    />
  </div>
</div>

              <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mt-4">
                <button
                  className="bg-gray-800 text-white px-4 py-2 shadow-md hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-300 transition flex items-center justify-center"
                  onClick={() => setShowSplitShipmentModal(true)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 mr-2"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v3.586L7.707 9.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 10.586V7z"
                      clipRule="evenodd"
                    />
                  </svg>
                  Split Shipment
                </button>
                <button
                  className="bg-gray-800 text-white px-4 py-2 shadow-md hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-300 transition flex items-center justify-center"
                  onClick={downloadUPSLabels}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 mr-2"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                  Download UPS Labels
                </button>
                <button
                  className="bg-gray-800 text-white px-4 py-2 shadow-md hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-300 transition flex items-center justify-center"
                  onClick={exportToExcel}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 mr-2"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                  Export to Excel
                </button>
                <label className="bg-gray-800 text-white px-4 py-2 shadow-md hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-300 transition cursor-pointer flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 mr-2"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 3a1 1 0 011 1v10a1 1 0 11-2 0V4a1 1 0 011-1z"
                      clipRule="evenodd"
                    />
                    <path
                      fillRule="evenodd"
                      d="M5 9a1 1 0 011 1v4a1 1 0 11-2 0v-4a1 1 0 011-1z"
                      clipRule="evenodd"
                    />
                    <path
                      fillRule="evenodd"
                      d="M15 7a1 1 0 011 1v6a1 1 0 11-2 0V8a1 1 0 011-1z"
                      clipRule="evenodd"
                    />
                  </svg>
                  Import Excel
                  <input
                    type="file"
                    accept=".xlsx, .xls"
                    className="hidden"
                    ref={fileInputRef}
                    onChange={handleImportExcel}
                  />
                </label>

                <button
                  className={`px-4 py-2 shadow-md focus:outline-none focus:ring-2 transition text-center flex items-center justify-center ${
                    isSubmitting
                      ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                      : 'bg-green-500 text-white hover:bg-green-600 focus:ring-green-300'
                  }`}
                  onClick={handleSubmitBoxContents}
                  disabled={isSubmitting}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 mr-2"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V4a2 2 0 00-2-2H6zm2 14a1 1 0 100-2 1 1 0 000 2zm0-4a1 1 0 100-2 1 1 0 000 2zm0-4a1 1 0 100-2 1 1 0 000 2zm4 4a1 1 0 100-2 1 1 0 000 2zm0-4a1 1 0 100-2 1 1 0 000 2z"
                      clipRule="evenodd"
                    />
                  </svg>
                  {isSubmitting ? 'Submitting...' : 'Submit Box Contents'}
                </button>
                <button
                  className="bg-gray-800 text-white px-4 py-2 shadow-md hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-300 transition flex items-center justify-center"
                  onClick={() => setShowSendEmailModal(true)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 mr-2"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                    <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                  </svg>
                  Contact Client
                </button>
                <button
                  className="bg-gray-800 text-white px-4 py-2 shadow-md hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-300 transition flex items-center justify-center"
                  onClick={generatePickingList}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 mr-2"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M3 5a2 2 0 012-2h10a2 2 0 012 2v10a2 2 0 01-2 2H5a2 2 0 01-2-2V5zm5 1a1 1 0 100 2h4a1 1 0 100-2H8z"
                      clipRule="evenodd"
                    />
                  </svg>
                  Generate Picking List
                </button>
                <button
                  className="bg-red-500 text-white px-4 py-2 shadow-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-300 transition flex items-center justify-center"
                  onClick={() => setShowResetDialog(true)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 mr-2"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                  Reset Box Contents
                </button>
              </div>
            </div>
          </div>
        </div>
      </header>

      <main className="bg-white p-4 rounded-b-lg shadow-md">
        {showProgressBar && (
          <div className="relative pt-1">
            <div className="flex mb-2 items-center justify-between">
              <div>
                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blue-600 bg-blue-200">
                  Box Contents Submitting...
                </span>
              </div>
              <div className="text-right">
                <span className="text-xs font-semibold inline-block text-blue-600">
                  {progress}%
                </span>
              </div>
            </div>
            <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-blue-200">
              <div
                style={{ width: `${progress}%` }}
                className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-500 progress-bar"
              ></div>
            </div>
          </div>
        )}

        <div className="grid grid-cols-2 gap-4">
          <div>
            <div className="bg-white equal-shadow rounded-lg p-4 mb-4">
              <div className="flex space-x-4">
                <input
                  type="text"
                  placeholder="Search Outbound Items"
                  value={itemSearchTerm}
                  onChange={(e) => setItemSearchTerm(e.target.value)}
                  className="flex-1 p-2 border border-gray-300 rounded"
                />
                <select
                  value={statusFilter}
                  onChange={(e) => setStatusFilter(e.target.value)}
                  className="p-2 border border-gray-300 rounded"
                >
                  <option value="All">All</option>
                  <option value="Not Started">
                    Not Started ({statusCounts.NotStarted}/{totalSKUs})
                  </option>
                  <option value="In Progress">
                    In Progress ({statusCounts.InProgress}/{totalSKUs})
                  </option>
                  <option value="Completed">
                    Completed ({statusCounts.Completed}/{totalSKUs})
                  </option>
                  <option value="Over Limit">
                    Over Limit ({statusCounts.OverLimit}/{totalSKUs})
                  </option>
                </select>
                <FiltersDropdown
                  prepFilter={prepFilter}
                  setPrepFilter={setPrepFilter}
                  shelvedFilter={shelvedFilter}
                  setShelvedFilter={setShelvedFilter}
                  showItemsForAllPlacements={showItemsForAllPlacements}
                  setShowItemsForAllPlacements={setShowItemsForAllPlacements}
                />
              </div>
              <div
                className="pagination-controls"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: '20px',
                }}
              >
                <IconButton
                  onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                  disabled={currentPage === 1}
                  color="primary"
                  aria-label="previous page"
                >
                  <ArrowBack />
                </IconButton>
                <Typography variant="body1" style={{ margin: '0 20px' }}>
                  Page {currentPage} of {totalPages}
                </Typography>
                <IconButton
                  onClick={() =>
                    setCurrentPage(prev => (indexOfLastItem < filteredShipmentItems.length ? prev + 1 : prev))
                  }
                  disabled={currentPage >= totalPages}
                  color="primary"
                  aria-label="next page"
                >
                  <ArrowForward />
                </IconButton>
              </div>
            </div>

            <div className="bg-white rounded-lg p-4" style={{ height: '60vh', overflowY: 'auto' }}>
              {currentItems.map((item, index) => (
                <OutboundItem
                  key={index}
                  item={item}
                  selectedNickname={selectedNickname}
                  quantityInBoxes={calculateTotalQuantityInBoxes(item.item.merchant_sku)}
                  asinData={asinData}
                  shelvingData={shelvingData.filter(data => data.SKU === item.item.merchant_sku)}
                  updateShelvingQty={updateShelvingQty}
                  removeShelving={removeShelving}
                  servicesData={servicesData}
                  clientsData={clientsData}
                  country={clientInfo.country}
                />
              ))}
            </div>
          </div>

          <div>
            <div className="bg-white equal-shadow rounded-lg p-4 mb-4">
              <div className="flex justify-between mb-4">
                <button
                  className={`py-2 px-4 rounded ${
                    !selectedPlan ? 'bg-gray-300 cursor-not-allowed' : 'bg-blue-500 text-white'
                  }`}
                  onClick={handleAddBox}
                  disabled={!selectedPlan}
                >
                  Add Box
                </button>
                <button
                  className="bg-gray-300 py-2 px-4 rounded"
                  onClick={() => setBoxSortOrder(boxSortOrder === 'asc' ? 'desc' : 'asc')}
                >
                  {boxSortOrder === 'asc' ? 'Sort Z-A' : 'Sort A-Z'}
                </button>
                <label className="flex items-center ml-4">
                  <input
                    type="checkbox"
                    checked={filterByPackingGroup}
                    onChange={(e) => setFilterByPackingGroup(e.target.checked)}
                    className="mr-2"
                  />
                  Filter by Packing Group
                </label>
              </div>
              <input
                type="text"
                placeholder="Search Boxes"
                value={boxSearchTerm}
                onChange={(e) => setBoxSearchTerm(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>

            <div className="bg-white shadow-md rounded-lg p-4" style={{ height: '60vh', overflowY: 'auto' }}>
              {filteredDbData.length === 0 && (
                <p>
                  {!selectedPlan
                    ? 'No Box Contents Available Without Plan'
                    : 'No boxes match your search criteria.'}
                </p>
              )}
              {sortedDbData.map((box, index) => (
                <Box
                  key={box.boxNumber}
                  boxNumber={box.boxNumber}
                  isOpen={box.boxNumber === openBoxNumber}
                  onClick={() => handleBoxClick(box)}
                  country={clientInfo.country}
                  shipmentItems={shipmentItems}
                  asinData={asinData}
                  selectedNickname={selectedNickname}
                  selectedBox={selectedBox}
                  onUpdateBoxDetails={handleUpdateBoxDetails}
                  onDuplicateBox={handleDuplicateBox}
                  shipmentId={shipmentId}
                  fetchStandardBoxes={fetchStandardBoxes}
                  standardBoxes={standardBoxes}
                  clientCountry={clientInfo.country}
                />
              ))}
            </div>
          </div>
        </div>
      </main>

      <Modal open={showShelfReminder} onClose={() => setShowShelfReminder(false)}>
        <div className="flex items-center justify-center min-h-screen px-4">
          <div className="bg-white rounded-lg shadow-lg max-w-lg w-full p-6">
            <h2 className="text-2xl font-bold mb-4 text-center">Confirm Shelf Removal</h2>
            <p className="text-gray-700 mb-6 text-center">
              Please confirm that items have already been removed from the shelves before submitting the box
              contents.
            </p>
            <div className="flex justify-end space-x-3">
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => setShowShelfReminder(false)}
                style={{ fontSize: '0.875rem', padding: '8px 16px' }}
              >
                Go Back
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setShowShelfReminder(false);
                  handleSubmitBoxContents(true, true);
                }}
                style={{ fontSize: '0.875rem', padding: '8px 16px' }}
              >
                Confirm
              </Button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby="oversized-items-modal-title"
        aria-describedby="oversized-items-modal-description"
      >
        <div className="flex items-center justify-center min-h-screen">
          <div className="bg-white rounded-lg shadow-lg w-full max-w-xl mx-4 p-8">
            <h2 id="oversized-items-modal-title" className="text-3xl font-bold mb-6 text-center">
              Oversized Items Without Fees
            </h2>
            <p
              id="oversized-items-modal-description"
              className="text-gray-700 mb-6 text-center text-lg"
            >
              The following items look like they should be oversized but do not have fees applied. Are
              you sure they are not oversized?
            </p>
            <ul className="list-disc pl-6 mb-6 text-left text-gray-700 text-lg">
              {oversizedItemsWithoutFees.map((item, index) => (
                <li key={index} className="mb-3">
                  <strong>{item.item.title}</strong> ({item.item.merchant_sku})
                </li>
              ))}
            </ul>
            <div className="flex justify-between mt-6">
              <Button
                variant="contained"
                color="secondary"
                style={{ fontSize: '1rem', padding: '12px 24px' }}
                onClick={() => setIsModalOpen(false)}
              >
                Go Back and add fees
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setIsModalOpen(false);
                  handleSubmitBoxContents(false, true);
                }}
              >
                They are not oversized
              </Button>
            </div>
          </div>
        </div>
      </Modal>

      <SplitShipmentModal
        open={showSplitShipmentModal}
        onClose={() => setShowSplitShipmentModal(false)}
        shipmentItems={shipmentItems}
        onSelectItems={(items) => setSelectedItems(items)}
        onSplit={handleSplitShipment}
      />
      <Dialog
        open={showSuccessModal}
        onClose={() => setShowSuccessModal(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Shipment Split Successful</DialogTitle>
        <DialogContent>
          <p>The shipment has been successfully split!</p>
          <p>Would you like to:</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowSuccessModal(false)} color="primary">
            Close
          </Button>
          <Button
            onClick={() => {
              window.open(
                `https://app.yyzprep.ca/staff/shipments/outbound/${newOutboundId}`,
                '_blank'
              );
              window.open(`/outbound/${newOutboundId}`, '_blank');
              setShowSuccessModal(false);
            }}
            color="primary"
          >
            Open New Shipment Page
          </Button>
        </DialogActions>
      </Dialog>

      {showSendEmailModal && (
        <SendEmail
          show={showSendEmailModal}
          recipientEmail={clientInfo.email}
          onClose={() => setShowSendEmailModal(false)}
        />
      )}
      {showImportModal && (
        <ImportModal
          data={importedData}
          onClose={() => setShowImportModal(false)}
          onConfirm={handleConfirmImport}
        />
      )}
      {showPickingListModal && (
        <PickingListModal
          show={showPickingListModal}
          onClose={() => setShowPickingListModal(false)}
          data={pickingListData}
        />
      )}
      {showReassignPlanModal && (
        <ReassignPlanModal
          show={showReassignPlanModal}
          shipmentId={shipmentId}
          onClose={() => setShowReassignPlanModal(false)}
          mainOutboundId={mainOutboundId}
          handleReassignPlan={handleReassignPlan}
        />
      )}
      <Dialog
        open={showResetDialog}
        onClose={() => setShowResetDialog(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Reset Box Contents</DialogTitle>
        <DialogContent>
          <p>
            Are you sure you want to reset all box contents for <strong>FBA ID: {selectedPlan?.id}</strong>?
          </p>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowResetDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleResetBoxContents} color="secondary" disabled={isResetting}>
            {isResetting ? 'Resetting...' : 'Reset'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Shipment;
