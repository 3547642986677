import React, { useContext, useState, useEffect } from 'react';
import { Box, Button, Checkbox, FormControlLabel, Grid, Radio, RadioGroup, Typography, Paper, Container, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { USAContext } from './USAContext';
import { useNavigate, useParams } from 'react-router-dom';
import { gapi } from 'gapi-script';

const API_URL = process.env.REACT_APP_API_URL;

const CLIENT_ID = '513169446517-8t6mm1hc3q2dlfiit3k9n04k4op6s3ih.apps.googleusercontent.com';
const API_KEY = 'AIzaSyDj6U0S0fY66gxnqv6P_VR3LiEcpgvzDzk';
const SPREADSHEET_ID = '1ok2BQYB6FbXXVBVs4uBWRZhMPoMN47k9-Hkj57YYSyE';
const SHEET_NAME = 'Requests'; // Adjust this to your sheet name
const CLIENT_INFO_SHEET_NAME = 'Client Information';
const SCOPES = 'https://www.googleapis.com/auth/spreadsheets';

const FoodFDACheck = () => {
    const { boxes, shipmentItems, shipmentInfo, clientsData, handleNext, selectedPlan } = useContext(USAContext);
    const [hasFood, setHasFood] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [uniqueAsins, setUniqueAsins] = useState(new Set());
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');
    const [filteredItems, setFilteredItems] = useState([]); // State to hold filtered items
    const [confirmedPlacementOption, setConfirmedPlacementOption] = useState(null);

    const navigate = useNavigate();
    const { shipmentId } = useParams();

    useEffect(() => {
        const initClient = () => {
            gapi.client.init({
                apiKey: API_KEY,
                clientId: CLIENT_ID,
                scope: SCOPES,
                discoveryDocs: ['https://sheets.googleapis.com/$discovery/rest?version=v4'],
            }).then(() => {
                gapi.auth2.getAuthInstance().isSignedIn.listen(updateSigninStatus);
                updateSigninStatus(gapi.auth2.getAuthInstance().isSignedIn.get());
            });
        };

        gapi.load('client:auth2', initClient);
    }, []);

    useEffect(() => {
        if (selectedPlan && selectedPlan.items) {
            // Filter shipmentItems to only include those in selectedPlan.items
            const filtered = shipmentItems.filter(item =>
                selectedPlan.items.some(planItem => planItem.msku === item.item.merchant_sku)
            );
            setFilteredItems(filtered);
        }
    }, [selectedPlan, shipmentItems]);

    useEffect(() => {
        if (selectedPlan) {
            checkPlacementOptions(selectedPlan);
        }
    }, [selectedPlan]);

    const updateSigninStatus = (isSignedIn) => {
        if (isSignedIn) {
            setIsAuthorized(true);
            readSheet();
        } else {
            setIsAuthorized(false);
        }
    };

    const handleAuthClick = () => {
        gapi.auth2.getAuthInstance().signIn();
    };

    const handleSignoutClick = () => {
        gapi.auth2.getAuthInstance().signOut();
    };

    const handleFoodChange = (event) => {
        const value = event.target.value === 'yes';
        setHasFood(value);
        if (!value) {
            setSelectedItems([]); // Clear selected items if "No" is selected
        } else if (value && !isAuthorized) {
            handleAuthClick(); // Prompt authorization if not already authorized
        }
    };

    const handleItemChange = (event) => {
        const itemId = event.target.value;
        console.log('Selected Item:', itemId);
        if (event.target.checked) {
            setSelectedItems([...selectedItems, itemId]);
        } else {
            setSelectedItems(selectedItems.filter((id) => id !== itemId));
        }
        setSubmitDisabled(event.target.checked ? false : selectedItems.length <= 1);
    };

    const readSheet = async () => {
        const range = SHEET_NAME; // Adjust to your sheet name
        const response = await gapi.client.sheets.spreadsheets.values.get({
            spreadsheetId: SPREADSHEET_ID,
            range,
        });

        const data = response.result;
        if (data.values && data.values.length > 0) {
            const headers = data.values[0]; // First row is headers
            const rows = data.values.slice(1); // Remaining rows are data

            const parsedData = rows.map(row => {
                const rowData = {};
                headers.forEach((header, index) => {
                    rowData[header] = row[index] || ''; // Default to empty string if value is undefined
                });
                return rowData;
            });

            // Create a unique set of ASINs
            const asins = new Set(parsedData.map(record => record['ASIN OR SKU']));
            setUniqueAsins(asins);
            console.log('Unique ASINs:', asins);
        } else {
            console.log('No data found.');
        }
    };

    const checkIfItemExists = (asin) => {
        return uniqueAsins.has(asin);
    };

    const getClientId = async (clientName) => {
        const response = await gapi.client.sheets.spreadsheets.values.get({
            spreadsheetId: SPREADSHEET_ID,
            range: CLIENT_INFO_SHEET_NAME,
        });

        const data = response.result;
        if (data.values && data.values.length > 0) {
            const headers = data.values[0]; // First row is headers
            const rows = data.values.slice(1); // Remaining rows are data

            const parsedData = rows.map(row => {
                const rowData = {};
                headers.forEach((header, index) => {
                    rowData[header] = row[index] || ''; // Default to empty string if value is undefined
                });
                return rowData;
            });

            const clientInfo = parsedData.find(client => client['Company Name'] === clientName);
            return clientInfo ? clientInfo['Client ID'] : null;
        } else {
            console.log('No client data found.');
            return null;
        }
    };

    const checkPlacementOptions = (plan) => {
        if (!plan || !plan.placement_options || plan.placement_options.length === 0) {
            console.log('No placement options available.');
            return;
        }

        const confirmedOptions = plan.placement_options.filter(option => option.confirmed_at);
        if (confirmedOptions.length === 0) {
            console.log('No placement option has been confirmed.');
            return;
        }

        const latestConfirmedOption = confirmedOptions.reduce((latest, current) => {
            return new Date(current.confirmed_at) > new Date(latest.confirmed_at) ? current : latest;
        });

        setConfirmedPlacementOption(latestConfirmedOption);
    };

    const findShipmentForBox = (box) => {
        if (!confirmedPlacementOption || !confirmedPlacementOption.shipments) {
            console.log('No confirmed placement option or shipments found.');
            return null;
        }
    
        const boxNumberInt = parseInt(box.boxNumber, 10);
        if (isNaN(boxNumberInt)) {
            console.error(`Invalid boxNumber: ${box.boxNumber}`);
            return null;
        }
    
        for (const shipment of confirmedPlacementOption.shipments) {
            console.log(`Checking shipment ID: ${shipment.id}`);
    
            for (const boxGroup of shipment.box_groups) {
                console.log(`Checking box group:`, boxGroup);
    
                if (Array.isArray(boxGroup.box_numbers)) {
                    if (boxGroup.box_numbers.includes(boxNumberInt)) {
                        console.log(`Box number ${boxNumberInt} found in shipment ID: ${shipment.id}`);
                        return shipment;
                    } else {
                        console.log(`Box number ${boxNumberInt} not found in this box group.`);
                    }
                } else {
                    console.warn(`box_numbers is not an array in shipment ID: ${shipment.id}, box_group:`, boxGroup);
                }
            }
        }
    
        console.log(`No matching shipment found for box number: ${boxNumberInt}`);
        return null;
    };
    

    const handleSubmit = async () => {
        if (hasFood && selectedItems.length > 0) {
            try {
                const clientData = clientsData.find(client => client.id === shipmentInfo.team_id);
                if (!clientData) {
                    console.error('Client data not found.');
                    return;
                }
                const clientId = await getClientId(clientData.name);
                if (!clientId) {
                    console.error('Client ID not found.');
                    alert('Client ID not found, might need to be added to Google Sheets first.');
                    return;
                }

                const rows = [];
                const now = new Date();
                const dateRequested = now.toISOString(); // Full date and time
                const dateRequestedTime = ''; // Empty since it's already included in dateRequested

                selectedItems.forEach(itemId => {
                    const item = shipmentItems.find(item => item.id == itemId);
                    console.log("Found Item:", item);
                    const isExisting = checkIfItemExists(item.item.asin);
                    boxes.forEach(box => {
                        console.log("Box we're finding shipment for:")
                        console.log(box)
                        const shipment = findShipmentForBox(box);
                        console.log(`Found shipment for box ${box}`)
                        console.log(shipment)
                        if (shipment && box.items.some(boxItem => boxItem.SKU == item.item.merchant_sku)) {
                            rows.push([
                                selectedPlan.inbound_plan_id.substring(selectedPlan.inbound_plan_id.length - 4),
                                dateRequested,
                                '', // Date Completed
                                dateRequestedTime,
                                'Pending',
                                clientId,
                                box.boxNumber,
                                '',
                                'Amazon FBA',
                                shipment.destination.address.address_line_1,
                                shipment.destination.address.address_line_2 || '',
                                shipment.destination.address.address_line_3 || '',
                                shipment.destination.address.city,
                                shipment.destination.address.state_or_province_code,
                                shipment.destination.address.postal_code,
                                box.items.find(boxItem => boxItem.ASIN == item.item.asin).QTY, // Amount
                                item.item.asin,
                                '',
                                2,
                                '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''
                            ]);
                        } else {
                            console.log("Asin no match")
                            console.log("Box items:")
                            console.log(box.items)
                            console.log("ITEM:")
                            console.log(item)
                        }
                    });
                });

                console.log('Rows to Append:', rows);

                const response = await gapi.client.sheets.spreadsheets.values.append({
                    spreadsheetId: SPREADSHEET_ID,
                    range: SHEET_NAME,
                    valueInputOption: 'RAW',
                    insertDataOption: 'INSERT_ROWS',
                    resource: {
                        values: rows,
                    },
                });

                if (!response.status === 200) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                console.log('Google Sheets Append Response:', response);

                setDialogMessage(`${rows.length} rows added to the sheet. You can view the sheet `);
                setDialogOpen(true);
            } catch (error) {
                console.error('Error inserting data into Google Sheets:', error);
            }
        } else {
            handleNext();
        }
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
        handleNext();
    };

    return (
        <Container>
            <Paper elevation={3} sx={{ padding: '24px', marginTop: '24px' }}>
                <Typography variant="h5" gutterBottom>Are there any food/FDA products?</Typography>
                <RadioGroup row value={hasFood ? 'yes' : 'no'} onChange={handleFoodChange}>
                    <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                    <FormControlLabel value="no" control={<Radio />} label="No" />
                </RadioGroup>

                {isAuthorized && hasFood && (
                    <Box sx={{ marginTop: '24px' }}>
                        <Typography variant="h6" gutterBottom>Select Food Products</Typography>
                        <Grid container spacing={2}>
                            {filteredItems.map((item, index) => (
                                <Grid item xs={12} key={index}>
                                    <FormControlLabel
                                        control={<Checkbox value={item.id} onChange={handleItemChange} />}
                                        label={
                                            <Grid container alignItems="center" spacing={2}>
                                                <Grid item>
                                                    <img src={(item.item.images && item.item.images.length > 0 && item.item.images[0].large_url) || 'https://qph.cf2.quoracdn.net/main-qimg-654617264f9192ec976abe6e53356240-lq'}
                                                        alt={item.item.title} width="50" />
                                                </Grid>
                                                <Grid item xs>
                                                    <Typography variant="body1">
                                                        {item.item.title}
                                                    </Typography>
                                                    <Typography variant="body2" color="textSecondary">
                                                        {item.item.merchant_sku && `Merchant SKU: ${item.item.merchant_sku}`}
                                                        {item.item.merchant_sku && item.item.asin && <br />}
                                                        {item.item.asin && `ASIN: ${item.item.asin}`}
                                                        {item.item.asin && item.item.fnsku && <br />}
                                                        {item.item.fnsku && `FNSKU: ${item.item.fnsku}`}
                                                    </Typography>
                                                    {checkIfItemExists(item.item.asin) ? ' (Has had PN before)' : ''}
                                                </Grid>
                                            </Grid>
                                        }
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                )}

                {hasFood && (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        disabled={submitDisabled || !isAuthorized}
                        sx={{ marginTop: '24px' }}
                    >
                        Submit to Sheet
                    </Button>
                )}

                {!isAuthorized && hasFood && (
                    <Button onClick={handleAuthClick} variant="contained" color="primary" sx={{ marginTop: '24px' }}>
                        Authorize Google Sheets
                    </Button>
                )}
            </Paper>

            <Dialog open={dialogOpen} onClose={handleDialogClose}>
                <DialogTitle>Success</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {dialogMessage}
                        <a href={`https://docs.google.com/spreadsheets/d/${SPREADSHEET_ID}?range=A7308`} target="_blank" rel="noopener noreferrer">here</a>.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary" autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default FoodFDACheck;