import React, { useContext } from 'react';
import Select from 'react-select';
import { BoxContext } from './BoxContext';

const CustomDropdown = ({ shipmentItems, onChange, value }) => {
  const { selectedPlan, boxes, selectedPackingGroup } = useContext(BoxContext);

  // Filter shipment items based on the selected plan
// Filter shipment items based on the selected plan or packing group
const filteredShipmentItems = shipmentItems
  .filter(item => {
    // Use selectedPackingGroup if it exists, otherwise use selectedPlan.items
    const itemsToCheck = selectedPackingGroup ? selectedPackingGroup.items : selectedPlan.items;
    return itemsToCheck.some(planItem => planItem.msku === item.item.merchant_sku);
  })
  .map(item => {
    // Find the corresponding plan item
    const planItem = (selectedPackingGroup ? selectedPackingGroup.items : selectedPlan.items).find(
      planItem => planItem.msku === item.item.merchant_sku
    );
    return { ...item, quantity: planItem.quantity };
  });

  // Calculate boxed quantity for each SKU
  const calculateBoxedQuantity = (merchantSku) => {
    let boxedQuantity = 0;
    boxes.forEach(box => {
      box.items.forEach(boxItem => {
        if (boxItem.SKU === merchantSku) {
          boxedQuantity += boxItem.QTY;
        }
      });
    });
    return boxedQuantity;
  };

  const customStyles = {
    option: (provided) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
      padding: 10,
    }),
    singleValue: (provided) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
    }),
    menu: (provided) => ({
      ...provided,
      minWidth: 400,
    }),
    menuList: (provided) => ({
      ...provided,
      minWidth: 400,
    }),
  };

  const formatOptionLabel = ({ item }) => {
    const boxedQuantity = calculateBoxedQuantity(item.merchant_sku);
    const totalQuantity = filteredShipmentItems.find(filteredItem => filteredItem.item.merchant_sku === item.merchant_sku)?.quantity || 0;
  
    // Determine the background and text color based on the conditions
    let backgroundColor = '';
    let textColor = '';
  
    if (boxedQuantity === totalQuantity) {
      backgroundColor = 'green';
      textColor = 'white'; // High contrast for green background
    } else if (boxedQuantity > totalQuantity) {
      backgroundColor = 'red';
      textColor = 'white'; // High contrast for red background
    } else if (boxedQuantity > 0 && boxedQuantity < totalQuantity) {
      backgroundColor = 'orange';
      textColor = 'black'; // High contrast for orange background
    } else {
      backgroundColor = 'yellow';
      textColor = 'black'; // High contrast for yellow background
    }
  
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div
          style={{
            backgroundColor,
            color: textColor,
            padding: '4px 8px',
            borderRadius: '12px',
            fontWeight: 'bold',
            marginRight: 10,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: '50px',
          }}
        >
          {boxedQuantity}/{totalQuantity}
        </div>
        {item.images && item.images.length > 0 ? (
          <img src={item.images[0].large_url} alt={item.title} style={{ width: 50, height: 50, marginRight: 10 }} />
        ) : null}
        <div>
          <div style={{ fontSize: 14 }}>{item.title}</div>
          <div style={{ fontSize: 12, color: '#888' }}>{item.asin} / {item.fnsku} / {item.merchant_sku}</div>
        </div>
      </div>
    );
  };
  const options = filteredShipmentItems.map((item) => ({
    value: item.item.asin,
    label: item.item.title,
    item: item.item,
  }));

  const filterOption = ({ label, value, data }, inputValue) => {
    const searchTerm = inputValue.toLowerCase();
    return (
      label.toLowerCase().includes(searchTerm) ||
      data.item.fnsku.toLowerCase().includes(searchTerm) ||
      data.item.merchant_sku.toLowerCase().includes(searchTerm)
    );
  };

  return (
    <Select
      options={options}
      styles={customStyles}
      formatOptionLabel={formatOptionLabel}
      onChange={(selectedOption) => onChange(selectedOption.item)}
      value={options.find(option => option.value === value) || null}
      placeholder="Select an option"
      filterOption={filterOption}
    />
  );
};

export default CustomDropdown;