import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, MenuItem, Select, IconButton, Dialog, DialogActions, DialogContent, DialogTitle, InputAdornment } from '@mui/material';
import Sidebar from './InboundSidebar';
import InboundContext from './InboundContext';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SearchIcon from '@mui/icons-material/Search';
import moment from 'moment';
import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import SendEmailInbound from './SendEmailInbound';
import NicknameModal from './NicknameModal';
import { Tooltip } from '@mui/material';

const YYZ_API_URL = 'https://app.yyzprep.ca/api/shipments/inbound';
const API_URL = process.env.REACT_APP_API_URL;


function InboundDetail() {
  const { id } = useParams(); 
  const [inboundInfo, setInboundInfo] = useState(null);
  const [inboundItems, setInboundItems] = useState([]);
  const { setInboundData, selectedNickname } = useContext(InboundContext);
  const [orderType, setOrderType] = useState(''); 
  const [inboundStatus, setInboundStatus] = useState('');
  const [shelfCode, setShelfCode] = useState('');
  const [shelfQuantities, setShelfQuantities] = useState({});
  const [shelfData, setShelfData] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedShelf, setSelectedShelf] = useState({});
  const [newQuantity, setNewQuantity] = useState(0);
  const [inputQuantities, setInputQuantities] = useState({});
  const [shelfCodes, setShelfCodes] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // success, error, info, warning
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [notes, setNotes] = useState(inboundInfo?.notes || '');
  const [internalNotes, setInternalNotes] = useState(inboundInfo?.internal_notes || '');
  const [searchQuery, setSearchQuery] = useState(''); // Search query state
  const [allShelfCode, setAllShelfCode] = useState('');
  const [openShelfDialog, setOpenShelfDialog] = useState(false);
  const [client, setClient] = useState(null);
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const [nicknames, setNicknames] = useState([]);
  const [addToActualHistory, setAddToActualHistory] = useState({});
  const [actualChangeHistory, setActualChangeHistory] = useState({});
  const [shelfAdjustmentLog, setShelfAdjustmentLog] = useState({}); // For shelf adjustments
  const [addToShelfLog, setAddToShelfLog] = useState({}); // For adding items to shelves

  const [bannerMessage, setBannerMessage] = useState('');
const [bannerType, setBannerType] = useState('info'); // 'info', 'success', 'error'

useEffect(() => {
  const handleVisibilityChange = () => {
    if (document.visibilityState === 'visible') {
      updateTextFileOnServer();  // Call your function to generate the text file when the tab is visible
    }
  };

  document.addEventListener('visibilitychange', handleVisibilityChange);

  // Cleanup the event listener on component unmount
  return () => {
    document.removeEventListener('visibilitychange', handleVisibilityChange);
  };
}, []);

//FOR MOBILE APPS
const updateTextFileOnServer = async () => {
  if (!inboundInfo || !client || inboundItems.length === 0) return;

  const fileContent = {
    inboundInfo,
    client,
    items: inboundItems.map(item => ({
      title: item.item.title,
      merchant_sku: item.item.merchant_sku,
      asin: item.item.asin,
      fnsku: item.item.fnsku,
      expected_quantity: item.expected.quantity,
      actual_quantity: item.actual.quantity,
    })),
  };

  try {
    const response = await fetch(`${API_URL}/update-inbound-file`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(fileContent),
    });

    if (response.ok) {
      console.log('File updated successfully on the server');
    } else {
      console.error('Failed to update the file');
    }
  } catch (error) {
    console.error('Error updating the file:', error);
  }
};

const showBanner = (message, type = 'info') => {
  setBannerMessage(message);
  setBannerType(type);
};

const hideBanner = () => {
  setBannerMessage('');
};


useEffect(() => {
  if (inboundInfo && client && inboundItems.length > 0) {
    updateTextFileOnServer(); // Update the file with new content
  }
}, [inboundInfo, client, inboundItems]); // Ensure it runs when data is available


  const logInboundAction = async ({ inboundId, type, sku = null, qty = null }) => {
    const employee = selectedNickname;
    try {
      const response = await fetch(`${API_URL}/api/log_inbound_action`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          employee,
          inboundId,
          type,
          sku,
          qty,
        }),
      });
  
      if (!response.ok) {
        throw new Error(`Failed to log inbound action: ${response.statusText}`);
      }
  
      const data = await response.json();
      console.log('Log response:', data);
      return data; // Handle the response as needed
    } catch (error) {
      console.error('Error logging inbound action:', error);
    }
  };
  



  const handleOpenEmailModal = () => {
    setIsEmailModalOpen(true);
    console.log("Open");
  };
  
  const handleCloseEmailModal = () => {
    setIsEmailModalOpen(false);
  };
  

  const fetchClientInfo = async (teamId) => {
    try {
      const response = await fetch(`${API_URL}/api/get_clients`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      const clients = await response.json();
  
      // Find the client with the matching team_id
      const matchedClient = clients.find(client => client.id === teamId);
  
      if (matchedClient) {
        setClient(matchedClient);
        console.log(matchedClient);
      } else {
        console.error('Client not found');
      }
    } catch (error) {
      console.error('Error fetching clients:', error);
    }
  };

  useEffect(() => {
    if (inboundInfo && inboundInfo.team_id) {
      fetchClientInfo(inboundInfo.team_id);
    }
  }, [inboundInfo]);

  useEffect(() => {
    const interval = setInterval(() => {
      // Force a re-render every second
      setAddToActualHistory((prevHistory) => ({ ...prevHistory }));
    }, 1000);

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);


  const handleOpenShelfDialog = () => {
    setOpenShelfDialog(true);
  };
  
  const handleCloseShelfDialog = () => {
    setOpenShelfDialog(false);
  };
  
  const showSnackbar = (message, severity = 'info') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };
  const handleAddAllToShelf = async () => {
    try {
      showSnackbar('Saving...', 'info');
  
      for (let index = 0; index < inboundItems.length; index++) {
        console.log("Index ", index);
        const shelfCode = allShelfCode; // Use the shelf code provided in the dialog
        const inputQuantity = shelfQuantities[index] || 0; // Get the quantity for this row
        console.log(inputQuantity);
        const item = inboundItems[index];
  
        // Skip if the quantity is 0 or shelf code is empty
        if (inputQuantity <= 0 || !shelfCode) continue;
        console.log({
            key: `${shelfCode}-${item.item.merchant_sku}`, // Assuming KEY is the SKU
            qty: inputQuantity,
            sku: item.item.merchant_sku,
            shelfCode: shelfCode,
            shipmentId: inboundInfo.id,
            action_type: "INBOUND_TOOL_ADDALLTOSHELF",
            employee: selectedNickname, // Replace with the actual employee name or ID
            team_id: inboundInfo.team_id
          });
        const response = await fetch(`${API_URL}/api/add_or_update_shelving_qty`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            key: `${shelfCode}-${item.item.merchant_sku}`, // Assuming KEY is the SKU
            qty: inputQuantity,
            sku: item.item.merchant_sku,
            shelfCode: shelfCode,
            shipmentId: inboundInfo.id,
            action_type: "INBOUND_TOOL_ADDALLTOSHELF",
            employee: selectedNickname, // Replace with the actual employee name or ID
            team_id: inboundInfo.team_id
          }),
        });
  
        const result = await response.json();
        
  
        if (!result.success) {
          console.error('Failed to update shelving quantity:', result.error);
          throw new Error('Failed to update shelving quantity');
        }
      }
  
      await fetchShelfData(inboundItems);
      showSnackbar('All items added to shelf successfully', 'success');
      
      handleCloseShelfDialog(); // Close the dialog after success
  
    } catch (error) {
      console.error('Error updating shelving quantity:', error);
      showSnackbar('Error updating shelving quantity', 'error');
    }
  };
  
  useEffect(() => {
  console.log(inputQuantities);
}, [inputQuantities]);

  useEffect(() => {
    checkAllItemsReceived();
  }, [inboundItems]); // This will check whenever `inboundItems` changes

  useEffect(() => {
    if (inboundInfo) {
      document.title = `${id} • ${inboundInfo.name}`;
    }
  }, []); // This will check whenever `inboundItems` changes

  useEffect(() => {
    fetchInboundData();
  }, []);

  const handleShelfCodeChange = (index, value) => {
    setShelfCodes({
      ...shelfCodes,
      [index]: value.toUpperCase(),
    });
  };

  useEffect(() => {
    if (orderType === 'OA') {
      applyOAFeeToItems();
    } else if (orderType === 'Amazon Removal Receiving') {
      addTagToInbound(inboundInfo.id);
    }
  }, [orderType]);

  const checkAllItemsReceived = () => {
    console.log('Checking if all items are received...');
    if (!inboundInfo || !inboundItems || inboundItems.length === 0) {
      return;
    }
  
    const allItemsReceived = inboundItems.every(item => item.expected.quantity === item.actual.quantity);
    //if (allItemsReceived){
    if (allItemsReceived && inboundInfo.status.toLowerCase() !== 'received') {
      // Instead of opening a dialog, show a banner with an action button
      showBanner(
        'All items expected are received. Would you like to mark this shipment as received?',
        'info'
      );
    }
  };
  

  // Example using async/await and fetch
async function addTagToInbound(inboundId) {
  const apiToken = 'Yh7l5CUTaZ1nIgAueWglafvm616hchHFFZxRjKjPHNBjB19b2jTDgGoCSpeq';
  const tagId = 54;
  const url = `https://app.yyzprep.ca/api/shipments/inbound/${inboundId}/tags/add-tag/${tagId}?api_token=${apiToken}`;

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
            },
    });

    const data = await response.json();

    if (response.ok && data.message === 'Tag added') {
      alert('Tagged Inbound As Removal Order');
    } else {
      // Handle known error messages from the API
      const errorMessage = data.message || 'An unexpected error occurred.';
      alert(`Error: ${errorMessage}`);
    }
  } catch (error) {
    // Handle network or unexpected errors
  }
}
  const applyOAFeeToItems = async () => {
    const OA_FEE_SERVICE_ID = 1005;
    try {
      for (const item of inboundItems) {
        const inventoryResponse = await fetch(`https://app.yyzprep.ca/api/inventory/${item.id}?api_token=Yh7l5CUTaZ1nIgAueWglafvm616hchHFFZxRjKjPHNBjB19b2jTDgGoCSpeq`);
        const inventoryData = await inventoryResponse.json();
        console.log(inventoryData.item);

        const existingServices = inventoryData.item.company_services.map(service => service.id);

        if (!existingServices.includes(OA_FEE_SERVICE_ID)) {
          existingServices.push(OA_FEE_SERVICE_ID);

          await fetch(`https://app.yyzprep.ca/api/inventory/services?api_token=Yh7l5CUTaZ1nIgAueWglafvm616hchHFFZxRjKjPHNBjB19b2jTDgGoCSpeq`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              item_id: item.id,
              services: existingServices.reduce((acc, serviceId, index) => {
                acc[index] = serviceId;
                return acc;
              }, {}),
            }),
          });

          showSnackbar(`OA Fee added to ASIN ${item.item.asin}`, 'success');
        }
      }
    } catch (error) {
      console.error('Error applying OA fee to items:', error);
      showSnackbar('Error applying OA fee to items', 'error');
    }
  };

  const fetchShelfData = async (inboundItems) => {
    try {
      // Extract SKUs from inbound items
      const skus = inboundItems.map(item => item.item.merchant_sku);

      // Fetch shelf data for the extracted SKUs
      const shelfResponse = await fetch(`${API_URL}/api/get_shelving_data`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ skus }),
      });

      const shelfDataResult = await shelfResponse.json();

      // Create a map for quick lookup of shelf data by SKU
      const shelfDataMap = shelfDataResult.reduce((acc, item) => {
        if (!acc[item.SKU]) {
          acc[item.SKU] = [];
        }
        acc[item.SKU].push({
          FULL_SHELF_CODE: item.FULL_SHELF_CODE,
          QTY: item.QTY,
          KEY: item.KEY,
        });
        return acc;
      }, {});

      // Update the state with the new shelf data
      setShelfData(shelfDataMap);

    } catch (error) {
      console.error('Error fetching shelf data:', error);
    }
  };

  const fetchInboundData = async () => {
    try {
      // Fetch inbound information
      const infoResponse = await fetch(`${YYZ_API_URL}/${id}?api_token=Yh7l5CUTaZ1nIgAueWglafvm616hchHFFZxRjKjPHNBjB19b2jTDgGoCSpeq`);
      const inboundInfoData = await infoResponse.json();
      setInboundInfo(inboundInfoData.shipment);
      setOrderType(inboundInfoData.shipment.order_type || '');
      setInboundStatus(inboundInfoData.shipment.status?.toLowerCase() || '');
      setNotes(inboundInfoData.shipment.notes || '');
      setInternalNotes(inboundInfoData.shipment.internal_notes || '');
      // Fetch inbound items
      const itemsResponse = await fetch(`${YYZ_API_URL}/${id}/items?api_token=Yh7l5CUTaZ1nIgAueWglafvm616hchHFFZxRjKjPHNBjB19b2jTDgGoCSpeq`);
      const inboundItemsData = await itemsResponse.json();
      setInboundItems(inboundItemsData.items);

      // Store in context if needed
      setInboundData({
        inboundInfo: inboundInfoData.shipment,
        inboundItems: inboundItemsData.items
      });

      // Fetch and update shelf data
      await fetchShelfData(inboundItemsData.items);

    } catch (error) {
      console.error('Error fetching inbound data:', error);
    }
  };

  const handleSetActualToExpected = async (index) => {
    const item = inboundItems[index];
    const newQuantity = item.expected.quantity;

    try {
      // Show saving indication
      const updatedItems = [...inboundItems];

      showSnackbar('Saving...', 'info'); // Show saving message
      // Optimistically update the UI
      updatedItems[index].actual.quantity = newQuantity;
      setInboundItems(updatedItems);

    // Update the 'Add to Actual' input field
    setShelfQuantities({
      ...shelfQuantities,
      [index]: newQuantity,
    });

    // Update the 'Add to Shelf' input field
    setInputQuantities({
      ...inputQuantities,
      [index]: newQuantity,
    });


      // Send POST request to update the actual quantity
      const response = await fetch(`${YYZ_API_URL}/${id}/update-item?api_token=Yh7l5CUTaZ1nIgAueWglafvm616hchHFFZxRjKjPHNBjB19b2jTDgGoCSpeq`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: item.id,
          item_id: item.item.id,
          shipment_id: id,
          expected: item.expected,
          actual: {
            ...item.actual,
            quantity: newQuantity,
          },
          unsellable: item.unsellable,
          item: item.item,
        }),
      });

      if (response.ok) {
        await logInboundAction({
          inboundId: inboundInfo.id,
          type: 'UPDATE_ACTUAL_QTY',
          sku: updatedItems[index].item.merchant_sku,
          qty: newQuantity,
        });
  
        // Update history for the "Actual" field
        setActualChangeHistory((prevHistory) => ({
          ...prevHistory,
          [index]: {
            qty: newQuantity,
            timestamp: new Date(),
          },
        }));
        showSnackbar('Saved', 'success'); // Show saved message
        // Refresh the data to reflect the changes
        await fetchInboundData();
      } else {
        throw new Error('Failed to save changes.');
      }
    } catch (error) {
      console.error('Error updating item:', error);
      showSnackbar('Error saving changes', 'error'); // Show error message
    }
  };

  const handleQuantityChange = (index, value) => {
    setInputQuantities({
      ...inputQuantities,
      [index]: value,
    });
  };

    // Trigger handleMarkShipmentAsReceived when inboundStatus changes to 'received'
    useEffect(() => {
      if (inboundStatus === 'received' && inboundInfo.status != 'received') {
        handleMarkShipmentAsReceived();
      }
    }, [inboundStatus]);

  const handleMarkShipmentAsReceived = async () => {
    try {
      // First, try to post to the /submit URL
      await fetch(`${YYZ_API_URL}/${id}/submit?api_token=Yh7l5CUTaZ1nIgAueWglafvm616hchHFFZxRjKjPHNBjB19b2jTDgGoCSpeq`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          selected_carrier: 'no_tracking',
          tracking_numbers: {},
        }),
      });
  
      // Then, post to the /receive URL as before
      const response = await fetch(`${YYZ_API_URL}/${id}/receive?api_token=Yh7l5CUTaZ1nIgAueWglafvm616hchHFFZxRjKjPHNBjB19b2jTDgGoCSpeq`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ status: 'received' }),
      });
  
      if (response.ok) {
        showSnackbar('Shipment marked as received', 'success');
        fetchInboundData();
      } else {
        throw new Error('Failed to update shipment status.');
      }
    } catch (error) {
      console.error('Error updating shipment status:', error);
      showSnackbar('Error updating shipment status', 'error');
    } finally {
      setOpenConfirmDialog(false);
    }
  };
  

  const handleActualChange = (index, value) => {
    const updatedItems = [...inboundItems];
    updatedItems[index].actual.quantity = value;
    setInboundItems(updatedItems);
  };

  const handleActualBlur = async (index) => {
    const updatedItems = [...inboundItems];

    showSnackbar('Saving...', 'info'); // Show saving message

    try {
      const item = updatedItems[index];
      const response = await fetch(`${YYZ_API_URL}/${id}/update-item?api_token=Yh7l5CUTaZ1nIgAueWglafvm616hchHFFZxRjKjPHNBjB19b2jTDgGoCSpeq`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(item),
      });

      if (response.ok) {
        await logInboundAction({
          inboundId: inboundInfo.id,
          type: 'UPDATE_ACTUAL_QTY',
          sku: item.item.merchant_sku,
          qty: item.actual.quantity,
        });
    
        // Update history for the "Actual" field
      setActualChangeHistory((prevHistory) => ({
        ...prevHistory,
        [index]: {
          qty: item.actual.quantity,
          timestamp: new Date(),
        },
      }));

        showSnackbar('Saved', 'success'); // Show saved message
        fetchInboundData();
        //checkAllItemsReceived();
      } else {
        throw new Error('Failed to save changes.');
      }
    } catch (error) {
      console.error('Error updating item:', error);
      showSnackbar('Error saving changes', 'error'); // Show error message
    }
  };

  const handleShelfQuantityChange = (index, value) => {
    console.log("Shelf quantity change");
    setShelfQuantities({
      ...shelfQuantities,
      [index]: value,
    });
    console.log(shelfQuantities);
  };

  const handleAddToActual = async (index, value) => {
    const updatedItems = [...inboundItems];
    const addedQuantity = parseInt(value, 10); // Ensure the value is a number
  
    showSnackbar('Saving...', 'info'); // Show saving message
  
    try {
      const item = updatedItems[index];
      const newActualQuantity = item.actual.quantity + addedQuantity; // Calculate the new total actual quantity
  
      // Optimistically update the UI
      updatedItems[index].actual.quantity = newActualQuantity;
      setInboundItems(updatedItems);
  
      // Send POST request to update the actual quantity
      const response = await fetch(`${YYZ_API_URL}/${id}/update-item?api_token=Yh7l5CUTaZ1nIgAueWglafvm616hchHFFZxRjKjPHNBjB19b2jTDgGoCSpeq`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...item,
          actual: { ...item.actual, quantity: newActualQuantity },
        }),
      });
  
      if (response.ok) {
        await logInboundAction({
          inboundId: inboundInfo.id,
          type: 'ADDED_TO_ACTUAL_QTY',
          sku: item.item.merchant_sku,
          qty: addedQuantity,
        });
  
        // Update history for the "Add to Actual" field
        setAddToActualHistory((prevHistory) => ({
          ...prevHistory,
          [index]: {
            qty: addedQuantity, // Record only the added quantity
            timestamp: new Date(),
          },
        }));
  
        // Set the "Add to Shelf" input to reflect only the added quantity, not the total
        setInputQuantities((prevQuantities) => ({
          ...prevQuantities,
          [index]: addedQuantity, // Only reflect the value entered in "Add to Actual"
        }));
  
        await fetchInboundData();
        showSnackbar('Saved', 'success'); // Show saved message
      } else {
        throw new Error('Failed to save changes.');
      }
    } catch (error) {
      showSnackbar('Error saving changes', 'error'); // Show error message
      console.error('Error updating item:', error);
    }
  };
  
  useEffect(() => {
    if (inboundInfo) {
      document.title = inboundInfo.name; // Set the document title to inbound name
    }
  }, [inboundInfo]);
  
  const handleMarkAllReceived = async () => {
    const updatedItems = inboundItems.map((item) => ({
      ...item,
      actual: { ...item.actual, quantity: item.expected.quantity },
    }));

    setInboundItems(updatedItems);

    try {
      showSnackbar('Saving...', 'info');

      for (const item of updatedItems) {
        const response = await fetch(`${YYZ_API_URL}/${id}/update-item?api_token=Yh7l5CUTaZ1nIgAueWglafvm616hchHFFZxRjKjPHNBjB19b2jTDgGoCSpeq`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(item),
        });

        if (!response.ok) {
          throw new Error(`Failed to update item ${item.id}`);
        }
      }

      showSnackbar('All items set actual to expected and saved successfully', 'success');
    } catch (error) {
      console.error('Error updating items:', error);
      showSnackbar('Error saving changes', 'error');
    }
  };

  const handleShelfClick = (shelf) => {
    setSelectedShelf(shelf);
    setNewQuantity(shelf.QTY);
    setOpenDialog(true);
  };

  const handleAddToShelf = async (index) => {
    const shelfCode = shelfCodes[index] || ''; // Get the shelf code for this row
    const inputQuantity = inputQuantities[index] || 0; // Get the quantity for this row
    const item = inboundItems[index];

    // Check if the shelf code and quantity are valid
    if (!shelfCode || inputQuantity <= 0) {
      console.error('Shelf code and a positive quantity must be provided.');
      return;
    }

    try {
      const response = await fetch(`${API_URL}/api/add_or_update_shelving_qty`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          key: `${shelfCode}-${item.item.merchant_sku}`, // Assuming KEY is the SKU
          qty: inputQuantity,
          sku: item.item.merchant_sku,
          shelfCode: shelfCode,
          employee: selectedNickname,
          shipmentId: inboundInfo.id,
          action_type: "INBOUND_TOOL_ADD_INDIVIDUAL", // Replace with the actual employee name or ID
          team_id: inboundInfo.team_id
        }),
      });

      const result = await response.json();

      if (result.success) {
        console.log(result.message);
              // Update history for the shelf adjustment
      // Add entry to the add-to-shelf log
      setAddToShelfLog((prevLog) => ({
        ...prevLog,
        [index]: {
          action: 'Added',
          qty: inputQuantity,
          shelfCode: shelfCode,
          timestamp: new Date(),
        },
      }));

        // Refresh existing shelves after update
        await fetchShelfData(inboundItems);
      } else {
        console.error('Failed to update shelving quantity:', result.error);
      }
    } catch (error) {
      console.error('Error updating shelving quantity:', error);
    }
  };

  const handleSaveNotes = async (type, value) => {
    try {
      const response = await fetch(`${YYZ_API_URL}/${id}?api_token=Yh7l5CUTaZ1nIgAueWglafvm616hchHFFZxRjKjPHNBjB19b2jTDgGoCSpeq`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          [type]: value,
        }),
      });

      if (response.ok) {
        showSnackbar('Notes saved successfully', 'success');
      } else {
        throw new Error('Failed to save notes');
      }
    } catch (error) {
      console.error('Error saving notes:', error);
      showSnackbar('Error saving notes', 'error');
    }
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleDialogSave = async () => {
    console.log(selectedShelf);
    try {
      const response = await fetch(`${API_URL}/api/update_shelving_qty`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          key: selectedShelf.KEY,
          shelfCode: selectedShelf.FULL_SHELF_CODE,
          qty: newQuantity,
          employee: selectedNickname,
          sku: selectedShelf.SKU,
          action_type: "INBOUND_TOOL_EDITSETSHELFQTY",
          shipmentId: inboundInfo.id, // Replace with the actual employee name or ID
        }),
      });

      const result = await response.json();

        // Calculate the difference between the old quantity and the new quantity
        const quantityChange = newQuantity - selectedShelf.QTY;
        const action = quantityChange > 0 ? 'Added' : 'Removed';
  
 // Add entry to the shelf adjustment log
 setShelfAdjustmentLog((prevLog) => {
  const updatedLog = {
    ...prevLog,
    [`${selectedShelf.SKU}-${selectedShelf.FULL_SHELF_CODE}`]: {
      action: action,
      qty: Math.abs(quantityChange),
      shelfCode: selectedShelf.FULL_SHELF_CODE,
      timestamp: new Date(),
    },
  };
  console.log('Updated Shelf Adjustment Log:', updatedLog); // Log the updated state
  return updatedLog;
});
        await fetchShelfData(inboundItems); // Refresh the shelf data after update

    } catch (error) {
      console.error('Error updating shelf quantity:', error);
    }

    setOpenDialog(false);
  };

  // Filtering items based on search query
  const filteredItems = inboundItems.filter((item) => {
    const query = searchQuery.toLowerCase();
    return (
      item.item.title.toLowerCase().includes(query) ||
      item.item.merchant_sku.toLowerCase().includes(query) ||
      item.item.asin.toLowerCase().includes(query) ||
      item.item.fnsku.toLowerCase().includes(query)
    );
  });

  if (!inboundInfo || inboundItems.length === 0) {
    return <div>Loading...</div>;
  }
  return (
<Box component="main" sx={{ flexGrow: 1, p: 0, m: 0 }}>
{(
        <>
          <Sidebar />
          <Box component="main" sx={{ flexGrow: 1, p: 0, ml: 0 }}>



            {isEmailModalOpen && client && selectedNickname && (
  <SendEmailInbound
    recipientEmail={client.email}
    onClose={handleCloseEmailModal}
  />
)}

            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <Paper elevation={3} sx={{ p: 3, borderRadius: '12px' }}>
                  <Typography variant="h6">{inboundInfo.name}</Typography>
{client && (
  <Typography variant="subtitle1">{client.name}</Typography>
)}
                  <Typography variant="body1">
                    Created At: {moment(inboundInfo.created_at).format('MMMM D, YYYY [at] h:mm A')}
                  </Typography>
                  {inboundInfo.received_at && (
                    <Typography variant="body1">
                      Received At: {moment(inboundInfo.received_at).format('MMMM D, YYYY [at] h:mm A')}
                    </Typography>
                  )}
                  <Typography variant="body1">
                    Units Received: {inboundInfo.received_quantity}/{inboundInfo.expected_quantity}
                  </Typography>
                  <Typography variant="body1">SKUs: {inboundInfo.sku_count}</Typography>
                  <Typography variant="body1">
                    Inbound ID: <a href={`https://app.yyzprep.ca/staff/shipments/inbound/${inboundInfo.id}`} target="_blank" rel="noopener noreferrer">{inboundInfo.id}</a>
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleOpenEmailModal} // Open the email modal
                    sx={{ mt: 2, borderRadius: '8px', boxShadow: 3 }}
                  >
                    Contact Client
                  </Button>
                </Paper>
              </Grid>
              <Grid item xs={12} md={4}>
                <Paper elevation={3} sx={{ p: 2 }}>
                  <Typography variant="h6">Order Type</Typography>
                  <Select
                    value={orderType}
                    onChange={(e) => setOrderType(e.target.value)}
                    fullWidth
                    variant="outlined"
                    sx={{ mt: 2, mb: 2, backgroundColor: '#FFFF00' }}
                  >
                    <MenuItem value="OA">OA (From Retailers)</MenuItem>
                    <MenuItem value="Not OA">Not OA (Wholesale, Own Brand, etc.)</MenuItem>
                    <MenuItem value="Amazon Removal Receiving">Amazon Removal Receiving</MenuItem>
                  </Select>
                  <Typography variant="h6">Inbound Status</Typography>
                  <Select
                    value={inboundStatus}
                    onChange={(e) => setInboundStatus(e.target.value)}
                    fullWidth
                    variant="outlined"
                    sx={{ mt: 2, mb: 2 }}
                  >
                    <MenuItem value="open">Open</MenuItem>
                    <MenuItem value="shipped">Shipped</MenuItem>
                    <MenuItem value="received">Received</MenuItem>
                  </Select>
                  <Button
                    variant="outlined"
                    color="primary"
                    fullWidth
                    sx={{ borderRadius: '8px', boxShadow: 1 }}
                    onClick={handleMarkAllReceived}
                  >
                    Mark All as Received in Full
                  </Button>
                </Paper>
              </Grid>
              <Grid item xs={12} md={4}>
                <Paper elevation={3} sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <Button 
                    variant="contained" 
                    color="secondary"
                    onClick={handleOpenShelfDialog} // Open dialog when button is clicked
                  >
                    ADD ALL TO 1 SHELF
                  </Button>
                </Paper>
              </Grid>
            </Grid>
  
            {/* Notes Section */}
            <Box sx={{ mt: 3 }}>
              <Paper elevation={3} sx={{ p: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      multiline
                      rows={3}
                      variant="outlined"
                      label="Notes"
                      value={notes}
                      onChange={(e) => setNotes(e.target.value)}
                      onBlur={() => handleSaveNotes('notes', notes)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      multiline
                      rows={3}
                      variant="outlined"
                      label="Internal Notes"
                      sx={{ backgroundColor: '#fff8e1' }}
                      value={internalNotes}
                      onChange={(e) => setInternalNotes(e.target.value)}
                      onBlur={() => handleSaveNotes('internal_notes', internalNotes)}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Box>
            {bannerMessage && (
  <Box
    sx={{
      backgroundColor:
        bannerType === 'success' ? '#d4edda' :
        bannerType === 'error' ? '#f8d7da' : 
        '#d1ecf1',
      color: bannerType === 'success' ? '#155724' :
             bannerType === 'error' ? '#721c24' : 
             '#0c5460',
      padding: '16px',
      marginBottom: '16px',
      borderRadius: '4px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    }}
  >
    <Typography>{bannerMessage}</Typography>
    {bannerMessage.includes('mark this shipment as received') && (
      <Button 
        variant="contained" 
        color="secondary" 
        size="large" 
        onClick={handleMarkShipmentAsReceived} // Trigger the correct function
        sx={{ ml: 2 }}
      >
        Mark Shipment as Received
      </Button>
    )}
    <Button onClick={hideBanner} variant="contained" size="small">Dismiss</Button>
  </Box>
)}

            {/* Search Bar */}
            <Box sx={{ mt: 3, mb: 2 }}>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Search by Title, SKU, ASIN, FNSKU"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
  
            {/* Items Table */}
            <Box sx={{ mt: 3 }}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Image</TableCell>
                      <TableCell>Title</TableCell>
                      <TableCell align="center">Expected</TableCell>
                      <TableCell align="center">Actual</TableCell>
                      <TableCell align="center">Add to Actual</TableCell>
                      <TableCell align="center">Add to Shelf</TableCell>
                      <TableCell align="center">Existing Shelves</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredItems.map((item, index) => (
                      <TableRow key={item.id}>
                        <TableCell>
                        {/* <Tooltip
    title={<img src={item.item.images[0]?.large_url} alt={item.item.title} style={{ width: '550px', height: '550px', objectFit: 'contain' }} />}
    enterDelay={1000} // 1 second delay
    placement="top"
  > */}
                          <img src={item.item.images[0]?.large_url} alt={item.item.title} style={{ width: '170px', height: '170px', objectFit: 'contain' }} />
                          {/* </Tooltip> */}
                        </TableCell>
                        <TableCell sx={{ maxWidth: '500px' }}>
  <Typography variant="body1" sx={{ maxWidth: '500px', whiteSpace: 'normal', wordBreak: 'break-word' }}>
    {item.item.title}
  </Typography>
  <Typography variant="body2">
  ASIN: 
  <a 
    href={`https://www.amazon.${client.name.includes('US') ? 'com' : 'ca'}/dp/${item.item.asin}`} 
    target="_blank" 
    rel="noopener noreferrer"
  >
    {item.item.asin}
  </a>
</Typography>
<Typography variant="body2">FNSKU: {item.item.fnsku}</Typography>
<Typography variant="body2">
  SKU: 
  <a 
    href={`https://app.yyzprep.ca/staff/inventory/${item.id}#history`} 
    target="_blank" 
    rel="noopener noreferrer"
  >
    {item.item.merchant_sku}
  </a>
</Typography>

</TableCell>


                        <TableCell align="center">
                          <Typography variant="body1">{item.expected.quantity}</Typography>
                        </TableCell>
                        <TableCell align="center">
  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <IconButton
        onClick={() => handleSetActualToExpected(index)}
        size="small"
        sx={{ mr: 1 }}
      >
        <ArrowForwardIosIcon sx={{ fontSize: 'small' }} />
      </IconButton>
      <TextField
        type="number"
        variant="outlined"
        value={item.actual.quantity}
        onChange={(e) => handleActualChange(index, e.target.value)}
        onBlur={() => handleActualBlur(index)}
        inputProps={{ min: 0 }}
        sx={{ width: '120px' }}
      />
    </Box>
    {actualChangeHistory[index] && (
      <Box sx={{ textAlign: 'center', mt: 1 }}>
        <Typography variant="caption" color="textSecondary">
          Changed to {actualChangeHistory[index].qty} pcs <br></br>
        </Typography>
        <Typography variant="caption" color="textSecondary">
          {Math.floor((new Date() - new Date(actualChangeHistory[index].timestamp)) / 1000)} seconds ago
        </Typography>
      </Box>
    )}
  </Box>
</TableCell>


                        <TableCell align="center">
  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <TextField
      type="number"
      variant="outlined"
      value={shelfQuantities[index] || 0}
      onChange={(e) => handleShelfQuantityChange(index, e.target.value)}
      inputProps={{ min: 0 }}
      sx={{ width: '80px', mb: 1 }}
    />
    <Button
      variant="contained"
      color="primary"
      onClick={() => handleAddToActual(index, parseInt(shelfQuantities[index] || 0, 10))}
      sx={{ mt: 1, width: '80px' }}
    >
      +
    </Button>
    {addToActualHistory[index] && (
      <Typography variant="caption" color="textSecondary" sx={{ mt: 1 }}>
        Added {addToActualHistory[index].qty} pcs <br></br>{Math.floor((new Date() - new Date(addToActualHistory[index].timestamp)) / 1000)} seconds ago
      </Typography>
    )}
  </Box>
</TableCell>

<TableCell align="center">
  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
  <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
  <TextField
    fullWidth
    placeholder="Shelf Code i.e P-01-01"
    variant="outlined"
    value={shelfCodes[index] || ''}
    onChange={(e) => handleShelfCodeChange(index, e.target.value.toUpperCase())}
    sx={{ mt: 1, mb: 1, mr: 1, flexGrow: 1 }}  // Adjust flexGrow for the first TextField
  />
  <TextField
    type="number"
    variant="outlined"
    placeholder="Qty"
    value={inputQuantities[index] || 0}
    onChange={(e) => handleQuantityChange(index, parseInt(e.target.value, 10))}
    inputProps={{ min: 0 }}
    sx={{ width: '150px', mt: 1, mb: 1 }}  // Adjust width for the number TextField
  />
</Box>

    <Button
      variant="contained"
      color="primary"
      onClick={() => handleAddToShelf(index)}
      sx={{ mt: 1, width: '100%' }}
    >
      Add to Shelf
    </Button>
    {addToShelfLog[index] && (
    <Typography variant="caption" color="textSecondary" sx={{ mt: 1 }}>
      {addToShelfLog[index].action} {addToShelfLog[index].qty} pcs to {addToShelfLog[index].shelfCode} <br />
      {Math.floor((new Date() - new Date(addToShelfLog[index].timestamp)) / 1000)} seconds ago
    </Typography>
  )}
  </Box>
</TableCell>
<TableCell align="center">
  {shelfData[item.item.merchant_sku] ? (
    shelfData[item.item.merchant_sku].map((shelf, idx) => (
      <Box key={idx} sx={{ mb: 2 }}>
        <Typography 
          variant="body2" 
          onClick={() => handleShelfClick({ ...shelf, SKU: item.item.merchant_sku })} // Ensure SKU is passed correctly
          style={{ cursor: 'pointer', textDecoration: 'underline' }}
        >
          {shelf.FULL_SHELF_CODE} x {shelf.QTY}
        </Typography>


        {shelfAdjustmentLog[`${item.item.merchant_sku}-${shelf.FULL_SHELF_CODE}`] && (
          <Typography variant="caption" color="textSecondary" sx={{ mt: 1 }}>
            {shelfAdjustmentLog[`${item.item.merchant_sku}-${shelf.FULL_SHELF_CODE}`].action}&nbsp;  
             {shelfAdjustmentLog[`${item.item.merchant_sku}-${shelf.FULL_SHELF_CODE}`].qty} pcs <br></br>
            from {shelfAdjustmentLog[`${item.item.merchant_sku}-${shelf.FULL_SHELF_CODE}`].shelfCode} 
            <br />
            {Math.floor((new Date() - new Date(shelfAdjustmentLog[`${item.item.merchant_sku}-${shelf.FULL_SHELF_CODE}`].timestamp)) / 1000)} seconds ago
          </Typography>
        )}
      </Box>
    ))
  ) : (
    <Typography variant="body2">N/A</Typography>
  )}
</TableCell>





                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </>
      )}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={snackbarOpen}
        autoHideDuration={2000} // Automatically hide after 2 seconds
        onClose={() => setSnackbarOpen(false)}
      >
        <MuiAlert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
      {/* <Dialog
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
      >
        <DialogTitle>Mark Shipment as Received?</DialogTitle>
        <DialogContent>
          <Typography>All items expected are received. Would you like to mark this shipment as received?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirmDialog(false)} color="secondary">No</Button>
          <Button onClick={handleMarkShipmentAsReceived} color="primary">Yes</Button>
        </DialogActions>
      </Dialog> */}
      <Dialog open={openShelfDialog} onClose={handleCloseShelfDialog}>
        <DialogTitle>Add All Items to 1 Shelf</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="Shelf Code"
            variant="outlined"
            value={allShelfCode}
            onChange={(e) => setAllShelfCode(e.target.value)}
            sx={{ mt: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseShelfDialog} color="secondary">Cancel</Button>
          <Button onClick={handleAddAllToShelf} color="primary">Confirm</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle>Update Shelf Quantity</DialogTitle>
        <DialogContent>
          <Typography>Full Shelf Code: {selectedShelf.FULL_SHELF_CODE}</Typography>
          <TextField
            label="New Quantity"
            type="number"
            fullWidth
            value={newQuantity}
            onChange={(e) => setNewQuantity(e.target.value)}
            sx={{ mt: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="secondary">Cancel</Button>
          <Button onClick={handleDialogSave} color="primary">Save</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
export default InboundDetail;
