import React, { useState, useEffect, useRef, useContext } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './tailwind-datepicker.css'; // Make sure this file is imported
import Select from 'react-select';
import ReactTooltip from 'react-tooltip';

import moment from 'moment';
import { generatePDF } from './labelGenerator';
import { BoxContext } from './BoxContext'; // Import BoxContext
const API_URL = process.env.REACT_APP_API_URL;

function OutboundItem({ item, quantityInBoxes }) {
  const { asinData, shelvingData, updateShelvingQty, reloadShipmentData,removeShelving, servicesData, clientInfo, reloadAsinData, selectedNickname } = useContext(BoxContext); // Use context data

  const [expiryDate, setExpiryDate] = useState(item.expiry_date ? new Date(item.expiry_date) : null);
  const [warning, setWarning] = useState(false);
  const [notification, setNotification] = useState(false);
  const dropdownRef = useRef(null);

  const [shelvingNotification, setShelvingNotification] = useState(false);
  const [priceNotification, setPriceNotification] = useState(false);
  const [weightNotification, setWeightNotification] = useState(false); // Add state for weight notification
  const [shelvingInputValues, setShelvingInputValues] = useState(
    shelvingData.reduce((acc, shelving) => {
      acc[shelving.KEY] = shelving.QTY;
      return acc;
    }, {})
  );
  const [removedQuantities, setRemovedQuantities] = useState({});
  const totalQuantity = item.quantity;
  const [selectedServices, setSelectedServices] = useState([]);
  const [showTooltip, setShowTooltip] = useState(false);
  const [hoverTimeout, setHoverTimeout] = useState(null);
  const [printOption, setPrintOption] = useState('');
  const [showPrintOptions1, setShowPrintOptions1] = useState(false);
  const [showPrintOptions5, setShowPrintOptions5] = useState(false);
  const [showPrintOptionsCustom, setShowPrintOptionsCustom] = useState(false);
  const [showExpiryOptions, setShowExpiryOptions] = useState(false);
  const [isLoadingPrice, setIsLoadingPrice] = useState(false);
  const [separatorNumber, setSeparatorNumber] = useState(0); // Add state for separator number
  const [customQty, setCustomQty] = useState(totalQuantity);

  const currentYear = new Date().getFullYear();
  const maxYear = currentYear + 10;

  const presetOptions = [
    { label: '160 days', value: new Date(new Date().setDate(new Date().getDate() + 160)) },
    { label: '1 year', value: new Date(new Date().setFullYear(new Date().getFullYear() + 1)) },
    { label: '2 years', value: new Date(new Date().setFullYear(new Date().getFullYear() + 2)) },
    { label: '3 years', value: new Date(new Date().setFullYear(new Date().getFullYear() + 3)) },
  ];

  useEffect(() => {
    setExpiryDate(item.expiry_date ? new Date(item.expiry_date) : null);
  }, [item]); // This useEffect will run whenever the 'item' prop changes


  useEffect(() => {
    // Pre-select existing services
    const preSelectedServices = item.company_services.map(service => ({
      value: service.name,
      label: service.name,
      ITEM_ID: service.pivot.outbound_shipment_item_id, // Include the ITEM_ID
      service: service // Include the full service object
    }));
    setSelectedServices(preSelectedServices);
  }, [item]);

  const handlePriceCheck = async (asin) => {
    try {
      setIsLoadingPrice(true); // Set loading state to true
      const response = await fetch(`${API_URL}/api/price?asin=${asin}`);
      const data = await response.json();
      console.log('Price data:', data);
      setPrice(data.VALUE);
    } catch (error) {
      console.error('Error fetching price:', error);
    } finally {
      setIsLoadingPrice(false); // Set loading state to false
    }
  };

  const handlePresetChange = (date) => {
    updateExpiryDate(date);
  };

  const formatCurrency = (value) => {
    return value;
  };

  const getAsinData = (asin) => {
    return asinData.find(data => data.ASIN === asin) || {};
  };
  //console.log(item);
  const asinDataItem = getAsinData(item.item.asin);
  const [price, setPrice] = useState(asinDataItem.VALUE || ''); // Initialize price after asinDataItem is defined
  const [weight, setWeight] = useState(asinDataItem.WEIGHT || ''); // Initialize weight after asinDataItem is defined

// Add useEffect to update price
useEffect(() => {
  setPrice(asinDataItem.VALUE || '');
}, [asinDataItem.VALUE]);

// Add useEffect to update weight
useEffect(() => {
  setWeight(asinDataItem.WEIGHT || '');
}, [asinDataItem.WEIGHT]);

const formatDate = (date) => {
  // Convert date to UTC to avoid timezone issues
  const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
  return utcDate.toISOString().split('T')[0]; // Return in 'YYYY-MM-DD' format
};

  const customPrintDropdownRef = useRef(null); // Add this new ref

  // Effect to handle clicks outside the dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowExpiryOptions(false);
        
      }
      if (customPrintDropdownRef.current && !customPrintDropdownRef.current.contains(event.target)) {
        setShowPrintOptionsCustom(false); // Hide custom print options when clicking outside
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  const updateExpiryDate = async (date) => {
    const newExpiryDate = date ? formatDate(date) : null;
    const itemId = item.id;
    const shipmentId = item.shipment_id;

    const currentDate = new Date();
    const differenceInDays = (date - currentDate) / (1000 * 60 * 60 * 24);

    if (differenceInDays < 90) {
      setWarning(true);
    } else {
      setWarning(false);
    }

    try {
      console.log("ITEM ID WE'RE SENDING PATCH TO: ", itemId);
      const modifiedItem = {
        ...item,
        expiry_date: newExpiryDate
    };
      const response = await fetch(`${API_URL}/api/update-expiry`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            itemId: itemId,
            shipmentId: shipmentId,
            item: modifiedItem,
        }),
      });

      if (response.ok) {
          console.log('Expiry date updated successfully');
          console.log(newExpiryDate);
          setExpiryDate(moment(newExpiryDate).toDate()); // Update the state with the new date
          setNotification(true); // Show notification
          reloadShipmentData();

          // Hide the notification after 0.5 seconds
          setTimeout(() => {
              setNotification(false);
          }, 500);
      } else {
          console.error('Failed to update expiry date');
          alert('Failed to update expiry date');
      }
  } catch (error) {
      console.error('Error updating expiry date:', error);
      alert('Error updating expiry date');
  } finally {
      setShowExpiryOptions(false);
  }
};

  const handlePriceBlur = async (e) => {
    console.log('Price blur event:', e.target.value);
    const newValue = e.target.value;
    setPrice(newValue);
    console.log('New price:', newValue);

    try {
      const response = await fetch(`${API_URL}/api/update_price`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ asin: item.item.identifiers.find(id => id.identifier_type === 'ASIN').identifier, value: newValue })
      });

      const result = await response.json();

      if (!response.ok) {
        throw new Error(result.error || 'Failed to update price');
      }

      console.log('Price updated successfully:', result.message);
      setPriceNotification(true); // Show notification
      reloadAsinData();

      // Hide the notification after 1 second
      setTimeout(() => {
        setPriceNotification(false);
      }, 500);
    } catch (error) {
      console.error('Error updating price:', error.message);
    }
  };

  const handleWeightBlur = async (e) => {
    console.log('Weight blur event:', e.target.value);
    const newValue = e.target.value;
    setWeight(newValue);
    console.log('New weight:', newValue);

    try {
      const response = await fetch(`${API_URL}/api/update_weight`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ asin: item.item.identifiers.find(id => id.identifier_type === 'ASIN').identifier, weight: newValue })
      });

      const result = await response.json();

      if (!response.ok) {
        throw new Error(result.error || 'Failed to update weight');
      }

      console.log('Weight updated successfully:', result.message);
      setWeightNotification(true); // Show notification
      reloadAsinData();

      // Hide the notification after 1 second
      setTimeout(() => {
        setWeightNotification(false);
      }, 500);
    } catch (error) {
      console.error('Error updating weight:', error.message);
    }
  };

  const handleShelvingQtyChange = (key, value) => {
    setShelvingInputValues(prevState => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleShelvingQtyBlur = async (key, shelfCode) => {
    const newQty = shelvingInputValues[key];
    try {
      await updateShelvingQty(key, shelfCode, newQty, item.item.merchant_sku);
      setShelvingNotification(true); // Show notification

      // Hide the notification after 1 second
      setTimeout(() => {
        setShelvingNotification(false);
      }, 500);
    } catch (error) {
      console.error('Error updating shelving quantity:', error);
      alert('Error updating shelving quantity');
    }
  };

  let remainingQty = totalQuantity - quantityInBoxes; 

  // Function to handle the removal of specific quantities from the shelves
  const handleRemoveFromShelf = async (shelfKey, shelfCode, quantityToRemove) => {
    const currentQty = shelvingInputValues[shelfKey];
    const newQty = currentQty - quantityToRemove;
  
    if (newQty < 0) {
      alert('Cannot remove more than the available quantity on the shelf.');
      return;
    }
  
    setShelvingInputValues(prevState => ({
      ...prevState,
      [shelfKey]: newQty,
    }));
  
    setRemovedQuantities(prevState => ({
      ...prevState,
      [shelfKey]: (prevState[shelfKey] || 0) + quantityToRemove,
    }));
  
    try {
      await updateShelvingQty(shelfKey, shelfCode, newQty, item.item.merchant_sku);
      setShelvingNotification(true); // Show notification
  
      // Hide the notification after 1 second
      setTimeout(() => {
        setShelvingNotification(false);
      }, 500);
    } catch (error) {
      console.error('Error updating shelving quantity:', error);
      alert('Error updating shelving quantity');
    }
  };
  
  const updateServices = async (selectedServices) => {
    const servicesPayload = selectedServices.reduce((acc, service, index) => {
      acc[index] = {
        id: service.service.id,
        name: service.service.name,
        charge: service.service.charge,
        advanced_options: service.service.advanced_options,
        quantity: 1 // assuming quantity is always 1
      };
      return acc;
    }, {});

    try {
      const response = await fetch(`https://app.yyzprep.ca/api/shipments/outbound/${item.shipment_id}/outbound-shipment-item/${item.id}/services?api_token=Yh7l5CUTaZ1nIgAueWglafvm616hchHFFZxRjKjPHNBjB19b2jTDgGoCSpeq`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          services: servicesPayload
        }),
      });

      if (response.ok) {
       
        console.log('Services updated successfully');
        setNotification(true); // Show notification
        reloadShipmentData();

        // Hide the notification after 1 second
        setTimeout(() => {
          setNotification(false);
        }, 500);
      } else {
        console.error('Failed to update services');
        alert('Failed to update services');
      }
    } catch (error) {
      console.error('Error updating services:', error);
      alert('Error updating services');
    }
  };

  const handleServiceChange = (selectedOptions) => {
    setSelectedServices(selectedOptions || []);
    updateServices(selectedOptions || []);
    reloadShipmentData();
    console.log("Service added so we reloaded shipment data")
  };

  const checkOversize = (item) => {
    const lengthInches = item.length_mm / 25.4;
    const widthInches = item.width_mm / 25.4;
    const heightInches = item.height_mm / 25.4;
    const weightLbs = item.weight_gm / 453.592;

    const isOversized = lengthInches > 18 || widthInches > 14 || heightInches > 8 || weightLbs > 20;
    const isHugeOversized = isOversized && (lengthInches >= 25 || widthInches >= 25 || heightInches >= 25);

    if (isHugeOversized) return 'Huge Oversized';
    if (isOversized) return 'Oversized';
    return '';
  };

  const getPrepRequirement = (prepInstructions) => {
  if (!prepInstructions) return [];
    return prepInstructions.filter(instruction => instruction !== "Labeling");
  };

  const oversizeTag = checkOversize(item.item);
  const prepRequirement = getPrepRequirement(item.item.prep_instructions);

  let statusBadge = null;
  if (quantityInBoxes === 0) {
    statusBadge = (
      <span className="absolute top-0 right-0 bg-red-500 text-white text-xs font-bold px-2 py-1 rounded-bl-lg">
        Not Started
      </span>
    );
  } else if (quantityInBoxes < totalQuantity) {
    statusBadge = (
      <span className="absolute top-0 right-0 bg-yellow-500 text-white text-xs font-bold px-2 py-1 rounded-bl-lg">
        In Progress
      </span>
    );
  } else if (quantityInBoxes === totalQuantity) {
    statusBadge = (
      <span className="absolute top-0 right-0 bg-green-500 text-white text-xs font-bold px-2 py-1 rounded-bl-lg">
        Completed
      </span>
    );
  } else if (quantityInBoxes > totalQuantity) {
    statusBadge = (
      <span className="absolute top-0 right-0 bg-red-700 text-white text-xs font-bold px-2 py-1 rounded-bl-lg">
        Over Limit
      </span>
    );
  }

  // Sort servicesData alphabetically by service name
  const sortedServices = [...servicesData.services].sort((a, b) => a.name.localeCompare(b.name));

  // Create options for react-select
  const serviceOptions = sortedServices.map(service => ({
    value: service.name,
    label: service.name,
    ITEM_ID: service.id, // Include the ITEM_ID
    service: service // Include the full service object
  }));

  const formatDimensions = (length, width, height) => {
    return `${Math.round(length / 10)} x ${Math.round(width / 10)} x ${Math.round(height / 10)} cm`;
  };

  const formatWeight = (weight) => {
    return `${(weight / 1000).toFixed(2)} kg`;
  };

  const handleMouseEnter = () => {
    const timeout = setTimeout(() => {
      setShowTooltip(true);
    }, 250); // 1 second delay
    setHoverTimeout(timeout);
  };

  const handleMouseLeave = () => {
    clearTimeout(hoverTimeout);
    setShowTooltip(false);
  };

  const handlePrintOptionChange = (quantity, option, setShowPrintOptions) => {
    setPrintOption(option);
    setShowPrintOptions(false);
    handlePrint(quantity, option);
  };
  
  const handlePrint = (quantity, option) => {
    generatePDF(item, quantity, expiryDate, option || printOption, separatorNumber);
    setPrintOption(''); // Reset the printOption after printing
  };
  
  const truncateText = (text, maxLength, firstPercentage = 60, lastPercentage = 40) => {
    if (text.length <= maxLength) return text; // Return the full text if it's shorter than maxLength
  
    const firstChars = Math.floor((firstPercentage / 100) * text.length); // First X% of characters
    const lastChars = Math.floor((lastPercentage / 100) * text.length); // Last Y% of characters
  
    const firstPart = text.slice(0, firstChars); // First X% of characters
    const lastPart = text.slice(-lastChars); // Last Y% of characters
  
    return `${firstPart}...${lastPart}`;
  };

  const togglePrintOptions = (setShowPrintOptions) => {
    setShowPrintOptions(prev => !prev);
  };

  return (
<div className="bg-white shadow-[0_0_10px_0_rgba(0,0,0,0.1)] rounded-lg mb-4 relative max-w-screen-lg mx-auto transition-shadow duration-200 hover:shadow-[0_0_20px_0_rgba(0,0,0,0.15)]">        <div className="absolute top-0 left-0 bg-green-500 text-white text-xs font-bold px-2 py-1 rounded-full">
          ✓ {priceNotification ? "Price Saved" : weightNotification ? "Weight Saved" : "Saved"}
        </div>
      
  
      {/* Header */}
      <div className="flex items-center justify-between p-3 border-b">
      <>
      <h3
        className="text-lg font-semibold"
        data-tip={item.item.title}
        data-for="title-tooltip"
      >
        {truncateText(item.item.title, 200)}
      </h3>
      <ReactTooltip
        id="title-tooltip"
        effect="solid"
        place="top" // Default position
        delayShow={200} // Optional: Delay to show the tooltip
        delayHide={200} // Optional: Delay to hide the tooltip
        offset={{ top: 10, left: 10 }} // Optional: Adjust offset
        className="max-w-xs" // Optional: Limit tooltip width
      />
    </>    
{statusBadge}
      </div>
  
      {/* Main Content */}
      <div className="grid grid-cols-1 md:grid-cols-5 gap-4 p-3"> {/* Changed to 5 columns */}
        {/* Image */}
        <div className="col-span-1 flex items-center justify-center">
          <img
            src={(item.item.images && item.item.images.length > 0 && item.item.images[0].large_url) || 'https://qph.cf2.quoracdn.net/main-qimg-654617264f9192ec976abe6e53356240-lq'}
            alt={item.item.title}
            className="w-20 h-20 object-cover rounded-lg"
          />
        </div>
  
        {/* Details */}
        <div className="col-span-2 space-y-2"> {/* Reduced to col-span-2 */}
          <p><strong>ASIN: </strong>
            <a
              href={`https://${clientInfo.name.includes('(CA)') ? 'amazon.ca' : 'amazon.com'}/dp/${item.item.asin}`}
              target="_blank"
              rel="noopener noreferrer"
              className="text-black hover:text-blue-500"
              title="Opens Amazon Product Page"
            >
              {item.item.asin}
            </a>
          </p>
          <p><strong>FNSKU:</strong> {item.item.fnsku}</p>
          <p><strong>SKU: </strong>
            <a
              href={`https://app.yyzprep.ca/staff/inventory/${item.item.id}#history`}
              target="_blank"
              rel="noopener noreferrer"
              className="text-black hover:text-blue-500"
              title="Opens SKU History on YYZ Prep Website"
            >
              {item.item.merchant_sku}
            </a>
          </p>
          <p><strong>Estimated Dims:</strong> {formatDimensions(item.item.length_mm, item.item.width_mm, item.item.height_mm)} | {formatWeight(item.item.weight_gm)}</p>
          <p><strong>Prep Requirement: </strong>
            <span className={prepRequirement.length > 0 ? "text-red-500" : "text-green-500"}>
              {prepRequirement.length > 0 ? prepRequirement.join(', ') : 'None'}
            </span>
          </p>
          {oversizeTag && (
            <span className="bg-red-500 text-white text-sm font-bold px-2 py-1 rounded">
              {oversizeTag}
            </span>
          )}
        </div>
  
        {/* Services */}
        <div className="col-span-2"> {/* Increased to col-span-2 */}
          <h4 className="text-lg font-semibold mb-2">Services Added</h4>
          <Select
            isMulti
            options={serviceOptions}
            value={selectedServices}
            onChange={handleServiceChange}
            className="w-full"
          />
        </div>
      </div>
  
      {/* Actions */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 p-3 bg-gray-100 rounded">
        {/* Quantity & Weight */}
        <div className="flex items-center space-x-2">
          <span className="font-bold text-lg">{quantityInBoxes} / {totalQuantity}</span>
          <input
            type="number"
            value={weight}
            onBlur={handleWeightBlur}
            onChange={(e) => setWeight(e.target.value)}
            className="border border-gray-300 rounded p-1 w-16"
            placeholder="Weight"
          />
          <span>kg</span>
        </div>
  
        {/* Price (USA Only) */}
        {clientInfo.country === 'USA' && (
          <div className="flex items-center space-x-2">
            <span
              className={`px-2 py-1 border rounded-full cursor-pointer ${isLoadingPrice ? 'bg-gray-200' : 'bg-blue-500 text-white hover:bg-blue-600'}`}
              onClick={(e) => { e.preventDefault(); handlePriceCheck(item.item.asin); }}
            >
              $
            </span>
            <input
              type="number"
              value={price}
              onBlur={handlePriceBlur}
              onChange={(e) => setPrice(e.target.value)}
              className={`border border-gray-300 rounded p-1 w-24 ${isLoadingPrice ? 'bg-gray-200' : ''}`}
              placeholder="Price"
              disabled={isLoadingPrice}
            />
            {isLoadingPrice && <span>Loading...</span>}
          </div>
        )}
  
        {/* Expiry Date */}
        <div className="flex items-center space-x-2">
          <DatePicker
            selected={expiryDate}
            onChange={updateExpiryDate}
            className="border border-gray-300 rounded p-1 w-24"
            placeholderText="Expiry Date"
            dateFormat="yyyy-MM-dd"
            showYearDropdown
            showMonthDropdown
            dropdownMode="select"
            minDate={new Date(currentYear, 0, 1)}
            maxDate={new Date(maxYear, 11, 31)}
          />
          <button onClick={() => setShowExpiryOptions(!showExpiryOptions)}>
            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" d="M10 6a1 1 0 110-2 1 1 0 010 2zm0 3a1 1 0 110-2 1 1 0 010 2zm0 3a1 1 0 110-2 1 1 0 010 2z" clipRule="evenodd"></path>
            </svg>
          </button>
          {showExpiryOptions && (
            <div ref={dropdownRef} className="absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10">
              <div className="py-1">
                {presetOptions.map((option, index) => (
                  <button
                    key={index}
                    className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    onClick={() => updateExpiryDate(option.value)}
                  >
                    {option.label}
                  </button>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
  
      {/* Warning */}
      {warning && (
        <div className="text-red-500 text-sm p-3">
          Warning: Expiry date is less than 90 days.
        </div>
      )}
  
      {/* Shelving Locations (Conditional) */}
      {shelvingData && shelvingData.length > 0 && (
        <div className="p-3">
          <h4 className="text-lg font-semibold mb-2">Shelving Locations</h4>
          {shelvingData
            .filter(shelving => shelving.SKU === item.item.merchant_sku && shelvingInputValues[shelving.KEY] > 0)
            .map((shelving, index) => {
              const qtyToRemove = Math.min(quantityInBoxes, shelvingInputValues[shelving.KEY]);
              return (
                <div key={index} className="flex items-center justify-between mb-2">
                  <p><strong>Location:</strong> {shelving.FULL_SHELF_CODE}</p>
                  <div className="flex items-center space-x-2">
                    <input
                      type="number"
                      className="border border-gray-300 rounded p-1 w-16"
                      value={shelvingInputValues[shelving.KEY]}
                      onChange={(e) => handleShelvingQtyChange(shelving.KEY, Number(e.target.value))}
                      onBlur={() => handleShelvingQtyBlur(shelving.KEY, shelving.FULL_SHELF_CODE)}
                    />
                    <button
                      className="bg-red-500 text-white px-2 py-1 rounded"
                      onClick={() => {
                        if (window.confirm(`Are you sure you want to remove all items from ${shelving.FULL_SHELF_CODE}?`)) {
                          removeShelving(shelving.KEY, shelving.FULL_SHELF_CODE, item.item.merchant_sku);
                        }
                      }}
                    >
                      X
                    </button>
                    {shelvingInputValues[shelving.KEY] > 0 && (
                      <button
                        className="bg-blue-500 text-white px-2 py-1 rounded"
                        onClick={() => handleRemoveFromShelf(shelving.KEY, shelving.FULL_SHELF_CODE, qtyToRemove)}
                      >
                        Remove {qtyToRemove} pcs
                      </button>
                    )}
                    {removedQuantities[shelving.KEY] > 0 && (
                      <span className="text-green-500">
                        Removed {removedQuantities[shelving.KEY]} pcs
                      </span>
                    )}
                  </div>
                </div>
              );
            })}
        </div>
      )}
  
      {/* Print Buttons */}
      <div className="flex justify-center space-x-4 p-3 bg-gray-100 rounded-b-lg">
        <button
          className="bg-blue-500 text-white py-1 px-3 rounded"
          onClick={() => handlePrint(1)}
        >
          Print 1
        </button>
        <button
          className="bg-blue-500 text-white py-1 px-3 rounded"
          onClick={() => handlePrint(5)}
        >
          Print 5
        </button>
        <div className="relative">
          <button
            className="bg-blue-500 text-white py-1 px-3 rounded"
            onClick={() => togglePrintOptions(setShowPrintOptionsCustom)}
          >
            Print
          </button>
          <input
            type="number"
            id={`customQty${item.item.merchant_sku}`}
            className="border border-gray-300 rounded p-1 w-16 ml-2"
            value={customQty}
            onChange={(e) => setCustomQty(e.target.value)}
            placeholder="Qty"
          />
          {showPrintOptionsCustom && (
            <div ref={customPrintDropdownRef} className="absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10">
              <div className="py-1">
                <button
                  className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  onClick={() => {
                    const qty = parseInt(document.getElementById(`customQty${item.item.merchant_sku}`).value, 10);
                    handlePrintOptionChange(qty, '', setShowPrintOptionsCustom);
                  }}
                >
                  Print
                </button>
                <button
                  className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  onClick={() => {
                    const qty = parseInt(document.getElementById(`customQty${item.item.merchant_sku}`).value, 10);
                    handlePrintOptionChange(qty, 'Ready to Ship', setShowPrintOptionsCustom);
                  }}
                >
                  Print with Ready to Ship
                </button>
                <button
                  className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  onClick={() => {
                    const qty = parseInt(document.getElementById(`customQty${item.item.merchant_sku}`).value, 10);
                    handlePrintOptionChange(qty, 'Sold as Set', setShowPrintOptionsCustom);
                  }}
                >
                  Print with Sold as Set
                </button>
                <button
                  className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  onClick={() => {
                    const qty = parseInt(document.getElementById(`customQty${item.item.merchant_sku}`).value, 10);
                    handlePrintOptionChange(qty, 'Warning Labels', setShowPrintOptionsCustom);
                  }}
                >
                  Print with Warning Labels
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default OutboundItem;
