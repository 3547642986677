import React, { useEffect, useState, useCallback, useContext, useRef } from 'react';
import { Container, Accordion, Table, Spinner, Alert, Button, Modal, Form  } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { BillingContext } from './BillingContext';
import Select from 'react-select';
import LoadingOverlay from './LoadingOverlay'; // Import the LoadingOverlay component

const API_BASE_URL = 'https://app.yyzprep.ca/api';
const API_TOKEN = 'Yh7l5CUTaZ1nIgAueWglafvm616hchHFFZxRjKjPHNBjB19b2jTDgGoCSpeq';
const LOCAL_API_URL = process.env.REACT_APP_API_URL;

const BOX_RATE = 1.29; // Rate per box
// New services for USA shipments based on weight
const USA_WEIGHT_SERVICES = [
  { name: 'USA | Less than 10lb', maxWeightLbs: 10 },
  { name: 'USA | Between 10lb and 20lb', maxWeightLbs: 20 },
  { name: 'USA | Between 21lb and 40lb', maxWeightLbs: 40 },
  { name: 'USA | More than 40lb', maxWeightLbs: Infinity },
];

const ClientDetail = () => {
  // Add this state for customer selection
  const [selectedSquareCustomer, setSelectedSquareCustomer] = useState(null);
  const [squareCustomerOptions, setSquareCustomerOptions] = useState([]);
  const [applyCcAdminFee, setApplyCcAdminFee] = useState(false);
  const [applyMinimumAdjustment, setApplyMinimumAdjustment] = useState(false);

  const [invoiceNotes, setInvoiceNotes] = useState('');
  const { teamId, startDate, endDate } = useParams();
  const { setClientShipments } = useContext(BillingContext);

  const [loading, setLoading] = useState(true); // Default to true for initial loading state

  // Shipment and Client Data
  const [detailedShipments, setDetailedShipments] = useState([]);
  const [clients, setClients] = useState([]);
  const [boxData, setBoxData] = useState([]);

  // Rates and Fees
  const [rateSheet, setRateSheet] = useState([]);
  const [servicesAdded, setServicesAdded] = useState([]);
  const [serviceCharges, setServiceCharges] = useState([]);
  const [totalBillWithTax, setTotalBillWithTax] = useState(0);

  // Boxes and Pallets
  const [standardBoxes, setStandardBoxes] = useState([]);
  const [boxUsage, setBoxUsage] = useState({});
  const [totalBoxesOverall, setTotalBoxesOverall] = useState(0);
  const [totalUnitsShipped, setTotalUnitsShipped] = useState(0);
  const [waivedBoxes, setWaivedBoxes] = useState(0);

  // UI State
  const [expandedService, setExpandedService] = useState(null);
  const [warnings, setWarnings] = useState([]);
  const [showStorageModal, setShowStorageModal] = useState(false);
  const [pallets, setPallets] = useState('');
  const [cbm, setCbm] = useState('');
  const [customAmount, setCustomAmount] = useState('');
  const [clientTier, setClientTier] = useState(1);
  const [fbaIds, setFbaIds] = useState([]);

  const [billableBoxes, setBillableBoxes] = useState(0);
  const [billableBoxesAdded, setBillableBoxesAdded] = useState(false);

  // Custom Service Modal
  const [showCustomServiceModal, setShowCustomServiceModal] = useState(false);
  const [customServiceName, setCustomServiceName] = useState('');
  const [customServiceRate, setCustomServiceRate] = useState('');
  const [customServiceQuantity, setCustomServiceQuantity] = useState('');

  // Tax State
  const [selectedTaxes, setSelectedTaxes] = useState({});
  const [applyTaxToAll, setApplyTaxToAll] = useState(false);

  // Fetch State Flags
  const [dataFetched, setDataFetched] = useState({
    clients: false,
    rateSheet: false,
    standardBoxes: false,
  });

  // Calculation Flags
  const [calculationsCompleted, setCalculationsCompleted] = useState({
    usaWeightFees: false,
    standardBoxes: false,
    palletDeliveries: false,
  });

  const formatFbaIdsForNotes = (fbaIds) => {
    return fbaIds.length > 0 ? `FBA Shipment IDs:\n${fbaIds.join('\n')}` : 'No FBA Shipment IDs found.';
  };

  useEffect(() => {
    const loadBoxData = async () => {
      const boxData = await fetchBoxes();
      setBoxData(boxData);
    };
    loadBoxData();
  }, []);

  const handleAddCustomService = () => {
    const customService = {
      serviceId: `custom-${Date.now()}`, // Unique ID based on timestamp
      name: customServiceName,
      charge: parseFloat(customServiceRate),
      quantity: parseInt(customServiceQuantity, 10),
      totalCharge: parseFloat(customServiceRate) * parseInt(customServiceQuantity, 10),
    };

    setServicesAdded((prevServices) => [...prevServices, customService]);

    // Reset modal fields and close modal
    setCustomServiceName('');
    setCustomServiceRate('');
    setCustomServiceQuantity(1);
    setShowCustomServiceModal(false);

    // Recalculate service charges
    calculateServiceCharges([...servicesAdded, customService]);
  };

  const fetchBoxes = async () => {
    try {
      const response = await fetch(`${LOCAL_API_URL}/api/boxes`);
      if (!response.ok) {
        throw new Error('Failed to fetch boxes data');
      }
      const boxData = await response.json();
      return boxData;
    } catch (error) {
      console.error('Error fetching boxes:', error);
      return [];
    }
  };

  useEffect(() => {
    if (applyCcAdminFee) {
      calculateCcAdminFee(totalBillWithTax);
    } else {
      calculateServiceCharges(servicesAdded); // Recalculate without the fee
    }
  }, [applyCcAdminFee, totalBillWithTax]);

  const calculateMinimumAdjustmentFee = () => {
    const totalAmount = totalBillWithTax;
    const minimumAdjustment = 500 - totalAmount;

    if (minimumAdjustment > 0) {
      const service = {
        serviceId: 'minimum-adjustment-fee',
        name: 'Monthly Minimum Adjustment',
        charge: minimumAdjustment,
        type: 'minimum-adjustment-fee',
        shipmentId: 'N/A',
        origin: 'minimum-adjustment',
        quantity: 1,
        totalCharge: minimumAdjustment,
      };

      setServicesAdded((prevServices) => {
        const updatedServices = [
          ...prevServices.filter(service => service.serviceId !== 'minimum-adjustment-fee'),
          service
        ];
        return updatedServices;
      });

      setApplyMinimumAdjustment(true);
    }
  };

  const calculateCcAdminFee = (totalAmount) => {
    const ccAdminFee = totalAmount * 0.024;
    const eTransferAmount = (totalAmount - ccAdminFee).toFixed(2);
    const eTransferMessage = `To avoid paying the CC Admin Fee, you can send an eTransfer of $${eTransferAmount} to hello@yyzprep.ca and send us the password if applicable.\n\n`;

    if (applyCcAdminFee) {
      const service = {
        serviceId: 'cc-admin-fee',
        name: 'CC Admin Fee',
        charge: ccAdminFee,
        type: 'cc-admin-fee',
        shipmentId: 'N/A',
        origin: 'cc-admin-fee',
        quantity: 1,
        totalCharge: ccAdminFee,
      };

      setServicesAdded((prevServices) => {
        const updatedServices = [
          ...prevServices.filter(service => service.serviceId !== 'cc-admin-fee'),
          service
        ];
        return updatedServices;
      });

      // Replace any existing eTransfer message with the updated one
      setInvoiceNotes(prevNotes => {
        const newNotes = prevNotes.replace(
          /To avoid paying the CC Admin Fee, you can send an eTransfer of \$[0-9.]+ to hello@yyzprep.ca and send us the password if applicable\.\n\n/,
          ''
        );
        return `${eTransferMessage}${newNotes}`;
      });
    } else {
      setServicesAdded(prevServices => prevServices.filter(service => service.serviceId !== 'cc-admin-fee'));

      // Remove the eTransfer message if the fee is removed
      setInvoiceNotes(prevNotes => prevNotes.replace(eTransferMessage, ''));
    }
  };

  // Function to extract FBA IDs from shipments
  const extractFbaIds = (shipments) => {
    const fbaIdList = [];
    console.log("Getting FBA IDs...");
    shipments.forEach((shipment) => {
      if (shipment.plans && shipment.plans.length > 0) {
        // Extract FBA IDs from plans
        shipment.plans.forEach((plan) => {
          if (plan.fba_shipment_id) {
            fbaIdList.push(plan.fba_shipment_id);
          }
        });
      } else {
        // Extract FBA ID from marketplace_data if no plans
        console.log(shipment);
        const marketplaceData = JSON.parse(shipment.outboundInfo.shipment.marketplace_data);
        if (marketplaceData && marketplaceData.shipment_id) {
          fbaIdList.push(marketplaceData.shipment_id);
        }
      }
    });

    return fbaIdList;
  };

  // Delay function using Promise
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const getRateForService = (serviceName, clientTier = 1) => {
    const rateEntry = rateSheet.find((entry) => entry.SERVICE_NAME === serviceName);
    if (!rateEntry) return 0;

    // Determine the tier field name based on the clientTier
    const tierField = `TIER_${clientTier}_RATE`;

    // Return the rate for the specified tier
    return parseFloat(rateEntry[tierField]) || 0;
  };

  const calculateUSAWeightFees = async (shipments, clients) => {
    let allUSAWeightFees = [];
  
    for (const shipment of shipments) {
      const client = clients.find((client) => client.id === shipment.outboundInfo.shipment.team_id);
      if (!client || !client.name.includes("(US)")) {
        continue;
      }
  
      let usaWeightFees = [];
      console.log("Calculating USA weight fees for Outbound ID:", shipment.id);
      console.log("Number of plans in this shipment:", shipment.plans ? shipment.plans.length : 0);
  
      if (shipment.plans && shipment.plans.length > 0) {
        for (const plan of shipment.plans) {
          const boxGroups = plan.box_groups || [];
          console.log("Box Groups in plan", plan.id, "=>", boxGroups.length);
          boxGroups.forEach((boxGroup) => {
            const weightLbs = gmToLbs(boxGroup.weight_gm);
            const service = USA_WEIGHT_SERVICES.find(
              (s) => weightLbs <= s.maxWeightLbs
            );
            if (service) {
              const rate = getRateForService(service.name);
              const quantity = boxGroup.box_numbers.length || 1;
              if (rate > 0) {
                usaWeightFees.push({
                  serviceId: service.name,
                  name: service.name,
                  charge: rate,
                  type: "usa-weight-fee",
                  shipmentId: shipment.id,
                  origin: "weight-check",
                  quantity: quantity,
                  totalCharge: rate * quantity,
                });
              }
            }
          });
        }
      } else {
        // No plans => fall back to box-contents approach
        const boxContents = await fetchBoxContents(shipment.id);
        const uniqueBoxWeights = {};
        boxContents.forEach((box) => {
          const { BOX_NUMBER, WEIGHT } = box;
          if (!uniqueBoxWeights[BOX_NUMBER]) {
            uniqueBoxWeights[BOX_NUMBER] = WEIGHT;
          }
        });
  
        Object.keys(uniqueBoxWeights).forEach((boxNumber) => {
          const weightKg = uniqueBoxWeights[boxNumber];
          const weightLbs = kgToLbs(weightKg);
          const service = USA_WEIGHT_SERVICES.find(
            (s) => weightLbs <= s.maxWeightLbs
          );
          if (service) {
            const rate = getRateForService(service.name);
            if (rate > 0) {
              usaWeightFees.push({
                serviceId: service.name,
                name: service.name,
                charge: rate,
                type: 'usa-weight-fee',
                shipmentId: shipment.id,
                origin: 'weight-check',
                quantity: 1,
                totalCharge: rate,
              });
            }
          }
        });
      }
  
      if (usaWeightFees.length > 0) {
        console.log(
          `Outbound ID ${shipment.id} => Found ${usaWeightFees.length} USA weight fee entries before aggregation.`
        );
      }
      allUSAWeightFees.push(...usaWeightFees);
    }
  
    // Aggregate
    const aggregatedFees = aggregateUSAWeightFees(allUSAWeightFees);
    
    // Debug: Log final aggregated fees for each shipment & service
    console.log("Aggregated USA Weight Fees:");
    aggregatedFees.forEach((fee) => {
      console.log(
        `- ShipmentID: ${fee.shipmentId}, Service: ${fee.serviceId}, Qty: ${fee.quantity}, Rate: ${fee.charge}, Total: ${fee.totalCharge}`
      );
    });
  
    // Add to services
    setServicesAdded((prevServices) => {
      const newServices = [...prevServices, ...aggregatedFees];
      return JSON.stringify(prevServices) !== JSON.stringify(newServices)
        ? newServices
        : prevServices;
    });
  };
  

  // Helper function to aggregate USA weight fees
  const aggregateUSAWeightFees = (fees) => {
    const feeMap = new Map();

    fees.forEach((fee) => {
      const key = `${fee.serviceId}-${fee.shipmentId}`;
      if (feeMap.has(key)) {
        const existingFee = feeMap.get(key);
        existingFee.quantity += fee.quantity;
        existingFee.totalCharge += fee.totalCharge;
      } else {
        feeMap.set(key, { ...fee });
      }
    });

    return Array.from(feeMap.values());
  };

  const fetchData = async () => {
    setLoading(true); // Start loading

    try {
      const [squareResponse, clientResponse, rateSheetResponse, standardBoxResponse] = await Promise.all([
        fetch(`${LOCAL_API_URL}/api/square/customers`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        }),
        fetch(`${LOCAL_API_URL}/api/get_clients`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        }),
        fetch(`${LOCAL_API_URL}/api/rate-sheet`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        }),
        fetch(`${LOCAL_API_URL}/api/get_standard_boxes`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        }),
      ]);

      // Check if all responses are OK
      if (
        !squareResponse.ok ||
        !clientResponse.ok ||
        !rateSheetResponse.ok ||
        !standardBoxResponse.ok
      ) {
        throw new Error('Failed to fetch one or more resources');
      }

      const squareCustomers = await squareResponse.json();
      const clientList = await clientResponse.json();
      const rateSheet = await rateSheetResponse.json();
      const standardBoxes = await standardBoxResponse.json();
      setClients(clientList);

      setSquareCustomerOptions(
        squareCustomers.map((customer) => ({
          value: customer.id,
          email: customer.emailAddress,
          companyName: customer.companyName,
          label: `${customer.companyName} (${customer.emailAddress})`,
        }))
      );

      setRateSheet(rateSheet);
      setStandardBoxes(standardBoxes);

      // Update fetch flags
      setDataFetched({
        clients: true,
        rateSheet: true,
        standardBoxes: true,
      });

      // Re-run calculations if needed
      calculateServiceCharges(detailedShipments);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false); // Ensure loading state is turned off
    }
  };

  useEffect(() => {
    const totalAmount = calculateTotalBillWithTax();
    if (totalAmount !== totalBillWithTax) {
      setTotalBillWithTax(totalAmount);
    }

    // Apply minimum adjustment if total is below $500 and not already applied
    if (totalAmount < 500 && !applyMinimumAdjustment) {
      calculateMinimumAdjustmentFee();
    }
  }, [serviceCharges, selectedTaxes, totalBillWithTax, applyMinimumAdjustment]);

  // Function to generate invoice
  const generateInvoice = async () => {
    if (!selectedSquareCustomer) {
      alert('Please select a Square customer to add invoice items.');
      return;
    }

    try {
      const invoiceLineItems = serviceCharges.map((charge) => {
        const unitPrice = parseFloat(charge.rate);
        const quantity = parseInt(charge.quantity, 10);

        if (isNaN(unitPrice) || isNaN(quantity)) {
          throw new Error('Invalid unit price or quantity');
        }

        // Check if tax is selected for this service
        const applyTax = selectedTaxes[charge.serviceId];

        return {
          description: charge.serviceName,
          quantity,
          unitPrice,
          applyTax, // Boolean to indicate if tax should be applied
        };
      });

      const invoiceData = {
        customerId: selectedSquareCustomer.value,
        customerEmail: selectedSquareCustomer.email, 
        notes: invoiceNotes,
        lineItems: invoiceLineItems,
        companyName: selectedSquareCustomer.companyName
      };

      const response = await fetch(`${LOCAL_API_URL}/api/create-invoice`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(invoiceData),
      });

      if (!response.ok) {
        throw new Error('Failed to create invoice');
      }

      const invoiceResponse = await response.json();
      alert('Invoice created successfully.');
      console.log('Invoice Response:', invoiceResponse);

      // Extract the invoice ID from the response
      const invoiceId = invoiceResponse.invoice.id;
      const locationId = 'LHC59K9B425QJ'; // Your location ID

      // Construct the URL to the specific invoice
      const invoiceUrl = `https://app.squareup.com/dashboard/invoices/${invoiceId}/edit?currentUnitToken=${locationId}`;
      window.open(invoiceUrl, '_blank');

      // Optionally, reset the form
      setSelectedSquareCustomer(null);
      setInvoiceNotes('');
    } catch (error) {
      console.error('Error creating invoice:', error);
      alert('Failed to create invoice.');
    }
  };

  useEffect(() => {
    // Recalculate service charges whenever servicesAdded changes
    calculateServiceCharges(servicesAdded);
    console.log('Service charges changed', serviceCharges.length);
  }, [servicesAdded]);

  useEffect(() => {
    const totalAmount = calculateTotalBillWithTax();
    if (totalAmount !== totalBillWithTax) {
      setTotalBillWithTax(totalAmount);
    }
  }, [serviceCharges, selectedTaxes, totalBillWithTax]);

  useEffect(() => {
    // Ensure FDA charges are recalculated when shipments or other relevant states change
    if (detailedShipments.length > 0) {
      calculateServiceCharges(detailedShipments);
      fetchFDAServiceAmount(); // Make sure this function is called appropriately
    }
  }, [detailedShipments, clientTier]);

  useEffect(() => {
    if (detailedShipments.length > 0) {
      const fbaIdList = extractFbaIds(detailedShipments);
      const shipmentsWithoutFba = detailedShipments.filter(shipment => {
        // Check if the shipment has plans with FBA IDs
        const hasFbaId = shipment.plans && shipment.plans.some(plan => plan.fba_shipment_id);
        return !hasFbaId;
      });

      // Create a list of shipment names and IDs for those without FBA IDs
      const shipmentNamesAndIds = shipmentsWithoutFba.map(shipment => `Shipment ID: ${shipment.id}`);

      // Merge both lists
      const mergedNotes = [
        ...fbaIdList.map(fbaId => `FBA ID: ${fbaId}`),
        ...shipmentNamesAndIds,
      ].join('\n');

      setFbaIds(fbaIdList); 
      setInvoiceNotes(mergedNotes);
    }
  }, [detailedShipments]);

  const fetchFDAServiceAmount = async (clientNameBase, startDate, endDate) => {
    try {
      const response = await fetch("https://hook.us1.make.com/ykt4b7hx2iyz4a6dh1acax88sj9pg5xu", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          client_name: clientNameBase,
          start_date: startDate,
          end_date: endDate,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch FDA service amount");
      }

      const amount = await response.text(); // Read the response as a plain number
      return parseFloat(amount); // Convert the response to a number
    } catch (error) {
      console.error("Error fetching FDA service amount:", error);
      return 0; // Return 0 if there's an error
    }
  };

  const handleAddStorageFee = () => {
    let calculatedCbm = 0;
    let amount = 0;

    // Calculate CBM based on user input
    if (pallets) {
      calculatedCbm = parseFloat(pallets) * 1.61; // 1 pallet = 1.61 CBM
    } else if (cbm) {
      calculatedCbm = parseFloat(cbm);
    } else if (customAmount) {
      amount = parseFloat(customAmount);
      calculatedCbm = amount / 50 * 1.61; // Rough approach if needed
    }

    // Calculate the total charge
    amount = (calculatedCbm / 1.61) * 50; // $50 per 1.61 CBM (or per pallet)

    // Create a new service entry
    const service = {
      serviceId: `storage-fee`,
      name: `Storage Fee (CBM)`,
      charge: amount,
      type: 'storage-charge',
      shipmentId: 'N/A',
      origin: 'storage-fee',
      quantity: 1,
      totalCharge: amount,
    };

    setServicesAdded((prevServices) => {
      const updatedServices = [...prevServices, service];
      calculateServiceCharges(updatedServices);
      return updatedServices;
    });

    // Reset form fields
    setPallets('');
    setCbm('');
    setCustomAmount('');

    // Close the modal
    setShowStorageModal(false);
  };

  const fetchClientList = async () => {
    try {
      const response = await fetch(`${LOCAL_API_URL}/api/get_clients`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch client list');
      }

      const clientList = await response.json();
      setClients(clientList);
      return clientList;
    } catch (error) {
      console.error('Error fetching client list:', error);
      return [];
    }
  };

  const fetchAndCalculateFDANotice = async () => {
    try {
      let totalFDACharge = 0;

      // Group shipments by base client name
      const shipmentsByBaseClient = detailedShipments.reduce((acc, shipment) => {
        const client = clients.find(client => client.id === shipment.outboundInfo.shipment.team_id);
        if (!client) {
          console.error(`Client not found for team_id: ${shipment.outboundInfo.shipment.team_id}`);
          return acc;
        }
        const clientNameBase = client.name.replace(/(\s*\(CA\)|\s*\(US\))$/i, '');
        if (!acc[clientNameBase]) {
          acc[clientNameBase] = [];
        }
        acc[clientNameBase].push(shipment);
        return acc;
      }, {});

      for (const clientNameBase in shipmentsByBaseClient) {
        if (shipmentsByBaseClient.hasOwnProperty(clientNameBase)) {
          const fdaServiceAmount = await fetchFDAServiceAmount(clientNameBase, startDate, endDate);
          if (fdaServiceAmount > 0) {
            const fdaTotalCharge = fdaServiceAmount * 1.75; // $1.75 per service
            totalFDACharge += fdaTotalCharge;

            setServicesAdded((prevServices) => [
              ...prevServices,
              {
                serviceId: "fda-prior-notice",
                name: "FDA Prior Notice Service",
                charge: 1.75,
                type: "fda-charge",
                shipmentId: "N/A",
                origin: "fda-service",
                quantity: fdaServiceAmount,
                totalCharge: fdaTotalCharge,
                tax: 0,
              },
            ]);
          }
        }
      }
      console.log(`Total FDA Prior Notice Charge: $${totalFDACharge.toFixed(2)}`);
    } catch (error) {
      console.error('Error calculating FDA Prior Notice:', error);
    }
  };

  const fetchAndCalculatePalletDeliveries = async (clientName, startDate, endDate) => {
    try {
      // Remove any country suffix to match the base company name
      const clientNameBase = clientName.replace(/(\s*\(CA\)|\s*\(US\))$/i, '');

      const response = await fetch(
        `${LOCAL_API_URL}/api/delivery-log`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            company: clientNameBase,
            startDate,
            endDate,
          }),
        }
      );

      if (!response.ok) {
        throw new Error('Failed to fetch delivery log data');
      }

      const deliveryLogData = await response.json();

      const filteredDeliveries = deliveryLogData.filter((entry) => {
        const timeOfScan = new Date(entry.TIME_OF_SCAN);
        const trackingCodePattern = /^MANUAL_ENTRY_\d-Pallet/;
        const alternatePattern = /^MANUAL_ENTRY_\d--/;

        return (
          timeOfScan >= new Date(startDate) &&
          timeOfScan <= new Date(endDate) &&
          (trackingCodePattern.test(entry.TRACKING_CODE) || alternatePattern.test(entry.TRACKING_CODE))
        );
      });

      const palletCount = filteredDeliveries.length;
      console.log(filteredDeliveries);

      const totalCharge = palletCount * 10;
      if (palletCount > 0) {
        setServicesAdded((prevServices) => [
          ...prevServices,
          {
            serviceId: 'ltl-delivery-receiving',
            name: 'Delivery Receiving (LTL)',
            charge: 10,
            type: 'pallet-charge',
            shipmentId: 'N/A',
            origin: 'pallet-delivery',
            quantity: palletCount,
            totalCharge: totalCharge,
            tax: 0,
          },
        ]);
      }
      return { palletCount, totalCharge };
    } catch (error) {
      console.error('Error calculating pallet deliveries:', error);
      return { palletCount: 0, totalCharge: 0 };
    }
  };

  const fetchRateSheet = async () => {
    try {
      const response = await fetch(`${LOCAL_API_URL}/api/rate-sheet`);
      if (!response.ok) {
        throw new Error('Failed to fetch rate sheet data');
      }
      const data = await response.json();
      setRateSheet(data);
    } catch (error) {
      console.error('Error fetching rate sheet:', error);
    }
  };

  const prevDetailedShipments = useRef();
  const prevClients = useRef();

  useEffect(() => {
    if (dataFetched.clients && dataFetched.rateSheet && dataFetched.standardBoxes) {
      if (
        JSON.stringify(prevDetailedShipments.current) !== JSON.stringify(detailedShipments) ||
        JSON.stringify(prevClients.current) !== JSON.stringify(clients)
      ) {
        calculateServiceCharges(detailedShipments);
        calculateUSAWeightFees(detailedShipments, clients);
        calculateStandardBoxes(detailedShipments);
        calculatePalletDeliveries(detailedShipments, clients);

        prevDetailedShipments.current = detailedShipments;
        prevClients.current = clients;
      }
    }
  }, [dataFetched, detailedShipments, clients]);

  useEffect(() => {
    if (calculationsCompleted.usaWeightFees && calculationsCompleted.standardBoxes) {
      const updatedCharges = calculateServiceCharges(detailedShipments);
      if (JSON.stringify(serviceCharges) !== JSON.stringify(updatedCharges)) {
        setServiceCharges(updatedCharges);
      }
    }
  }, [calculationsCompleted, detailedShipments]);

  useEffect(() => {
    if (serviceCharges.length > 0) {
      const totalAmount = calculateTotalBillWithTax();
      setTotalBillWithTax(totalAmount);
    }
  }, [serviceCharges, selectedTaxes]);

  useEffect(() => {
    if (detailedShipments.length > 0) {
      calculateServiceCharges(detailedShipments);
    }
  }, [clientTier, detailedShipments]);

  useEffect(() => {
    if (teamId && startDate && endDate) {
      fetchClientShipments();
    }
  }, [teamId, startDate, endDate]);

  const fetchStandardBoxes = async () => {
    try {
      const response = await fetch(`${LOCAL_API_URL}/api/get_standard_boxes`);
      if (!response.ok) {
        throw new Error('Failed to fetch standard box dimensions');
      }
      const data = await response.json();
      setStandardBoxes(data);
    } catch (error) {
      console.error('Error fetching standard boxes:', error);
    }
  };

  const fetchBoxContents = async (shipmentId) => {
    try {
      const response = await fetch(
        `${LOCAL_API_URL}/api/box-contents?outbound_id=${shipmentId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch box contents");
      }

      const boxContents = await response.json();
      return boxContents;
    } catch (error) {
      console.error("Error fetching box contents:", error);
      return [];
    }
  };

  const getUniqueBoxes = (boxContents) => {
    const uniqueBoxesMap = new Map();
    boxContents.forEach((item) => {
      const box = {
        length: parseInt(item.LENGTH),
        width: parseInt(item.WIDTH),
        height: parseInt(item.HEIGHT),
        boxNumber: item.BOX_NUMBER,
      };
      if (!uniqueBoxesMap.has(box.boxNumber)) {
        uniqueBoxesMap.set(box.boxNumber, box);
      }
    });
    return Array.from(uniqueBoxesMap.values());
  };

  const determineClientTier = (totalUnitsShipped) => {
    if (totalUnitsShipped < 500) return 1;
    if (totalUnitsShipped < 1250) return 2;
    if (totalUnitsShipped < 2500) return 3;
    return 4;
  };

  const kgToLbs = (kg) => kg * 2.20462;
  const gmToLbs = (gm) => gm / 453.59237;

  const calculateTotalUnitsShipped = (shipments) => {
    return shipments.reduce(
      (total, shipment) => total + shipment.shipped_items_count,
      0
    );
  };

  const calculateWaivedBoxes = (unitLabels) => Math.floor(unitLabels / 100);

  const calculateServiceCharges = (shipments) => {
    const totalUnitsShipped = calculateTotalUnitsShipped(shipments);
    const autoClientTier = determineClientTier(totalUnitsShipped);
    setClientTier((prevTier) => prevTier || autoClientTier);

    const charges = {};
    servicesAdded.forEach((service) => {
      let rateEntry = rateSheet.find(
        (entry) => entry.SERVICE_ID === service.serviceId
      );

      let rate = rateEntry
        ? rateEntry[`TIER_${clientTier}_RATE`]
        : service.charge;

      let quantity = service.quantity || 1;

      // Example override for "Shipping Charge" if we needed it
      if (service.name.toLowerCase() === 'shipping charge' && quantity > 100000) {
        rate = 1.00;
        quantity = Math.ceil(quantity / 100);
      }

      const totalCharge = rate * quantity;

      if (!charges[service.name]) {
        charges[service.name] = {
          serviceName: service.name,
          serviceId: service.serviceId,
          rate: rate,
          quantity: 0,
          totalCharge: 0,
          tax: 0,
          breakdown: [],
        };
      }

      charges[service.name].quantity += quantity;
      charges[service.name].totalCharge += totalCharge;

      charges[service.name].breakdown.push({
        shipmentId: service.shipmentId,
        quantity: quantity,
        asin: service.asin,
        fnsku: service.fnsku,
        title: service.title,
        merchantSku: service.merchantSku,
        totalCharge: totalCharge,
      });
    });

    setServiceCharges(Object.values(charges));
  };

  const calculateStandardBoxes = async (shipments) => {
    const boxUsage = {};
    const totalBoxesCount = {};
    let totalBillableBoxes = 0;

    for (const shipment of shipments) {
      const plans = shipment.plans;
      if (plans.length > 0) {
        const boxGroups = plans.flatMap((plan) => plan.box_groups);
        boxGroups.forEach((boxGroup) => {
          const boxGroupLength = Math.round(boxGroup.length_mm / 10);
          const boxGroupWidth = Math.round(boxGroup.width_mm / 10);
          const boxGroupHeight = Math.round(boxGroup.height_mm / 10);

          const matchingBox = standardBoxes.find((standardBox) => {
            const boxLength = parseInt(standardBox.LENGTH, 10);
            const boxWidth = parseInt(standardBox.WIDTH, 10);
            const boxHeight = parseInt(standardBox.HEIGHT, 10);
            // Attempt to match in any orientation
            return (
              (boxLength === boxGroupLength && boxWidth === boxGroupWidth && boxHeight === boxGroupHeight) ||
              (boxLength === boxGroupLength && boxWidth === boxGroupHeight && boxHeight === boxGroupWidth) ||
              (boxLength === boxGroupWidth && boxWidth === boxGroupLength && boxHeight === boxGroupHeight) ||
              (boxLength === boxGroupWidth && boxWidth === boxGroupHeight && boxHeight === boxGroupLength) ||
              (boxLength === boxGroupHeight && boxWidth === boxGroupLength && boxHeight === boxGroupWidth) ||
              (boxLength === boxGroupHeight && boxWidth === boxGroupWidth && boxHeight === boxGroupLength)
            );
          });

          const boxCount = boxGroup.box_numbers && boxGroup.box_numbers.length > 0 ? boxGroup.box_numbers.length : 1;
          if (!totalBoxesCount[shipment.id]) {
            totalBoxesCount[shipment.id] = 0;
          }
          totalBoxesCount[shipment.id] += boxCount;

          if (matchingBox) {
            if (!boxUsage[matchingBox.NAME]) {
              boxUsage[matchingBox.NAME] = 0;
            }
            boxUsage[matchingBox.NAME] += boxCount;
            if (matchingBox.CHARGE === "YES") {
              totalBillableBoxes += boxCount;
            }
          }
        });
      } else {
        const uniqueBoxes = await fetchBoxContents(shipment.id);
        const allBoxes = getUniqueBoxes(uniqueBoxes);
        allBoxes.forEach((box) => {
          const matchingBox = standardBoxes.find((standardBox) => {
            const boxLength = Math.round(standardBox.LENGTH);
            const boxWidth = Math.round(standardBox.WIDTH);
            const boxHeight = Math.round(standardBox.HEIGHT);
            return (
              (boxLength === box.length && boxWidth === box.width && boxHeight === box.height) ||
              (boxLength === box.length && boxWidth === box.height && boxHeight === box.width) ||
              (boxLength === box.width && boxWidth === box.length && boxHeight === box.height) ||
              (boxLength === box.width && boxWidth === box.height && boxHeight === box.length) ||
              (boxLength === box.height && boxWidth === box.length && boxHeight === box.width) ||
              (boxLength === box.height && boxWidth === box.width && boxHeight === box.length)
            );
          });

          if (!totalBoxesCount[shipment.id]) {
            totalBoxesCount[shipment.id] = 0;
          }
          totalBoxesCount[shipment.id] += 1;

          if (matchingBox) {
            if (!boxUsage[matchingBox.NAME]) {
              boxUsage[matchingBox.NAME] = 0;
            }
            boxUsage[matchingBox.NAME] += 1;
            if (matchingBox.CHARGE === "YES") {
              totalBillableBoxes += 1;
            }
          }
        });
      }
    }

    const totalBoxesUsed = Object.values(totalBoxesCount).reduce((sum, count) => sum + count, 0);
    const totalUnitsShipped = calculateTotalUnitsShipped(shipments);
    const waivedBoxes = calculateWaivedBoxes(totalUnitsShipped);
    const billableBoxes = Math.max(0, totalBillableBoxes - waivedBoxes);

    setBillableBoxes(billableBoxes);
    setBoxUsage(boxUsage);
    setTotalBoxesOverall(totalBoxesUsed);
    setTotalUnitsShipped(totalUnitsShipped);
    setWaivedBoxes(waivedBoxes);
  };

  const processOutboundItems = async (
    shipment,
    outboundItems,
    servicesAdded
  ) => {
    for (const item of outboundItems.items) {
      const quantity = item.quantity;
      const asin = item.item.asin;
      const fnsku = item.item.fnsku;
      const merchantSku = item.item.merchant_sku;
      const title = item.item.title;
      const weightGm = item.item.weight_gm;

      const isOversized = item.company_services.some(
        (service) =>
          service.name.toLowerCase() === 'oversized' ||
          service.name.toLowerCase() === 'huge oversized'
      );

      if (isOversized) {
        let weightKg = null;
        try {
          const response = await fetch(
            `${LOCAL_API_URL}/api/asin-weight/${asin}`
          );
          const weightData = await response.json();
          if (weightData) {
            weightKg = weightData.WEIGHT;
          }
        } catch (error) {
          console.error('Error fetching ASIN weight:', error);
        }

        let weightLbs;
        if (weightKg !== null) {
          weightLbs = kgToLbs(weightKg);
        } else {
          weightLbs = gmToLbs(weightGm);
        }

        if (weightLbs > 5) {
          const extraWeightLbs = weightLbs - 5;
          const additionalCharge = 0.1 * extraWeightLbs;

          servicesAdded.push({
            serviceId: 'oversized-fee',
            name: 'Additional Oversized > 5lb Fees',
            charge: additionalCharge,
            type: 'extra-charge',
            shipmentId: shipment.id,
            origin: 'weight-check',
            quantity,
            asin,
            fnsku,
            title,
            merchantSku,
          });
        }
      }

      item.company_services.forEach((service) => {
        servicesAdded.push({
          serviceId: service.id,
          name: service.name,
          charge: service.charge,
          type: service.type,
          shipmentId: shipment.id,
          origin: 'outboundItem',
          quantity,
          asin,
          fnsku,
          title,
          merchantSku,
        });
      });

      if (Array.isArray(item.always_company_services) && item.always_company_services.length > 0) {
        item.always_company_services.forEach((service) => {
          servicesAdded.push({
            serviceId: service.id,
            name: service.name,
            charge: service.charge,
            type: service.type,
            shipmentId: shipment.id,
            origin: 'outboundItem',
            quantity,
            asin,
            fnsku,
            title,
            merchantSku,
          });
        });
      }
    }
  };

  /**
   * 
   * Updated fetchPlans function to handle both v0 and v2024. 
   * If v0 returns an empty array, we try v2024. 
   * Then we normalize the v2024 response into the same shape as v0. 
   * 
   */
  const fetchPlans = async (shipmentId) => {
    try {
      // 1) Try v0
      let response = await fetch(
        `${API_BASE_URL}/fba-transport/v0/plans?outbound_shipment_id=${shipmentId}&api_token=${API_TOKEN}`
      );
      if (!response.ok) {
        throw new Error('Failed to fetch v0 plans');
      }
      let plansData = await response.json();

      // If the v0 "data" array is empty, try v2024
      if (!plansData.data || plansData.data.length === 0) {
        console.log("No v0 plans found. Trying v2024...");
        response = await fetch(
          `${API_BASE_URL}/fba-transport/v2024/plans?outbound_shipment_id=${shipmentId}&api_token=${API_TOKEN}`
        );
        if (!response.ok) {
          throw new Error('Failed to fetch v2024 plans');
        }
        const v2024Data = await response.json();

        // Transform v2024 data to match the v0 shape
        plansData = { data: [transformV2024toV0(v2024Data)] };
      }

      // Filter out any plans that are not accepted or are rejected/deleted
      console.log("Plans data:", plansData);
      const filteredPlans = plansData.data.filter((plan) => {
        return plan.is_accepted && !plan.is_marked_as_deleted && !plan.is_rejected;
      });

      // Check if there are any plans that are accepted but not shipped
      const unshippedPlans = filteredPlans.filter(
        (plan) => !plan.is_marked_as_shipped
      );
      if (unshippedPlans.length > 0) {
        // setWarnings((prevWarnings) => [
        //   ...prevWarnings,
        //   `Warning: Shipment ID ${shipmentId} has plans that are accepted but not marked as shipped.`,
        // ]);
      }

      return filteredPlans;
    } catch (error) {
      console.error('Error fetching plans:', error);
      return [];
    }
  };

  /**
   * Utility to transform the v2024 shape into v0-like shape:
   * We'll flatten out the data so each "plan" we return is the same format as v0: 
   * { 
   *   id, name, mode, fba_shipment_id, is_accepted, is_rejected, is_marked_as_shipped, is_marked_as_deleted,
   *   items: [...], 
   *   box_groups: [...], 
   *   ...
   * }
   */
  const transformV2024toV0 = (v2024Data) => {
    // If no data or empty, just return an empty array
    if (!v2024Data.data || v2024Data.data.length === 0) return [];


    // 1) Filter for only active & not canceled
    const filteredData = v2024Data.data.filter(
      (item) => item.status === 'ACTIVE' && !item.is_canceled
    );

    const is_accepted = true; // v2024 doesn't have "is_accepted" field. We'll assume all are accepted.
    const is_canceled = false; // v2024 has "is_canceled" => could be "rejected" or "deleted"
  
    // 2) Sort descending by created_at
    filteredData.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    console.log("Filtered data:", filteredData);
    // 3) Keep only the first (the most recent)
    const [ newestPlan ] = filteredData;
    if (!newestPlan) return [];  // No valid plan found
  
    // 4) Transform only that single plan into the "v0 shape"
    const item = newestPlan;

    // v2024 doesn't always have a single "fba_shipment_id" at the top. 
      // We often find it inside "placement_options" => "shipments" => "shipment_confirmation_id".
      // But for a simpler approach, we'll try to see if there's only one "shipment" in "placement_options".
      // If there's multiple shipments, we can combine them or just pick the first "shipment_confirmation_id" we find.
      let fbaId = null;
      console.log(item)
      // Flatten out any "placement_options" => each "shipments" => "shipment_confirmation_id"
      if (item.packing_options && item.packing_options.length > 0) {
        // We might find a "shipment_confirmation_id" in each "packing_options" or "placement_options".
        // But "packing_options" in the sample data has no "shipment_confirmation_id".
        // Actually, the "shipment_confirmation_id" shows up in "placement_options" => "shipments".
      }
      if (item.placement_options && item.placement_options.length > 0) {
        for (const placement of item.placement_options) {
          if (placement.shipments && placement.shipments.length > 0 && placement.confirmed_at != null) {
            const foundShipment = placement.shipments.find(
              (s) => s.shipment_confirmation_id && s.shipment_confirmation_id.startsWith("FBA")
            );
            if (foundShipment) {
              fbaId = foundShipment.shipment_confirmation_id;
              console.log("Found shipment FBA ID: ", fbaId);
              break;
            }
          }
        }
      }

      // If we never found an FBA ID in the above, you may want to set a fallback
      if (!fbaId && item.name) {
        // fallback: try something from item.name (some sellers put it there)
        // or just keep it "UNKNOWN"
        fbaId = "UNKNOWN";
      }

      // Build "items" array
      // v2024 has "items" at the top-level (like item.items) => which are the SKU lines
      // They do not have "fnsku" by default. We'll treat "msku" as "seller_sku". 
      const items = (item.items || []).map((v2024Item) => ({
        seller_sku: v2024Item.msku || '',
        fnsku: '', // We don't get fnsku from v2024, so leaving blank
        quantity: v2024Item.quantity,
        expiration: v2024Item.expiration || null,
      }));

      // "box_groups" in v2024 is at top-level "box_groups" plus also each shipment can have box_groups
      const topLevelBoxGroups = item.box_groups || [];
      // We might also have item.placement_options[x].shipments[y].box_groups
      let extraBoxGroups = [];
      // if (item.placement_options && item.placement_options.length > 0) {
      //   for (const placement of item.placement_options) {
      //     if (placement.shipments) {
      //       for (const sh of placement.shipments) {
      //         if (sh.box_groups) {
      //           extraBoxGroups = extraBoxGroups.concat(sh.box_groups);
      //         }
      //       }
      //     }
      //   }
      // }
      const allBoxGroups = [...topLevelBoxGroups, ...extraBoxGroups];

      // Convert each box_group to the v0 shape
      const convertedBoxGroups = allBoxGroups.map((bg) => ({
        id: bg.id,
        length_mm: bg.length_mm,
        width_mm: bg.width_mm,
        height_mm: bg.height_mm,
        weight_gm: bg.weight_gm,
        box_numbers: bg.box_numbers || [],
        items: bg.items || [],
      }));

      return {
        // Mimic the v0 fields:
        id: item.id || 'unknown-plan-id',
        name: item.name || 'No name provided',
        mode: 'partnered_small_parcel', // v2024 might use "transport_mode_preference"
        fba_shipment_id: fbaId,
        is_accepted: is_accepted,
        is_rejected: false, // in v2024, "is_canceled" => could be "rejected" or "deleted"
        is_marked_as_shipped: false,
        is_marked_as_deleted: is_canceled,
        items,
        box_groups: convertedBoxGroups,
      };
    
  };

  // Calculate pallet deliveries for all shipments
  const calculatePalletDeliveries = async (shipments, clients) => {
    if (!shipments || shipments.length === 0) return;
    try {
      let totalPalletCount = 0;
      const shipmentsByBaseClient = shipments.reduce((acc, shipment) => {
        const client = clients.find(client => client.id === shipment.outboundInfo.shipment.team_id);
        if (!client) {
          return acc;
        }
        const clientNameBase = client.name.replace(/(\s*\(CA\)|\s*\(US\))$/i, '');
        if (!acc[clientNameBase]) {
          acc[clientNameBase] = [];
        }
        acc[clientNameBase].push(shipment);
        return acc;
      }, {});

      for (const clientNameBase in shipmentsByBaseClient) {
        if (shipmentsByBaseClient.hasOwnProperty(clientNameBase)) {
          const { palletCount, totalCharge } = await fetchAndCalculatePalletDeliveries(
            clientNameBase,
            startDate,
            endDate
          );
          totalPalletCount += palletCount;
        }
      }
      console.log(`Total Pallet Count: ${totalPalletCount}`);
    } catch (error) {
      console.error('Error calculating pallet deliveries:', error);
    }
  };

  // Fetch client shipments based on teamId and date range
  const fetchClientShipments = async () => {
    setLoading(true);
    setDetailedShipments([]);
    fetchStandardBoxes();
    fetchRateSheet();

    const startOfDay = new Date(new Date(startDate).setHours(0, 0, 0, 0));
    const endOfDay = new Date(new Date(endDate).setHours(23, 59, 59, 999));

    try {
      const teamIds = teamId.split(',');
      let allFilteredShipments = [];

      for (const id of teamIds) {
        let page = 1;
        let noResultPages = 0;

        while (page <= 10) {
          const response = await fetch(
            `${API_BASE_URL}/shipments/outbound?page=${page}&api_token=${API_TOKEN}`,
            {
              headers: {
                'X-Selected-Client-Id': id.trim(),
                'Content-Type': 'application/json',
              },
            }
          );
          if (!response.ok) {
            throw new Error('Failed to fetch client shipments');
          }

          const shipmentData = await response.json();
          const filteredShipments = shipmentData.data.filter((shipment) => {
            const shippedAt = shipment.shipped_at ? new Date(shipment.shipped_at) : null;
            const updatedAt = new Date(shipment.updated_at);
            const isWithinDateRange =
              (shippedAt && shippedAt >= startOfDay && shippedAt <= endOfDay) ||
              (!shippedAt && updatedAt >= startOfDay && updatedAt <= endOfDay);

            return (
              (shipment.status === 'closed' || shipment.status === 'shipped') &&
              isWithinDateRange
            );
          });

          allFilteredShipments = [...allFilteredShipments, ...filteredShipments];

          if (filteredShipments.length === 0) {
            noResultPages += 1;
            if (noResultPages >= 3) {
              break;
            }
          } else {
            noResultPages = 0;
          }

          page += 1;
          await delay(100);
        }
      }

      setClientShipments(allFilteredShipments);

      let newServicesAdded = [];

      const detailedShipments = await Promise.all(
        allFilteredShipments.map(async (shipment) => {
          const outboundInfo = await fetchOutboundInfo(shipment.id);
          const outboundItems = await fetchOutboundItems(shipment.id);
          const plans = await fetchPlans(shipment.id);

          console.log("Shipment Plans found for Outbound ID", shipment.id, plans);

          let shipmentServicesAdded = [];
          await processOutboundItems(shipment, outboundItems, shipmentServicesAdded);

          if (outboundInfo.shipment.company_services) {
            outboundInfo.shipment.company_services.forEach((service) => {
              const existingService = shipmentServicesAdded.find(
                (s) => s.serviceId === service.id && s.shipmentId === shipment.id
              );
              if (!existingService) {
                shipmentServicesAdded.push({
                  serviceId: service.id,
                  name: service.name,
                  charge: service.charge,
                  type: service.type,
                  shipmentId: shipment.id,
                  origin: 'outboundInfo',
                  quantity: service.pivot.quantity,
                  asin: '',
                  fnsku: '',
                  title: '',
                  merchantSku: '',
                });
              }
            });
          }

          newServicesAdded = [...newServicesAdded, ...shipmentServicesAdded];

          return {
            id: shipment.id,
            name: shipment.name,
            status: shipment.status,
            shipped_at: shipment.shipped_at,
            updated_at: shipment.updated_at,
            shipped_items_count: shipment.shipped_items_count,
            sku_count: shipment.sku_count,
            outboundInfo,
            outboundItems,
            plans,
            servicesAdded: shipmentServicesAdded,
          };
        })
      );

      setServicesAdded(newServicesAdded);
      setDetailedShipments(detailedShipments);

      calculateStandardBoxes(detailedShipments);
      const totalUnitsShipped = calculateTotalUnitsShipped(detailedShipments);
      calculateUSAWeightFees(detailedShipments, clients);
      await calculatePalletDeliveries(detailedShipments, clients);
      await calculateServiceCharges(detailedShipments);

      const autoClientTier = determineClientTier(totalUnitsShipped);
      setClientTier(autoClientTier);
    } catch (error) {
      console.error('Error fetching client shipments:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchOutboundInfo = async (shipmentId) => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/shipments/outbound/${shipmentId}?api_token=${API_TOKEN}`
      );
      if (!response.ok) {
        throw new Error('Failed to fetch outbound info');
      }
      const outboundInfo = await response.json();
      return outboundInfo;
    } catch (error) {
      console.error('Error fetching outbound info:', error);
      return null;
    }
  };

  const fetchOutboundItems = async (shipmentId) => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/shipments/outbound/${shipmentId}/outbound-shipment-item?api_token=${API_TOKEN}`
      );
      if (!response.ok) {
        throw new Error('Failed to fetch outbound items');
      }
      const outboundItems = await response.json();
      return outboundItems;
    } catch (error) {
      console.error('Error fetching outbound items:', error);
      return null;
    }
  };

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      console.log('Copied to clipboard!');
    });
  };

  const calculateAndAddBillableBoxes = async () => {
    await calculateStandardBoxes(detailedShipments);
    if (billableBoxes > 0) {
      const service = {
        serviceId: 'billable-boxes',
        name: 'Billable Boxes',
        charge: BOX_RATE,
        type: 'box-charge',
        shipmentId: 'N/A',
        origin: 'standard-box',
        quantity: billableBoxes,
        totalCharge: billableBoxes * BOX_RATE,
      };
      setServicesAdded((prevServices) => [...prevServices, service]);
      calculateServiceCharges(detailedShipments);
    }
  };

  const calculateTotalBillWithTax = () => {
    return serviceCharges.reduce((total, charge) => {
      const taxRate = selectedTaxes[charge.serviceId] ? 0.13 : 0;
      const taxAmount = charge.totalCharge * taxRate;
      return total + charge.totalCharge + taxAmount;
    }, 0);
  };

  const handleTaxToggle = (serviceId) => {
    setSelectedTaxes((prev) => ({
      ...prev,
      [serviceId]: !prev[serviceId],
    }));
  };

  const handleApplyTaxToAll = () => {
    const allSelected = !applyTaxToAll;
    setApplyTaxToAll(allSelected);

    const newSelectedTaxes = {};
    serviceCharges.forEach((charge) => {
      newSelectedTaxes[charge.serviceId] = allSelected;
    });

    setSelectedTaxes(newSelectedTaxes);
  };

  const handleDeleteService = useCallback(
    (serviceId) => {
      const updatedServices = servicesAdded.filter(service => service.serviceId !== serviceId);
      setServicesAdded(updatedServices);
      calculateServiceCharges(updatedServices);
    },
    [servicesAdded]
  );

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) {
    return <Spinner animation="border" />;
  }

  return (
    <Container style={{ paddingBottom: '150px' }}>
      {loading && <LoadingOverlay />}
      <h4 className="my-4">
        Client Shipments -{' '}
        {clients && clients.length > 0
          ? (() => {
              const firstTeamId = teamId.split(',')[0].trim();
              const client = clients.find(client => client.id == firstTeamId);
              const clientName = client
                ? client.name.replace(/\s*\(CA\)|\s*\(US\)/g, '')
                : null;
              return clientName || 'Client Not Found';
            })()
          : 'Loading...'}
      </h4>

      {warnings.length > 0 &&
        warnings.map((warning, index) => (
          <Alert key={index} variant="warning">
            {warning}
          </Alert>
        ))
      }

      <Button variant="primary" onClick={handleApplyTaxToAll}>
        {applyTaxToAll ? 'Remove Tax from All' : 'Apply Tax to All'}
      </Button>

      <Button variant="secondary" onClick={() => setShowStorageModal(true)}>
        Add Storage Fee
      </Button>

      <Button
        variant={applyCcAdminFee ? "danger" : "primary"}
        onClick={() => {
          setApplyCcAdminFee(!applyCcAdminFee);
          calculateCcAdminFee(totalBillWithTax);
        }}
      >
        {applyCcAdminFee ? 'Remove CC Admin Fee' : 'Add CC Admin Fee'}
      </Button>

      <Button
        variant="warning"
        onClick={calculateMinimumAdjustmentFee}
        disabled={totalBillWithTax >= 500}
      >
        Add Minimum Adjustment Fee
      </Button>

      <Button variant="info" onClick={fetchAndCalculateFDANotice}>
        Calculate FDA Prior Notice
      </Button>

      <Button variant="success" onClick={() => setShowCustomServiceModal(true)}>
        Add Custom Service Charge
      </Button>

      <h4 className="my-4">Select Stripe Customer</h4>
      <Select
        options={squareCustomerOptions}
        value={selectedSquareCustomer}
        onChange={(option) => setSelectedSquareCustomer(option)}
        placeholder="Select a Square customer"
        isSearchable
      />

      <h4 className="my-4">Invoice Notes</h4>
      <Form.Group controlId="invoiceNotes">
        <Form.Label>Notes</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          placeholder="Add any notes for the invoice..."
          value={invoiceNotes}
          onChange={(e) => setInvoiceNotes(e.target.value)}
        />
      </Form.Group>

      <Button variant="success" onClick={generateInvoice}>
        Generate Invoice
      </Button>

      <h4 className="my-4">Select Client Tier</h4>
      <div className="mb-4">
        {Array.from({ length: 4 }, (_, i) => i + 1).map((tier) => (
          <div key={tier} className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="clientTier"
              id={`tier${tier}`}
              value={tier}
              checked={clientTier === tier}
              onChange={() => {
                setClientTier(tier);
                calculateServiceCharges(detailedShipments);
              }}
            />
            <label className="form-check-label" htmlFor={`tier${tier}`}>
              Tier {tier}
            </label>
          </div>
        ))}
      </div>

      <div>
        <h2>Box Usage Summary</h2>
        <table>
          <thead>
            <tr>
              <th>Box Name</th>
              <th>Count</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(boxUsage).map(([name, count]) => (
              <tr key={name}>
                <td>{name}</td>
                <td>{count}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <h3>Total Boxes</h3>
        <p>Total Boxes Overall: {totalBoxesOverall}</p>
        <p>Total Units Shipped: {totalUnitsShipped}</p>
        <p>Waived Boxes: {waivedBoxes}</p>
        <p>Billable Boxes: {billableBoxes}</p>
        <Button variant="primary" onClick={calculateAndAddBillableBoxes}>
          Calculate and Add Billable Boxes
        </Button>
      </div>

      <h4 className="my-4">Total Bill Amount</h4>
      <p>Total Amount (including tax): ${totalBillWithTax.toFixed(2)}</p>

      {serviceCharges.length > 0 && (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Service Name</th>
              <th>Rate Charged</th>
              <th>Quantity</th>
              <th>Total Charges</th>
              <th>Tax</th>
              <th>Total With Tax</th>
              <th>Add Tax</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {serviceCharges.map((charge, index) => {
              const taxRate = selectedTaxes[charge.serviceId] ? 0.13 : 0;
              const taxAmount = charge.totalCharge * taxRate;
              const totalWithTax = charge.totalCharge + taxAmount;

              return (
                <React.Fragment key={index}>
                  <tr
                    onClick={() =>
                      setExpandedService(expandedService === index ? null : index)
                    }
                  >
                    <td>{charge.serviceName}</td>
                    <td>
                      {typeof charge.rate === 'number'
                        ? charge.rate.toFixed(2)
                        : 'N/A'}
                    </td>
                    <td>{charge.quantity}</td>
                    <td>{charge.totalCharge.toFixed(2)}</td>
                    <td>{taxAmount.toFixed(2)}</td>
                    <td>{totalWithTax.toFixed(2)}</td>
                    <td>
                      <input
                        type="checkbox"
                        checked={!!selectedTaxes[charge.serviceId]}
                        onChange={(e) => {
                          e.stopPropagation();
                          handleTaxToggle(charge.serviceId);
                        }}
                      />
                    </td>
                    <td>
                      <Button
                        variant="danger"
                        onClick={() => handleDeleteService(charge.serviceId)}
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                  {expandedService === index && (
                    <tr>
                      <td colSpan="8">
                        <Accordion>
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>Breakdown Details</Accordion.Header>
                            <Accordion.Body>
                              <Table striped bordered hover>
                                <thead>
                                  <tr>
                                    <th>Shipment ID</th>
                                    <th>Quantity</th>
                                    <th>ASIN</th>
                                    <th>FNSKU</th>
                                    <th>Title</th>
                                    <th>Merchant SKU</th>
                                    <th>Total Charge</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {charge.breakdown.map(
                                    (detail, detailIndex) => (
                                      <tr key={detailIndex}>
                                        <td>{detail.shipmentId}</td>
                                        <td>{detail.quantity}</td>
                                        <td>{detail.asin}</td>
                                        <td>{detail.fnsku}</td>
                                        <td>{detail.title}</td>
                                        <td>{detail.merchantSku}</td>
                                        <td>{detail.totalCharge.toFixed(2)}</td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </Table>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              );
            })}
          </tbody>
        </Table>
      )}

      {detailedShipments.length === 0 ? (
        <p>No shipments found for the selected date range and client.</p>
      ) : (
        <Accordion defaultActiveKey="0">
          {detailedShipments.map((shipment, index) => (
            <Accordion.Item eventKey={index.toString()} key={shipment.id}>
              <Accordion.Header>
                Shipment ID: {shipment.id} - {shipment.name} -{' '}
                {shipment.outboundInfo &&
                clients.length > 0
                  ? clients.find(client => client.id == shipment.outboundInfo.shipment.team_id).name ||
                    'Client Not Found'
                  : 'Loading...'}
              </Accordion.Header>
              <Accordion.Body>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Shipment ID</th>
                      <th>Name</th>
                      <th>Status</th>
                      <th>Shipped At</th>
                      <th>Updated At</th>
                      <th>Units</th>
                      <th>SKUs</th>
                      <th>Plans Count</th>
                      <th>Units in Plans</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{shipment.id}</td>
                      <td>{shipment.name}</td>
                      <td>{shipment.status}</td>
                      <td>{shipment.shipped_at || 'N/A'}</td>
                      <td>{shipment.updated_at}</td>
                      <td>{shipment.shipped_items_count || 0}</td>
                      <td>{shipment.sku_count || 0}</td>
                      <td>{shipment.plans ? shipment.plans.length : 0}</td>
                      <td
                        style={{
                          color:
                            shipment.plans &&
                            shipment.plans.reduce(
                              (sum, plan) =>
                                sum +
                                (plan.items
                                  ? plan.items.reduce(
                                      (acc, item) => acc + item.quantity,
                                      0
                                    )
                                  : 0),
                              0
                            ) === shipment.shipped_items_count
                              ? 'green'
                              : 'inherit',
                        }}
                      >
                        {shipment.plans
                          ? shipment.plans.reduce(
                              (sum, plan) =>
                                sum +
                                (plan.items
                                  ? plan.items.reduce(
                                      (acc, item) => acc + item.quantity,
                                      0
                                    )
                                  : 0),
                              0
                            )
                          : 0}
                      </td>
                    </tr>
                  </tbody>
                </Table>
                {shipment.plans && shipment.plans.length > 0 && (
                  <Accordion>
                    {shipment.plans.map((plan, planIndex) => {
                      const totalUnits = plan.items
                        ? plan.items.reduce((sum, item) => sum + item.quantity, 0)
                        : 0;
                      const totalSKUs = plan.items ? plan.items.length : 0;
                      var planBoxes = boxData.filter(box => box.FBA_ID === plan.fba_shipment_id);
                      if (!planBoxes.length) {
                        console.log(`FBA ID didn't find any boxes so we're using plan id.`,plan)
                        planBoxes = boxData.filter(box => box.FBA_ID === plan.id);
                      }
                      const boxCount = planBoxes.length;
                      const lastUpdated = planBoxes.reduce((latest, box) => {
                        const dateUpdated = new Date(box.DATE_UPDATED);
                        return dateUpdated > latest ? dateUpdated : latest;
                      }, new Date(0));

                      return (
                        <Accordion.Item
                          eventKey={planIndex.toString()}
                          key={plan.id}
                        >
                          <Accordion.Header>
                            FBA ID: {plan.fba_shipment_id}
                            <Button
                              variant="outline-secondary"
                              size="sm"
                              onClick={() => handleCopy(plan.fba_shipment_id)}
                              style={{ marginLeft: '10px' }}
                            >
                              Copy to Clipboard
                            </Button>
                          </Accordion.Header>
                          <Accordion.Body>
                            <p>
                              <strong>Box Count:</strong> {boxCount}
                            </p>
                            <p>
                              <strong>Box Last Updated:</strong>{' '}
                              {lastUpdated.toLocaleDateString('en-US', {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric',
                              })}{' '}
                              at {lastUpdated.toLocaleTimeString('en-US')}
                            </p>
                            <p>
                              <strong>Name:</strong> {plan.name}
                            </p>
                            <p>
                              <strong>FBA Shipment ID:</strong> {plan.fba_shipment_id}
                            </p>
                            <p>
                              <strong>Mode:</strong> {plan.mode}
                            </p>
                            <p>
                              <strong>Total Units:</strong> {totalUnits}
                            </p>
                            <p>
                              <strong>Total SKUs:</strong> {totalSKUs}
                            </p>
                            <p>
                              <strong>Accepted:</strong>{' '}
                              {plan.is_accepted ? 'Yes' : 'No'}
                            </p>
                            <p>
                              <strong>Deleted:</strong>{' '}
                              {plan.is_marked_as_deleted ? 'Yes' : 'No'}
                            </p>
                            <p>
                              <strong>Rejected:</strong>{' '}
                              {plan.is_rejected ? 'Yes' : 'No'}
                            </p>
                            <p>
                              <strong>Marked as Shipped:</strong>{' '}
                              {plan.is_marked_as_shipped ? 'Yes' : 'No'}
                            </p>
                          </Accordion.Body>
                        </Accordion.Item>
                      );
                    })}
                  </Accordion>
                )}
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      )}

      {/* Storage Fee Modal */}
      <Modal show={showStorageModal} onHide={() => setShowStorageModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Storage Fee</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="pallets">
              <Form.Label>Pallets</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter number of pallets"
                value={pallets}
                onChange={(e) => setPallets(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="cbm">
              <Form.Label>CBM</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter CBM"
                value={cbm}
                onChange={(e) => setCbm(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="customAmount">
              <Form.Label>Custom Amount ($)</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter custom amount"
                value={customAmount}
                onChange={(e) => setCustomAmount(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowStorageModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAddStorageFee}>
            Add Fee
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Custom Service Modal */}
      <Modal
        show={showCustomServiceModal}
        onHide={() => setShowCustomServiceModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Custom Service Charge</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="customServiceName">
              <Form.Label>Service Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter service name"
                value={customServiceName}
                onChange={(e) => setCustomServiceName(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="customServiceRate">
              <Form.Label>Rate Charged</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter rate charged"
                value={customServiceRate}
                onChange={(e) => setCustomServiceRate(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="customServiceQuantity">
              <Form.Label>Quantity</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter quantity"
                value={customServiceQuantity}
                onChange={(e) => setCustomServiceQuantity(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowCustomServiceModal(false)}
          >
            Close
          </Button>
          <Button variant="primary" onClick={handleAddCustomService}>
            Add Service
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default ClientDetail;
