import React, { useState } from 'react';
import { Button, Menu, MenuItem, Checkbox, FormControlLabel } from '@mui/material';

const FiltersDropdown = ({
  prepFilter,
  setPrepFilter,
  shelvedFilter,
  setShelvedFilter,
  showItemsForAllPlacements,
  setShowItemsForAllPlacements,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        variant="outlined"
        onClick={handleClick}
        aria-controls={open ? 'filters-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      >
        Filters
      </Button>
      <Menu
        id="filters-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'filters-button',
        }}
      >
        <MenuItem>
          <FormControlLabel
            control={
              <Checkbox
                checked={prepFilter}
                onChange={(e) => setPrepFilter(e.target.checked)}
              />
            }
            label="Items with Prep"
          />
        </MenuItem>
        <MenuItem>
          <FormControlLabel
            control={
              <Checkbox
                checked={shelvedFilter}
                onChange={(e) => setShelvedFilter(e.target.checked)}
              />
            }
            label="Shelved Items"
          />
        </MenuItem>
        <MenuItem>
          <FormControlLabel
            control={
              <Checkbox
                checked={showItemsForAllPlacements}
                onChange={(e) => setShowItemsForAllPlacements(e.target.checked)}
              />
            }
            label="Show Items for All Placements"
          />
        </MenuItem>
      </Menu>
    </div>
  );
};

export default FiltersDropdown;