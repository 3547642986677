import React, { useState } from 'react';
import { Modal, Table, Button } from '@mui/material';

const SplitShipmentModal = ({ open, onClose, shipmentItems, onSelectItems, onSplit }) => {
  const [selected, setSelected] = useState([]);

  // Handle selecting/deselecting all items
  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const newSelected = shipmentItems.map((item) => item.id);
      setSelected(newSelected);
      onSelectItems(shipmentItems); // Pass all items when selecting all
      return;
    }
    setSelected([]);
    onSelectItems([]); // Clear selection when deselecting all
  };

  // Handle selecting/deselecting a single item
  const handleSelectOne = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [...selected];

    if (selectedIndex === -1) {
      newSelected.push(id);
    } else {
      newSelected.splice(selectedIndex, 1);
    }

    setSelected(newSelected);
    onSelectItems(newSelected.map((id) => shipmentItems.find((item) => item.id === id)));
  };

  // Handle row click to select/deselect
  const handleRowClick = (id) => {
    handleSelectOne(null, id);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      className="flex items-center justify-center"
    >
      <div className="bg-white rounded-lg shadow-lg max-w-5xl w-full p-6 mx-4">
        <h2 className="text-2xl font-bold mb-4 text-center">Split Shipment</h2>
        <Table>
          <thead>
            <tr>
              <th className="p-4">
                <input
                  type="checkbox"
                  onChange={handleSelectAll}
                  checked={selected.length === shipmentItems.length}
                />
              </th>
              <th className="p-4">Image</th>
              <th className="p-4">Title</th>
              <th className="p-4">Quantity</th>
              <th className="p-4">Oversized</th>
            </tr>
          </thead>
          <tbody>
            {shipmentItems.map((item) => (
              <tr
                key={item.id}
                className={`cursor-pointer ${selected.includes(item.id) ? 'bg-blue-100' : 'hover:bg-gray-100'}`}
                onClick={() => handleRowClick(item.id)}
              >
                <td className="p-4">
                  <input
                    type="checkbox"
                    checked={selected.includes(item.id)}
                    onChange={() => {}}
                  />
                </td>
                <td className="p-4">
  <img
    src={item.item.images?.[0]?.thumbnail_url}
    alt={item.item.title}
    className="w-16 h-16 object-cover"
  />
</td>

                <td className="p-4">
                  {item.item.title}
                  <br />
                  <small>
                    ASIN: {item.item.asin}, FNSKU: {item.item.fnsku}, SKU: {item.item.merchant_sku}
                  </small>
                </td>
                <td className="p-4">{item.quantity}</td>
                <td className="p-4">
  {(() => {
    const lengthInches = item.item.length_mm / 25.4;
    const widthInches = item.item.width_mm / 25.4;
    const heightInches = item.item.height_mm / 25.4;
    const weightLbs = item.item.weight_gm / 453.592;

    const isOversized =
      lengthInches > 18 || widthInches > 14 || heightInches > 8 || weightLbs > 20;

    return isOversized ? (
      <span className="bg-yellow-400 text-black font-bold px-2 py-1 rounded">
        Yes
      </span>
    ) : (
      <span className="bg-gray-200 text-black px-2 py-1 rounded">No</span>
    );
  })()}
</td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="flex justify-end mt-6 space-x-4">
          <Button variant="outlined" color="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={onSplit}>
            Split Shipment
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default SplitShipmentModal;