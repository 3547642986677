import React, { useContext, useState, useEffect } from 'react';
import { Box, Typography, Grid, Paper, TextField, Button, Accordion, AccordionSummary, AccordionDetails, IconButton, FormControl, InputLabel, Select, MenuItem, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import SyncIcon from '@mui/icons-material/Sync';
import { USAContext } from './USAContext';

const API_URL = process.env.REACT_APP_API_URL;

const ReviewItemsBoxes = () => {
    const { shipmentItems, boxes, asinData, reloadAsinData, plans, selectedPlan, setSelectedPlan, refreshBoxes, handleNext } = useContext(USAContext);
    const [searchTerm, setSearchTerm] = useState('');
    const [prices, setPrices] = useState({});
    const [openDialog, setOpenDialog] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [placementWarning, setPlacementWarning] = useState('');
    const [placementDetails, setPlacementDetails] = useState([]);


    const sortedPlans = [...plans].sort((a, b) => {
        const dateA = new Date(a.created_at);
        const dateB = new Date(b.created_at);
        return dateB - dateA; // Descending order: most recent first
      });

    useEffect(() => {
        if (shipmentItems.length > 0 && selectedPlan) {
            const initialPrices = shipmentItems.reduce((acc, item) => {
                acc[item.item.asin] = getPrice(item.item.asin).toFixed(2);
                return acc;
            }, {});
            setPrices(initialPrices);
        }
    }, [shipmentItems, asinData, selectedPlan]);

    const getPrice = (asin) => {
        const data = asinData.find(item => item.ASIN === asin);
        return data ? parseFloat(data.VALUE) : 0;
    };

    const calculateBoxValue = (box) => {
        return box.items.reduce((acc, item) => {
            return acc + (getPrice(item.ASIN) * item.QTY);
        }, 0);
    };

    const handlePriceChange = (asin, newPrice) => {
        setPrices(prevPrices => ({ ...prevPrices, [asin]: newPrice }));
    };

    const handlePriceBlur = async (asin, newPrice) => {
        if (!isNaN(newPrice) && newPrice.trim() !== '') {
            try {
                await fetch(`${API_URL}/api/update_price`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ asin, value: parseFloat(newPrice) })
                });

                reloadAsinData();
            } catch (error) {
                console.error('Error updating price:', error);
            }
        }
    };

    const handlePlanChange = (event) => {
        const plan = event.target.value;
        setSelectedPlan(plan);
        checkPlacementOptions(plan);
    }

    useEffect(() => {
        if (selectedPlan) {
            refreshBoxes();
        }
    }, [selectedPlan]);

    const filteredItems = selectedPlan ? shipmentItems.filter(item => 
        selectedPlan.items.some(planItem => planItem.msku === item.item.merchant_sku)
    ) : [];

    const hasOver800 = boxes.some(box => calculateBoxValue(box) > 800);

    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        if (file) {
            setIsLoading(true);  // Start loading state
    
            const reader = new FileReader();
            reader.onloadend = async () => {
                const base64Data = reader.result.split(',')[1];
                const payload = {
                    name: file.name,
                    type: file.type,
                    base64Data: base64Data,
                    sizeBytes: file.size,
                };
    
                try {
                    const response = await fetch(`${API_URL}/proxy-retool`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(payload),
                    });
    
                    if (response.ok) {
                        const result = await response.json();
                        const base64PDF = result.response;
    
                        // Convert base64 PDF back to Blob and download it
                        const byteCharacters = atob(base64PDF);
                        const byteNumbers = new Array(byteCharacters.length);
                        for (let i = 0; i < byteCharacters.length; i++) {
                            byteNumbers[i] = byteCharacters.charCodeAt(i);
                        }
                        const byteArray = new Uint8Array(byteNumbers);
                        const blob = new Blob([byteArray], { type: 'application/pdf' });
                        const downloadUrl = URL.createObjectURL(blob);
    
                        const link = document.createElement('a');
                        link.href = downloadUrl;
                        link.download = 'PN-LABELS.pdf';
                        link.click();
                    } else {
                        console.error('Error processing PDF:', response.statusText);
                    }
                } catch (error) {
                    console.error('Error uploading file:', error);
                } finally {
                    setIsLoading(false);  // End loading state
                }
            };
            reader.readAsDataURL(file);
        }
    };

    const checkPlacementOptions = (plan) => {
        if (!plan || !plan.placement_options || plan.placement_options.length === 0) {
            setPlacementWarning('No placement options available. Please submit your box contents.');
            setPlacementDetails([]);
            return;
        }
        console.log(plan.placement_options)
        const confirmedOptions = plan.placement_options.filter(option => option.confirmed_at);
        if (confirmedOptions.length === 0) {
            setPlacementWarning('No placement option has been confirmed. Please confirm a placement option.');
            setPlacementDetails([]);
            return;
        }

        const latestConfirmedOption = confirmedOptions.reduce((latest, current) => {
            return new Date(current.confirmed_at) > new Date(latest.confirmed_at) ? current : latest;
        });

        const shipmentDetails = latestConfirmedOption.shipments.map(shipment => {
            const skuCount = new Set(shipment.box_groups.flatMap(boxGroup => boxGroup.items.map(item => item.plan_item_id))).size;
            const unitCount = shipment.box_groups.flatMap(boxGroup => boxGroup.items).reduce((acc, item) => acc + item.quantity, 0);
            return {
                fcId: shipment.destination.warehouse_id,
                skuCount,
                unitCount
            };
        });

        setPlacementWarning('');
        setPlacementDetails(shipmentDetails);
    };

    const groupConsecutiveBoxes = (boxes) => {
        const groupedBoxes = [];
        let currentGroup = null;

        boxes.forEach((box, index) => {
            const boxKey = JSON.stringify({
                dimensions: `${box.length}x${box.width}x${box.height}`,
                items: box.items.map(item => ({ ASIN: item.ASIN, QTY: item.QTY }))
            });

            if (currentGroup && currentGroup.key === boxKey) {
                currentGroup.endIndex = index;
            } else {
                if (currentGroup) {
                    groupedBoxes.push(currentGroup);
                }
                currentGroup = {
                    key: boxKey,
                    startIndex: index,
                    endIndex: index,
                    box
                };
            }
        });

        if (currentGroup) {
            groupedBoxes.push(currentGroup);
        }

        return groupedBoxes;
    };

    const groupedBoxes = groupConsecutiveBoxes(boxes);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Button
                    variant="contained"
                    color="primary"
                    component="label"
                    disabled={isLoading}
                    style={{ backgroundColor: isLoading ? '#ccc' : '' }}
                >
                    {isLoading ? 'Processing...' : 'Convert PN Labels'}
                    <input
                        type="file"
                        hidden
                        accept="application/pdf"
                        onChange={handleFileUpload}
                    />
                </Button>
            </Grid>

            <Grid item xs={12}>
                <FormControl fullWidth>
                    <InputLabel id="select-plan-label">Select Plan</InputLabel>
                    <Select
                        labelId="select-plan-label"
                        value={selectedPlan}
                        onChange={handlePlanChange}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Select Plan' }}
                    >
                        <MenuItem value="" disabled>Select a Plan</MenuItem>
                        {sortedPlans.map((plan) => {
                            const formattedDate = new Date(plan.created_at).toLocaleString('en-US', {
                                month: 'short',
                                day: 'numeric',
                                hour: 'numeric',
                                minute: '2-digit',
                                hour12: true,
                            });

                            const packingGroupsText = `${plan.packing_options[0].packing_groups.length} packing group${plan.packing_options[0].packing_groups.length !== 1 ? 's' : ''}`;

                            return (
                                <MenuItem key={plan.id} value={plan}>
                                    {`${plan.inbound_plan_id} - ${formattedDate} (${packingGroupsText})`}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </Grid>

            {placementWarning && (
                <Grid item xs={12}>
                    <Typography variant="h6" color="error">{placementWarning}</Typography>
                </Grid>
            )}

{placementDetails.length > 0 && (
    <Grid item xs={12}>
        <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold', color: '#333' }}>
            Placement Details
        </Typography>
        <Box display="flex" flexWrap="wrap" gap={3}>
            {placementDetails.map((detail, index) => (
                <Paper 
                    key={index} 
                    elevation={3} 
                    style={{ 
                        padding: '16px', 
                        flex: '1 1 auto', 
                        minWidth: '200px', 
                        maxWidth: '300px', 
                        backgroundColor: '#f9f9f9', 
                        border: '1px solid #e0e0e0', 
                        borderRadius: '8px', 
                        transition: 'transform 0.2s, box-shadow 0.2s',
                        ':hover': {
                            transform: 'translateY(-4px)',
                            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                        }
                    }}
                >
                    <Typography variant="subtitle1" style={{ fontWeight: '600', color: '#555', marginBottom: '8px' }}>
                        FC ID: {detail.fcId}
                    </Typography>
                    <Typography variant="body2" style={{ color: '#666', marginBottom: '4px' }}>
                        SKU Count: <span style={{ fontWeight: '500', color: '#333' }}>{detail.skuCount}</span>
                    </Typography>
                    <Typography variant="body2" style={{ color: '#666' }}>
                        Unit Count: <span style={{ fontWeight: '500', color: '#333' }}>{detail.unitCount}</span>
                    </Typography>
                </Paper>
            ))}
        </Box>
    </Grid>
)}

            {!selectedPlan ? (
                <Grid item xs={12}>
                    <Typography variant="h6">Please select a plan to view items and boxes.</Typography>
                </Grid>
            ) : (
                <>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="h5">Items</Typography>
                        <TextField
                            label="Search"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            fullWidth
                            margin="normal"
                            InputProps={{
                                endAdornment: (
                                    <IconButton>
                                        <SearchIcon />
                                    </IconButton>
                                )
                            }}
                        />
                        {filteredItems.length === 0 ? (
                            <Typography>No items found for the selected plan.</Typography>
                        ) : (
                            filteredItems.filter(item => 
                                item.item.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                item.item.asin.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                item.item.merchant_sku.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                item.item.fnsku.toLowerCase().includes(searchTerm.toLowerCase())
                            ).map((item, index) => {
                                const price = prices[item.item.asin];
                                const imageUrl = item.item.images[0] ? item.item.images[0].large_url : '';
                                return (
                                    <Accordion key={index} style={{ marginBottom: '8px' }}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            <Grid container alignItems="center">
                                                <Grid item xs={2}>
                                                    {imageUrl && <img src={imageUrl} alt={item.item.title} style={{ width: '100%' }} />}
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography>{item.item.title}</Typography>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <TextField
                                                        label="Price"
                                                        value={price}
                                                        onChange={(e) => handlePriceChange(item.item.asin, e.target.value)}
                                                        onBlur={(e) => handlePriceBlur(item.item.asin, e.target.value)}
                                                        fullWidth
                                                        type="number"
                                                        inputProps={{ step: '0.01' }}
                                                    />
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <IconButton onClick={() => reloadAsinData()}>
                                                        <SyncIcon />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography variant="body2">ASIN: {item.item.asin}</Typography>
                                            <Typography variant="body2">SKU: {item.item.merchant_sku}</Typography>
                                            <Typography variant="body2">FNSKU: {item.item.fnsku}</Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                );
                            })
                        )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="h5">Boxes</Typography>
                        {groupedBoxes.length === 0 ? (
                            <Typography>No boxes available.</Typography>
                        ) : (
                            groupedBoxes.map((group, index) => {
                                const boxValue = calculateBoxValue(group.box);
                                const boxRange = group.startIndex === group.endIndex ? `Box ${group.startIndex + 1}` : `Boxes ${group.startIndex + 1}-${group.endIndex + 1}`;
                                return (
                                    <Accordion key={index} style={{ marginBottom: '16px', backgroundColor: boxValue > 800 ? '#ffcccc' : 'white', border: '1px solid #ddd' }}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            <Typography variant="h6" style={{ fontWeight: 'bold', display: 'flex', justifyContent: 'space-between', width: '100%', color: boxValue > 800 ? 'red' : 'inherit' }}>
                                                <span>{boxRange}</span>
                                                <span>{group.box.length} x {group.box.width} x {group.box.height}</span>
                                                <span>${boxValue.toFixed(2)}</span>
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid container spacing={2}>
                                                {group.box.items.map((item, idx) => {
                                                    const itemPrice = getPrice(item.ASIN);
                                                    const totalValue = itemPrice * item.QTY;
                                                    const shipmentItem = shipmentItems.find(si => si.item.merchant_sku === item.SKU);
                                                    const imageUrl = shipmentItem && shipmentItem.item.images && shipmentItem.item.images.length > 0 ? shipmentItem.item.images[0].large_url : '';

                                                    return (
                                                        <Grid item xs={12} key={idx}>
                                                            <Paper style={{ padding: '16px', marginBottom: '16px', border: '1px solid #eee' }}>
                                                                <Grid container spacing={2} alignItems="center">
                                                                    <Grid item xs={2}>
                                                                        {imageUrl && <img src={imageUrl} alt={item.TITLE} style={{ width: '100%' }} />}
                                                                    </Grid>
                                                                    <Grid item xs={10}>
                                                                        <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>{item.TITLE}</Typography>
                                                                        <Grid container spacing={2} style={{ marginTop: '8px' }}>
                                                                            <Grid item xs={3}>
                                                                                <Typography variant="body2" style={{ fontWeight: 'bold' }}>ASIN</Typography>
                                                                                <Typography variant="body2">{item.ASIN}</Typography>
                                                                            </Grid>
                                                                            <Grid item xs={3}>
                                                                                <Typography variant="body2" style={{ fontWeight: 'bold' }}>Qty</Typography>
                                                                                <Typography variant="body2">{item.QTY}</Typography>
                                                                            </Grid>
                                                                            <Grid item xs={3}>
                                                                                <Typography variant="body2" style={{ fontWeight: 'bold' }}>Price</Typography>
                                                                                <Typography variant="body2">${itemPrice.toFixed(2)}</Typography>
                                                                            </Grid>
                                                                            <Grid item xs={3}>
                                                                                <Typography variant="body2" style={{ fontWeight: 'bold' }}>Total</Typography>
                                                                                <Typography variant="body2">${totalValue.toFixed(2)}</Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Paper>
                                                        </Grid>
                                                    );
                                                })}
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                );
                            })
                        )}
                    </Grid>
                </>
            )}
            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                <DialogTitle>Warning</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Some boxes have a total value exceeding $800. Proceeding to the next step will require additional approval.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
                    <Button onClick={() => {
                        setOpenDialog(false);
                        handleNext();
                    }} color="primary">Proceed</Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
};

export default ReviewItemsBoxes;