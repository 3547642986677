import React, { createContext, useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';

export const USAContext = createContext();
const API_URL = process.env.REACT_APP_API_URL;

export const USAProvider = ({ children }) => {
  const { shipmentId } = useParams(); // Use useParams to get the shipmentId from the URL

  const [boxes, setBoxes] = useState([]);
  const [shipmentInfo, setShipmentInfo] = useState(null);
  const [shipmentItems, setShipmentItems] = useState([]);
  const [asinData, setAsinData] = useState([]);
  const [clientsData, setClientsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null); // New state variable
  const [activeStep, setActiveStep] = useState(0);

  const fetchShipmentData = useCallback(async () => {
    setLoading(true);
    try {
      const [shipmentResponse, itemsResponse] = await Promise.all([
        fetch(`https://app.yyzprep.ca/api/shipments/outbound/${shipmentId}`, {
          headers: {
            'Authorization': 'Bearer Yh7l5CUTaZ1nIgAueWglafvm616hchHFFZxRjKjPHNBjB19b2jTDgGoCSpeq',
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        }).then(response => response.json()),
        fetch(`https://app.yyzprep.ca/api/shipments/outbound/${shipmentId}/outbound-shipment-item`, {
          headers: {
            'Authorization': 'Bearer Yh7l5CUTaZ1nIgAueWglafvm616hchHFFZxRjKjPHNBjB19b2jTDgGoCSpeq',
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        }).then(response => response.json())
      ]);

      setShipmentInfo(shipmentResponse.shipment);
      setShipmentItems(itemsResponse.items);
      await getPlans();  // Add this line


      const asinList = itemsResponse.items.map(item => item.item.asin);

      const [asinDataResponse, clientsDataResponse] = await Promise.all([
        fetch(`${API_URL}/api/get_asin_data`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ asins: asinList })
        }).then(response => response.json()),
        fetch(`${API_URL}/api/get_clients`)
          .then(response => response.json())
      ]);

      setAsinData(asinDataResponse);
      setClientsData(clientsDataResponse);

      const boxData = await fetchBoxes();
      setBoxes(boxData);

      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  }, [shipmentId]);

  const reloadAsinData = async () => {
    try {
      const asinList = shipmentItems.map(item => item.item.asin);
  
      const response = await fetch(`${API_URL}/api/get_asin_data`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ asins: asinList })
      });
  
      if (!response.ok) {
        throw new Error('Failed to fetch ASIN data');
      }
  
      const data = await response.json();
      setAsinData(data);
      console.log('ASIN data reloaded successfully:', data);
    } catch (error) {
      console.error('Error fetching ASIN data:', error);
    }
  };


  const getPlans = async () => {
    try {
      const response = await fetch(`https://app.yyzprep.ca/api/fba-transport/v2024/plans?api_token=Yh7l5CUTaZ1nIgAueWglafvm616hchHFFZxRjKjPHNBjB19b2jTDgGoCSpeq&outbound_shipment_id=${shipmentId}`);
      if (!response.ok) {
        throw new Error('Failed to fetch plans');
      }
      const data = await response.json();
      
      setPlans(data.data.filter(plan => 
        !plan.is_canceled && 
        plan.packing_options.some(option => option.confirmed_at !== null)
      ));
      console.log('Fetched plans:', data.data.filter(plan => 
        !plan.is_canceled && 
        plan.packing_options.some(option => option.confirmed_at !== null)
      ));
    } catch (error) {
      console.error('Error fetching plans:', error);
    }
  };


  const fetchBoxes = useCallback(async () => {
    try {
      const endpoint = `${API_URL}/api/get_box_contents/${selectedPlan.id}`;
      const response = await fetch(endpoint);
      const data = await response.json();
      if (Array.isArray(data)) {
        const groupedData = groupAndSortByBoxNumber(data);
        console.log("Fetched boxes: ", groupedData);
        setBoxes(groupedData);
        return groupedData;
      } else {
        console.error('dbResponse is not an array:', data);
        return [];
      }
    } catch (error) {
      console.error('Error fetching boxes:', error);
      return [];
    }
  }, [selectedPlan]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  useEffect(() => {
    fetchShipmentData();
  }, [fetchShipmentData]);

  return (
    <USAContext.Provider value={{ 
      boxes, 
      shipmentInfo, 
      shipmentItems, 
      asinData, 
      reloadAsinData,
      setBoxes,
      clientsData, 
      loading,
      plans,
      selectedPlan,
      setSelectedPlan,
      refreshBoxes: fetchBoxes,
      activeStep,
      handleNext,
      handleBack,
      handleReset,
    }}>
      {children}
    </USAContext.Provider>
  );
};

const groupAndSortByBoxNumber = (data) => {
  const groupedData = data.reduce((acc, item) => {
    const boxNumber = item.BOX_NUMBER;
    if (!acc[boxNumber]) {
      acc[boxNumber] = {
        length: item.LENGTH,
        width: item.WIDTH,
        height: item.HEIGHT,
        weight: item.WEIGHT,
        items: []
      };
    }
    
    // Only push non-empty items into the items array
    if (item.FNSKU || item.SKU || item.ASIN || item.QTY || item.TITLE) {
      acc[boxNumber].items.push(item);
    }

    return acc;
  }, {});

  const sortedData = Object.keys(groupedData)
    .sort((a, b) => Number(a) - Number(b))
    .map(boxNumber => ({
      boxNumber,
      length: groupedData[boxNumber].length,
      width: groupedData[boxNumber].width,
      height: groupedData[boxNumber].height,
      weight: groupedData[boxNumber].weight,
      items: groupedData[boxNumber].items
    }));

  return sortedData;
};
